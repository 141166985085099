{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2015-02-02",
    "endpointPrefix": "elasticache",
    "protocol": "query",
    "serviceFullName": "Amazon ElastiCache",
    "serviceId": "ElastiCache",
    "signatureVersion": "v4",
    "uid": "elasticache-2015-02-02",
    "xmlNamespace": "http://elasticache.amazonaws.com/doc/2015-02-02/"
  },
  "operations": {
    "AddTagsToResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceName",
          "Tags"
        ],
        "members": {
          "ResourceName": {},
          "Tags": {
            "shape": "S3"
          }
        }
      },
      "output": {
        "shape": "S5",
        "resultWrapper": "AddTagsToResourceResult"
      }
    },
    "AuthorizeCacheSecurityGroupIngress": {
      "input": {
        "type": "structure",
        "required": [
          "CacheSecurityGroupName",
          "EC2SecurityGroupName",
          "EC2SecurityGroupOwnerId"
        ],
        "members": {
          "CacheSecurityGroupName": {},
          "EC2SecurityGroupName": {},
          "EC2SecurityGroupOwnerId": {}
        }
      },
      "output": {
        "resultWrapper": "AuthorizeCacheSecurityGroupIngressResult",
        "type": "structure",
        "members": {
          "CacheSecurityGroup": {
            "shape": "S8"
          }
        }
      }
    },
    "BatchApplyUpdateAction": {
      "input": {
        "type": "structure",
        "required": [
          "ServiceUpdateName"
        ],
        "members": {
          "ReplicationGroupIds": {
            "shape": "Sc"
          },
          "CacheClusterIds": {
            "shape": "Sd"
          },
          "ServiceUpdateName": {}
        }
      },
      "output": {
        "shape": "Se",
        "resultWrapper": "BatchApplyUpdateActionResult"
      }
    },
    "BatchStopUpdateAction": {
      "input": {
        "type": "structure",
        "required": [
          "ServiceUpdateName"
        ],
        "members": {
          "ReplicationGroupIds": {
            "shape": "Sc"
          },
          "CacheClusterIds": {
            "shape": "Sd"
          },
          "ServiceUpdateName": {}
        }
      },
      "output": {
        "shape": "Se",
        "resultWrapper": "BatchStopUpdateActionResult"
      }
    },
    "CompleteMigration": {
      "input": {
        "type": "structure",
        "required": [
          "ReplicationGroupId"
        ],
        "members": {
          "ReplicationGroupId": {},
          "Force": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "CompleteMigrationResult",
        "type": "structure",
        "members": {
          "ReplicationGroup": {
            "shape": "So"
          }
        }
      }
    },
    "CopySnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "SourceSnapshotName",
          "TargetSnapshotName"
        ],
        "members": {
          "SourceSnapshotName": {},
          "TargetSnapshotName": {},
          "TargetBucket": {},
          "KmsKeyId": {},
          "Tags": {
            "shape": "S3"
          }
        }
      },
      "output": {
        "resultWrapper": "CopySnapshotResult",
        "type": "structure",
        "members": {
          "Snapshot": {
            "shape": "S1q"
          }
        }
      }
    },
    "CreateCacheCluster": {
      "input": {
        "type": "structure",
        "required": [
          "CacheClusterId"
        ],
        "members": {
          "CacheClusterId": {},
          "ReplicationGroupId": {},
          "AZMode": {},
          "PreferredAvailabilityZone": {},
          "PreferredAvailabilityZones": {
            "shape": "S1z"
          },
          "NumCacheNodes": {
            "type": "integer"
          },
          "CacheNodeType": {},
          "Engine": {},
          "EngineVersion": {},
          "CacheParameterGroupName": {},
          "CacheSubnetGroupName": {},
          "CacheSecurityGroupNames": {
            "shape": "S20"
          },
          "SecurityGroupIds": {
            "shape": "S21"
          },
          "Tags": {
            "shape": "S3"
          },
          "SnapshotArns": {
            "shape": "S22"
          },
          "SnapshotName": {},
          "PreferredMaintenanceWindow": {},
          "Port": {
            "type": "integer"
          },
          "NotificationTopicArn": {},
          "AutoMinorVersionUpgrade": {
            "type": "boolean"
          },
          "SnapshotRetentionLimit": {
            "type": "integer"
          },
          "SnapshotWindow": {},
          "AuthToken": {},
          "OutpostMode": {},
          "PreferredOutpostArn": {},
          "PreferredOutpostArns": {
            "shape": "S24"
          },
          "LogDeliveryConfigurations": {
            "shape": "S25"
          },
          "TransitEncryptionEnabled": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateCacheClusterResult",
        "type": "structure",
        "members": {
          "CacheCluster": {
            "shape": "S28"
          }
        }
      }
    },
    "CreateCacheParameterGroup": {
      "input": {
        "type": "structure",
        "required": [
          "CacheParameterGroupName",
          "CacheParameterGroupFamily",
          "Description"
        ],
        "members": {
          "CacheParameterGroupName": {},
          "CacheParameterGroupFamily": {},
          "Description": {},
          "Tags": {
            "shape": "S3"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateCacheParameterGroupResult",
        "type": "structure",
        "members": {
          "CacheParameterGroup": {
            "shape": "S2l"
          }
        }
      }
    },
    "CreateCacheSecurityGroup": {
      "input": {
        "type": "structure",
        "required": [
          "CacheSecurityGroupName",
          "Description"
        ],
        "members": {
          "CacheSecurityGroupName": {},
          "Description": {},
          "Tags": {
            "shape": "S3"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateCacheSecurityGroupResult",
        "type": "structure",
        "members": {
          "CacheSecurityGroup": {
            "shape": "S8"
          }
        }
      }
    },
    "CreateCacheSubnetGroup": {
      "input": {
        "type": "structure",
        "required": [
          "CacheSubnetGroupName",
          "CacheSubnetGroupDescription",
          "SubnetIds"
        ],
        "members": {
          "CacheSubnetGroupName": {},
          "CacheSubnetGroupDescription": {},
          "SubnetIds": {
            "shape": "S2p"
          },
          "Tags": {
            "shape": "S3"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateCacheSubnetGroupResult",
        "type": "structure",
        "members": {
          "CacheSubnetGroup": {
            "shape": "S2r"
          }
        }
      }
    },
    "CreateGlobalReplicationGroup": {
      "input": {
        "type": "structure",
        "required": [
          "GlobalReplicationGroupIdSuffix",
          "PrimaryReplicationGroupId"
        ],
        "members": {
          "GlobalReplicationGroupIdSuffix": {},
          "GlobalReplicationGroupDescription": {},
          "PrimaryReplicationGroupId": {}
        }
      },
      "output": {
        "resultWrapper": "CreateGlobalReplicationGroupResult",
        "type": "structure",
        "members": {
          "GlobalReplicationGroup": {
            "shape": "S2y"
          }
        }
      }
    },
    "CreateReplicationGroup": {
      "input": {
        "type": "structure",
        "required": [
          "ReplicationGroupId",
          "ReplicationGroupDescription"
        ],
        "members": {
          "ReplicationGroupId": {},
          "ReplicationGroupDescription": {},
          "GlobalReplicationGroupId": {},
          "PrimaryClusterId": {},
          "AutomaticFailoverEnabled": {
            "type": "boolean"
          },
          "MultiAZEnabled": {
            "type": "boolean"
          },
          "NumCacheClusters": {
            "type": "integer"
          },
          "PreferredCacheClusterAZs": {
            "shape": "S1v"
          },
          "NumNodeGroups": {
            "type": "integer"
          },
          "ReplicasPerNodeGroup": {
            "type": "integer"
          },
          "NodeGroupConfiguration": {
            "type": "list",
            "member": {
              "shape": "S1t",
              "locationName": "NodeGroupConfiguration"
            }
          },
          "CacheNodeType": {},
          "Engine": {},
          "EngineVersion": {},
          "CacheParameterGroupName": {},
          "CacheSubnetGroupName": {},
          "CacheSecurityGroupNames": {
            "shape": "S20"
          },
          "SecurityGroupIds": {
            "shape": "S21"
          },
          "Tags": {
            "shape": "S3"
          },
          "SnapshotArns": {
            "shape": "S22"
          },
          "SnapshotName": {},
          "PreferredMaintenanceWindow": {},
          "Port": {
            "type": "integer"
          },
          "NotificationTopicArn": {},
          "AutoMinorVersionUpgrade": {
            "type": "boolean"
          },
          "SnapshotRetentionLimit": {
            "type": "integer"
          },
          "SnapshotWindow": {},
          "AuthToken": {},
          "TransitEncryptionEnabled": {
            "type": "boolean"
          },
          "AtRestEncryptionEnabled": {
            "type": "boolean"
          },
          "KmsKeyId": {},
          "UserGroupIds": {
            "type": "list",
            "member": {}
          },
          "LogDeliveryConfigurations": {
            "shape": "S25"
          },
          "DataTieringEnabled": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateReplicationGroupResult",
        "type": "structure",
        "members": {
          "ReplicationGroup": {
            "shape": "So"
          }
        }
      }
    },
    "CreateSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "SnapshotName"
        ],
        "members": {
          "ReplicationGroupId": {},
          "CacheClusterId": {},
          "SnapshotName": {},
          "KmsKeyId": {},
          "Tags": {
            "shape": "S3"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateSnapshotResult",
        "type": "structure",
        "members": {
          "Snapshot": {
            "shape": "S1q"
          }
        }
      }
    },
    "CreateUser": {
      "input": {
        "type": "structure",
        "required": [
          "UserId",
          "UserName",
          "Engine",
          "AccessString"
        ],
        "members": {
          "UserId": {},
          "UserName": {},
          "Engine": {},
          "Passwords": {
            "shape": "S3d"
          },
          "AccessString": {},
          "NoPasswordRequired": {
            "type": "boolean"
          },
          "Tags": {
            "shape": "S3"
          }
        }
      },
      "output": {
        "shape": "S3f",
        "resultWrapper": "CreateUserResult"
      }
    },
    "CreateUserGroup": {
      "input": {
        "type": "structure",
        "required": [
          "UserGroupId",
          "Engine"
        ],
        "members": {
          "UserGroupId": {},
          "Engine": {},
          "UserIds": {
            "shape": "S3j"
          },
          "Tags": {
            "shape": "S3"
          }
        }
      },
      "output": {
        "shape": "S3k",
        "resultWrapper": "CreateUserGroupResult"
      }
    },
    "DecreaseNodeGroupsInGlobalReplicationGroup": {
      "input": {
        "type": "structure",
        "required": [
          "GlobalReplicationGroupId",
          "NodeGroupCount",
          "ApplyImmediately"
        ],
        "members": {
          "GlobalReplicationGroupId": {},
          "NodeGroupCount": {
            "type": "integer"
          },
          "GlobalNodeGroupsToRemove": {
            "shape": "S3p"
          },
          "GlobalNodeGroupsToRetain": {
            "shape": "S3p"
          },
          "ApplyImmediately": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "DecreaseNodeGroupsInGlobalReplicationGroupResult",
        "type": "structure",
        "members": {
          "GlobalReplicationGroup": {
            "shape": "S2y"
          }
        }
      }
    },
    "DecreaseReplicaCount": {
      "input": {
        "type": "structure",
        "required": [
          "ReplicationGroupId",
          "ApplyImmediately"
        ],
        "members": {
          "ReplicationGroupId": {},
          "NewReplicaCount": {
            "type": "integer"
          },
          "ReplicaConfiguration": {
            "shape": "S3s"
          },
          "ReplicasToRemove": {
            "type": "list",
            "member": {}
          },
          "ApplyImmediately": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "DecreaseReplicaCountResult",
        "type": "structure",
        "members": {
          "ReplicationGroup": {
            "shape": "So"
          }
        }
      }
    },
    "DeleteCacheCluster": {
      "input": {
        "type": "structure",
        "required": [
          "CacheClusterId"
        ],
        "members": {
          "CacheClusterId": {},
          "FinalSnapshotIdentifier": {}
        }
      },
      "output": {
        "resultWrapper": "DeleteCacheClusterResult",
        "type": "structure",
        "members": {
          "CacheCluster": {
            "shape": "S28"
          }
        }
      }
    },
    "DeleteCacheParameterGroup": {
      "input": {
        "type": "structure",
        "required": [
          "CacheParameterGroupName"
        ],
        "members": {
          "CacheParameterGroupName": {}
        }
      }
    },
    "DeleteCacheSecurityGroup": {
      "input": {
        "type": "structure",
        "required": [
          "CacheSecurityGroupName"
        ],
        "members": {
          "CacheSecurityGroupName": {}
        }
      }
    },
    "DeleteCacheSubnetGroup": {
      "input": {
        "type": "structure",
        "required": [
          "CacheSubnetGroupName"
        ],
        "members": {
          "CacheSubnetGroupName": {}
        }
      }
    },
    "DeleteGlobalReplicationGroup": {
      "input": {
        "type": "structure",
        "required": [
          "GlobalReplicationGroupId",
          "RetainPrimaryReplicationGroup"
        ],
        "members": {
          "GlobalReplicationGroupId": {},
          "RetainPrimaryReplicationGroup": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "DeleteGlobalReplicationGroupResult",
        "type": "structure",
        "members": {
          "GlobalReplicationGroup": {
            "shape": "S2y"
          }
        }
      }
    },
    "DeleteReplicationGroup": {
      "input": {
        "type": "structure",
        "required": [
          "ReplicationGroupId"
        ],
        "members": {
          "ReplicationGroupId": {},
          "RetainPrimaryCluster": {
            "type": "boolean"
          },
          "FinalSnapshotIdentifier": {}
        }
      },
      "output": {
        "resultWrapper": "DeleteReplicationGroupResult",
        "type": "structure",
        "members": {
          "ReplicationGroup": {
            "shape": "So"
          }
        }
      }
    },
    "DeleteSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "SnapshotName"
        ],
        "members": {
          "SnapshotName": {}
        }
      },
      "output": {
        "resultWrapper": "DeleteSnapshotResult",
        "type": "structure",
        "members": {
          "Snapshot": {
            "shape": "S1q"
          }
        }
      }
    },
    "DeleteUser": {
      "input": {
        "type": "structure",
        "required": [
          "UserId"
        ],
        "members": {
          "UserId": {}
        }
      },
      "output": {
        "shape": "S3f",
        "resultWrapper": "DeleteUserResult"
      }
    },
    "DeleteUserGroup": {
      "input": {
        "type": "structure",
        "required": [
          "UserGroupId"
        ],
        "members": {
          "UserGroupId": {}
        }
      },
      "output": {
        "shape": "S3k",
        "resultWrapper": "DeleteUserGroupResult"
      }
    },
    "DescribeCacheClusters": {
      "input": {
        "type": "structure",
        "members": {
          "CacheClusterId": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {},
          "ShowCacheNodeInfo": {
            "type": "boolean"
          },
          "ShowCacheClustersNotInReplicationGroups": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeCacheClustersResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "CacheClusters": {
            "type": "list",
            "member": {
              "shape": "S28",
              "locationName": "CacheCluster"
            }
          }
        }
      }
    },
    "DescribeCacheEngineVersions": {
      "input": {
        "type": "structure",
        "members": {
          "Engine": {},
          "EngineVersion": {},
          "CacheParameterGroupFamily": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {},
          "DefaultOnly": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeCacheEngineVersionsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "CacheEngineVersions": {
            "type": "list",
            "member": {
              "locationName": "CacheEngineVersion",
              "type": "structure",
              "members": {
                "Engine": {},
                "EngineVersion": {},
                "CacheParameterGroupFamily": {},
                "CacheEngineDescription": {},
                "CacheEngineVersionDescription": {}
              }
            }
          }
        }
      }
    },
    "DescribeCacheParameterGroups": {
      "input": {
        "type": "structure",
        "members": {
          "CacheParameterGroupName": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeCacheParameterGroupsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "CacheParameterGroups": {
            "type": "list",
            "member": {
              "shape": "S2l",
              "locationName": "CacheParameterGroup"
            }
          }
        }
      }
    },
    "DescribeCacheParameters": {
      "input": {
        "type": "structure",
        "required": [
          "CacheParameterGroupName"
        ],
        "members": {
          "CacheParameterGroupName": {},
          "Source": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeCacheParametersResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "Parameters": {
            "shape": "S4l"
          },
          "CacheNodeTypeSpecificParameters": {
            "shape": "S4o"
          }
        }
      }
    },
    "DescribeCacheSecurityGroups": {
      "input": {
        "type": "structure",
        "members": {
          "CacheSecurityGroupName": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeCacheSecurityGroupsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "CacheSecurityGroups": {
            "type": "list",
            "member": {
              "shape": "S8",
              "locationName": "CacheSecurityGroup"
            }
          }
        }
      }
    },
    "DescribeCacheSubnetGroups": {
      "input": {
        "type": "structure",
        "members": {
          "CacheSubnetGroupName": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeCacheSubnetGroupsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "CacheSubnetGroups": {
            "type": "list",
            "member": {
              "shape": "S2r",
              "locationName": "CacheSubnetGroup"
            }
          }
        }
      }
    },
    "DescribeEngineDefaultParameters": {
      "input": {
        "type": "structure",
        "required": [
          "CacheParameterGroupFamily"
        ],
        "members": {
          "CacheParameterGroupFamily": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeEngineDefaultParametersResult",
        "type": "structure",
        "members": {
          "EngineDefaults": {
            "type": "structure",
            "members": {
              "CacheParameterGroupFamily": {},
              "Marker": {},
              "Parameters": {
                "shape": "S4l"
              },
              "CacheNodeTypeSpecificParameters": {
                "shape": "S4o"
              }
            },
            "wrapper": true
          }
        }
      }
    },
    "DescribeEvents": {
      "input": {
        "type": "structure",
        "members": {
          "SourceIdentifier": {},
          "SourceType": {},
          "StartTime": {
            "type": "timestamp"
          },
          "EndTime": {
            "type": "timestamp"
          },
          "Duration": {
            "type": "integer"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeEventsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "Events": {
            "type": "list",
            "member": {
              "locationName": "Event",
              "type": "structure",
              "members": {
                "SourceIdentifier": {},
                "SourceType": {},
                "Message": {},
                "Date": {
                  "type": "timestamp"
                }
              }
            }
          }
        }
      }
    },
    "DescribeGlobalReplicationGroups": {
      "input": {
        "type": "structure",
        "members": {
          "GlobalReplicationGroupId": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {},
          "ShowMemberInfo": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeGlobalReplicationGroupsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "GlobalReplicationGroups": {
            "type": "list",
            "member": {
              "shape": "S2y",
              "locationName": "GlobalReplicationGroup"
            }
          }
        }
      }
    },
    "DescribeReplicationGroups": {
      "input": {
        "type": "structure",
        "members": {
          "ReplicationGroupId": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeReplicationGroupsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "ReplicationGroups": {
            "type": "list",
            "member": {
              "shape": "So",
              "locationName": "ReplicationGroup"
            }
          }
        }
      }
    },
    "DescribeReservedCacheNodes": {
      "input": {
        "type": "structure",
        "members": {
          "ReservedCacheNodeId": {},
          "ReservedCacheNodesOfferingId": {},
          "CacheNodeType": {},
          "Duration": {},
          "ProductDescription": {},
          "OfferingType": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeReservedCacheNodesResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "ReservedCacheNodes": {
            "type": "list",
            "member": {
              "shape": "S5f",
              "locationName": "ReservedCacheNode"
            }
          }
        }
      }
    },
    "DescribeReservedCacheNodesOfferings": {
      "input": {
        "type": "structure",
        "members": {
          "ReservedCacheNodesOfferingId": {},
          "CacheNodeType": {},
          "Duration": {},
          "ProductDescription": {},
          "OfferingType": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeReservedCacheNodesOfferingsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "ReservedCacheNodesOfferings": {
            "type": "list",
            "member": {
              "locationName": "ReservedCacheNodesOffering",
              "type": "structure",
              "members": {
                "ReservedCacheNodesOfferingId": {},
                "CacheNodeType": {},
                "Duration": {
                  "type": "integer"
                },
                "FixedPrice": {
                  "type": "double"
                },
                "UsagePrice": {
                  "type": "double"
                },
                "ProductDescription": {},
                "OfferingType": {},
                "RecurringCharges": {
                  "shape": "S5g"
                }
              },
              "wrapper": true
            }
          }
        }
      }
    },
    "DescribeServiceUpdates": {
      "input": {
        "type": "structure",
        "members": {
          "ServiceUpdateName": {},
          "ServiceUpdateStatus": {
            "shape": "S5n"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeServiceUpdatesResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "ServiceUpdates": {
            "type": "list",
            "member": {
              "locationName": "ServiceUpdate",
              "type": "structure",
              "members": {
                "ServiceUpdateName": {},
                "ServiceUpdateReleaseDate": {
                  "type": "timestamp"
                },
                "ServiceUpdateEndDate": {
                  "type": "timestamp"
                },
                "ServiceUpdateSeverity": {},
                "ServiceUpdateRecommendedApplyByDate": {
                  "type": "timestamp"
                },
                "ServiceUpdateStatus": {},
                "ServiceUpdateDescription": {},
                "ServiceUpdateType": {},
                "Engine": {},
                "EngineVersion": {},
                "AutoUpdateAfterRecommendedApplyByDate": {
                  "type": "boolean"
                },
                "EstimatedUpdateTime": {}
              }
            }
          }
        }
      }
    },
    "DescribeSnapshots": {
      "input": {
        "type": "structure",
        "members": {
          "ReplicationGroupId": {},
          "CacheClusterId": {},
          "SnapshotName": {},
          "SnapshotSource": {},
          "Marker": {},
          "MaxRecords": {
            "type": "integer"
          },
          "ShowNodeGroupConfig": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeSnapshotsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "Snapshots": {
            "type": "list",
            "member": {
              "shape": "S1q",
              "locationName": "Snapshot"
            }
          }
        }
      }
    },
    "DescribeUpdateActions": {
      "input": {
        "type": "structure",
        "members": {
          "ServiceUpdateName": {},
          "ReplicationGroupIds": {
            "shape": "Sc"
          },
          "CacheClusterIds": {
            "shape": "Sd"
          },
          "Engine": {},
          "ServiceUpdateStatus": {
            "shape": "S5n"
          },
          "ServiceUpdateTimeRange": {
            "type": "structure",
            "members": {
              "StartTime": {
                "type": "timestamp"
              },
              "EndTime": {
                "type": "timestamp"
              }
            }
          },
          "UpdateActionStatus": {
            "type": "list",
            "member": {}
          },
          "ShowNodeLevelUpdateStatus": {
            "type": "boolean"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeUpdateActionsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "UpdateActions": {
            "type": "list",
            "member": {
              "locationName": "UpdateAction",
              "type": "structure",
              "members": {
                "ReplicationGroupId": {},
                "CacheClusterId": {},
                "ServiceUpdateName": {},
                "ServiceUpdateReleaseDate": {
                  "type": "timestamp"
                },
                "ServiceUpdateSeverity": {},
                "ServiceUpdateStatus": {},
                "ServiceUpdateRecommendedApplyByDate": {
                  "type": "timestamp"
                },
                "ServiceUpdateType": {},
                "UpdateActionAvailableDate": {
                  "type": "timestamp"
                },
                "UpdateActionStatus": {},
                "NodesUpdated": {},
                "UpdateActionStatusModifiedDate": {
                  "type": "timestamp"
                },
                "SlaMet": {},
                "NodeGroupUpdateStatus": {
                  "type": "list",
                  "member": {
                    "locationName": "NodeGroupUpdateStatus",
                    "type": "structure",
                    "members": {
                      "NodeGroupId": {},
                      "NodeGroupMemberUpdateStatus": {
                        "type": "list",
                        "member": {
                          "locationName": "NodeGroupMemberUpdateStatus",
                          "type": "structure",
                          "members": {
                            "CacheClusterId": {},
                            "CacheNodeId": {},
                            "NodeUpdateStatus": {},
                            "NodeDeletionDate": {
                              "type": "timestamp"
                            },
                            "NodeUpdateStartDate": {
                              "type": "timestamp"
                            },
                            "NodeUpdateEndDate": {
                              "type": "timestamp"
                            },
                            "NodeUpdateInitiatedBy": {},
                            "NodeUpdateInitiatedDate": {
                              "type": "timestamp"
                            },
                            "NodeUpdateStatusModifiedDate": {
                              "type": "timestamp"
                            }
                          }
                        }
                      }
                    }
                  }
                },
                "CacheNodeUpdateStatus": {
                  "type": "list",
                  "member": {
                    "locationName": "CacheNodeUpdateStatus",
                    "type": "structure",
                    "members": {
                      "CacheNodeId": {},
                      "NodeUpdateStatus": {},
                      "NodeDeletionDate": {
                        "type": "timestamp"
                      },
                      "NodeUpdateStartDate": {
                        "type": "timestamp"
                      },
                      "NodeUpdateEndDate": {
                        "type": "timestamp"
                      },
                      "NodeUpdateInitiatedBy": {},
                      "NodeUpdateInitiatedDate": {
                        "type": "timestamp"
                      },
                      "NodeUpdateStatusModifiedDate": {
                        "type": "timestamp"
                      }
                    }
                  }
                },
                "EstimatedUpdateTime": {},
                "Engine": {}
              }
            }
          }
        }
      }
    },
    "DescribeUserGroups": {
      "input": {
        "type": "structure",
        "members": {
          "UserGroupId": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeUserGroupsResult",
        "type": "structure",
        "members": {
          "UserGroups": {
            "type": "list",
            "member": {
              "shape": "S3k"
            }
          },
          "Marker": {}
        }
      }
    },
    "DescribeUsers": {
      "input": {
        "type": "structure",
        "members": {
          "Engine": {},
          "UserId": {},
          "Filters": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Name",
                "Values"
              ],
              "members": {
                "Name": {},
                "Values": {
                  "type": "list",
                  "member": {}
                }
              }
            }
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeUsersResult",
        "type": "structure",
        "members": {
          "Users": {
            "type": "list",
            "member": {
              "shape": "S3f"
            }
          },
          "Marker": {}
        }
      }
    },
    "DisassociateGlobalReplicationGroup": {
      "input": {
        "type": "structure",
        "required": [
          "GlobalReplicationGroupId",
          "ReplicationGroupId",
          "ReplicationGroupRegion"
        ],
        "members": {
          "GlobalReplicationGroupId": {},
          "ReplicationGroupId": {},
          "ReplicationGroupRegion": {}
        }
      },
      "output": {
        "resultWrapper": "DisassociateGlobalReplicationGroupResult",
        "type": "structure",
        "members": {
          "GlobalReplicationGroup": {
            "shape": "S2y"
          }
        }
      }
    },
    "FailoverGlobalReplicationGroup": {
      "input": {
        "type": "structure",
        "required": [
          "GlobalReplicationGroupId",
          "PrimaryRegion",
          "PrimaryReplicationGroupId"
        ],
        "members": {
          "GlobalReplicationGroupId": {},
          "PrimaryRegion": {},
          "PrimaryReplicationGroupId": {}
        }
      },
      "output": {
        "resultWrapper": "FailoverGlobalReplicationGroupResult",
        "type": "structure",
        "members": {
          "GlobalReplicationGroup": {
            "shape": "S2y"
          }
        }
      }
    },
    "IncreaseNodeGroupsInGlobalReplicationGroup": {
      "input": {
        "type": "structure",
        "required": [
          "GlobalReplicationGroupId",
          "NodeGroupCount",
          "ApplyImmediately"
        ],
        "members": {
          "GlobalReplicationGroupId": {},
          "NodeGroupCount": {
            "type": "integer"
          },
          "RegionalConfigurations": {
            "type": "list",
            "member": {
              "locationName": "RegionalConfiguration",
              "type": "structure",
              "required": [
                "ReplicationGroupId",
                "ReplicationGroupRegion",
                "ReshardingConfiguration"
              ],
              "members": {
                "ReplicationGroupId": {},
                "ReplicationGroupRegion": {},
                "ReshardingConfiguration": {
                  "shape": "S6u"
                }
              }
            }
          },
          "ApplyImmediately": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "IncreaseNodeGroupsInGlobalReplicationGroupResult",
        "type": "structure",
        "members": {
          "GlobalReplicationGroup": {
            "shape": "S2y"
          }
        }
      }
    },
    "IncreaseReplicaCount": {
      "input": {
        "type": "structure",
        "required": [
          "ReplicationGroupId",
          "ApplyImmediately"
        ],
        "members": {
          "ReplicationGroupId": {},
          "NewReplicaCount": {
            "type": "integer"
          },
          "ReplicaConfiguration": {
            "shape": "S3s"
          },
          "ApplyImmediately": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "IncreaseReplicaCountResult",
        "type": "structure",
        "members": {
          "ReplicationGroup": {
            "shape": "So"
          }
        }
      }
    },
    "ListAllowedNodeTypeModifications": {
      "input": {
        "type": "structure",
        "members": {
          "CacheClusterId": {},
          "ReplicationGroupId": {}
        }
      },
      "output": {
        "resultWrapper": "ListAllowedNodeTypeModificationsResult",
        "type": "structure",
        "members": {
          "ScaleUpModifications": {
            "shape": "S71"
          },
          "ScaleDownModifications": {
            "shape": "S71"
          }
        }
      }
    },
    "ListTagsForResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceName"
        ],
        "members": {
          "ResourceName": {}
        }
      },
      "output": {
        "shape": "S5",
        "resultWrapper": "ListTagsForResourceResult"
      }
    },
    "ModifyCacheCluster": {
      "input": {
        "type": "structure",
        "required": [
          "CacheClusterId"
        ],
        "members": {
          "CacheClusterId": {},
          "NumCacheNodes": {
            "type": "integer"
          },
          "CacheNodeIdsToRemove": {
            "shape": "S2a"
          },
          "AZMode": {},
          "NewAvailabilityZones": {
            "shape": "S1z"
          },
          "CacheSecurityGroupNames": {
            "shape": "S20"
          },
          "SecurityGroupIds": {
            "shape": "S21"
          },
          "PreferredMaintenanceWindow": {},
          "NotificationTopicArn": {},
          "CacheParameterGroupName": {},
          "NotificationTopicStatus": {},
          "ApplyImmediately": {
            "type": "boolean"
          },
          "EngineVersion": {},
          "AutoMinorVersionUpgrade": {
            "type": "boolean"
          },
          "SnapshotRetentionLimit": {
            "type": "integer"
          },
          "SnapshotWindow": {},
          "CacheNodeType": {},
          "AuthToken": {},
          "AuthTokenUpdateStrategy": {},
          "LogDeliveryConfigurations": {
            "shape": "S25"
          }
        }
      },
      "output": {
        "resultWrapper": "ModifyCacheClusterResult",
        "type": "structure",
        "members": {
          "CacheCluster": {
            "shape": "S28"
          }
        }
      }
    },
    "ModifyCacheParameterGroup": {
      "input": {
        "type": "structure",
        "required": [
          "CacheParameterGroupName",
          "ParameterNameValues"
        ],
        "members": {
          "CacheParameterGroupName": {},
          "ParameterNameValues": {
            "shape": "S77"
          }
        }
      },
      "output": {
        "shape": "S79",
        "resultWrapper": "ModifyCacheParameterGroupResult"
      }
    },
    "ModifyCacheSubnetGroup": {
      "input": {
        "type": "structure",
        "required": [
          "CacheSubnetGroupName"
        ],
        "members": {
          "CacheSubnetGroupName": {},
          "CacheSubnetGroupDescription": {},
          "SubnetIds": {
            "shape": "S2p"
          }
        }
      },
      "output": {
        "resultWrapper": "ModifyCacheSubnetGroupResult",
        "type": "structure",
        "members": {
          "CacheSubnetGroup": {
            "shape": "S2r"
          }
        }
      }
    },
    "ModifyGlobalReplicationGroup": {
      "input": {
        "type": "structure",
        "required": [
          "GlobalReplicationGroupId",
          "ApplyImmediately"
        ],
        "members": {
          "GlobalReplicationGroupId": {},
          "ApplyImmediately": {
            "type": "boolean"
          },
          "CacheNodeType": {},
          "EngineVersion": {},
          "CacheParameterGroupName": {},
          "GlobalReplicationGroupDescription": {},
          "AutomaticFailoverEnabled": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "ModifyGlobalReplicationGroupResult",
        "type": "structure",
        "members": {
          "GlobalReplicationGroup": {
            "shape": "S2y"
          }
        }
      }
    },
    "ModifyReplicationGroup": {
      "input": {
        "type": "structure",
        "required": [
          "ReplicationGroupId"
        ],
        "members": {
          "ReplicationGroupId": {},
          "ReplicationGroupDescription": {},
          "PrimaryClusterId": {},
          "SnapshottingClusterId": {},
          "AutomaticFailoverEnabled": {
            "type": "boolean"
          },
          "MultiAZEnabled": {
            "type": "boolean"
          },
          "NodeGroupId": {
            "deprecated": true
          },
          "CacheSecurityGroupNames": {
            "shape": "S20"
          },
          "SecurityGroupIds": {
            "shape": "S21"
          },
          "PreferredMaintenanceWindow": {},
          "NotificationTopicArn": {},
          "CacheParameterGroupName": {},
          "NotificationTopicStatus": {},
          "ApplyImmediately": {
            "type": "boolean"
          },
          "EngineVersion": {},
          "AutoMinorVersionUpgrade": {
            "type": "boolean"
          },
          "SnapshotRetentionLimit": {
            "type": "integer"
          },
          "SnapshotWindow": {},
          "CacheNodeType": {},
          "AuthToken": {},
          "AuthTokenUpdateStrategy": {},
          "UserGroupIdsToAdd": {
            "shape": "Sx"
          },
          "UserGroupIdsToRemove": {
            "shape": "Sx"
          },
          "RemoveUserGroups": {
            "type": "boolean"
          },
          "LogDeliveryConfigurations": {
            "shape": "S25"
          }
        }
      },
      "output": {
        "resultWrapper": "ModifyReplicationGroupResult",
        "type": "structure",
        "members": {
          "ReplicationGroup": {
            "shape": "So"
          }
        }
      }
    },
    "ModifyReplicationGroupShardConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "ReplicationGroupId",
          "NodeGroupCount",
          "ApplyImmediately"
        ],
        "members": {
          "ReplicationGroupId": {},
          "NodeGroupCount": {
            "type": "integer"
          },
          "ApplyImmediately": {
            "type": "boolean"
          },
          "ReshardingConfiguration": {
            "shape": "S6u"
          },
          "NodeGroupsToRemove": {
            "type": "list",
            "member": {
              "locationName": "NodeGroupToRemove"
            }
          },
          "NodeGroupsToRetain": {
            "type": "list",
            "member": {
              "locationName": "NodeGroupToRetain"
            }
          }
        }
      },
      "output": {
        "resultWrapper": "ModifyReplicationGroupShardConfigurationResult",
        "type": "structure",
        "members": {
          "ReplicationGroup": {
            "shape": "So"
          }
        }
      }
    },
    "ModifyUser": {
      "input": {
        "type": "structure",
        "required": [
          "UserId"
        ],
        "members": {
          "UserId": {},
          "AccessString": {},
          "AppendAccessString": {},
          "Passwords": {
            "shape": "S3d"
          },
          "NoPasswordRequired": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "shape": "S3f",
        "resultWrapper": "ModifyUserResult"
      }
    },
    "ModifyUserGroup": {
      "input": {
        "type": "structure",
        "required": [
          "UserGroupId"
        ],
        "members": {
          "UserGroupId": {},
          "UserIdsToAdd": {
            "shape": "S3j"
          },
          "UserIdsToRemove": {
            "shape": "S3j"
          }
        }
      },
      "output": {
        "shape": "S3k",
        "resultWrapper": "ModifyUserGroupResult"
      }
    },
    "PurchaseReservedCacheNodesOffering": {
      "input": {
        "type": "structure",
        "required": [
          "ReservedCacheNodesOfferingId"
        ],
        "members": {
          "ReservedCacheNodesOfferingId": {},
          "ReservedCacheNodeId": {},
          "CacheNodeCount": {
            "type": "integer"
          },
          "Tags": {
            "shape": "S3"
          }
        }
      },
      "output": {
        "resultWrapper": "PurchaseReservedCacheNodesOfferingResult",
        "type": "structure",
        "members": {
          "ReservedCacheNode": {
            "shape": "S5f"
          }
        }
      }
    },
    "RebalanceSlotsInGlobalReplicationGroup": {
      "input": {
        "type": "structure",
        "required": [
          "GlobalReplicationGroupId",
          "ApplyImmediately"
        ],
        "members": {
          "GlobalReplicationGroupId": {},
          "ApplyImmediately": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "RebalanceSlotsInGlobalReplicationGroupResult",
        "type": "structure",
        "members": {
          "GlobalReplicationGroup": {
            "shape": "S2y"
          }
        }
      }
    },
    "RebootCacheCluster": {
      "input": {
        "type": "structure",
        "required": [
          "CacheClusterId",
          "CacheNodeIdsToReboot"
        ],
        "members": {
          "CacheClusterId": {},
          "CacheNodeIdsToReboot": {
            "shape": "S2a"
          }
        }
      },
      "output": {
        "resultWrapper": "RebootCacheClusterResult",
        "type": "structure",
        "members": {
          "CacheCluster": {
            "shape": "S28"
          }
        }
      }
    },
    "RemoveTagsFromResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceName",
          "TagKeys"
        ],
        "members": {
          "ResourceName": {},
          "TagKeys": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "shape": "S5",
        "resultWrapper": "RemoveTagsFromResourceResult"
      }
    },
    "ResetCacheParameterGroup": {
      "input": {
        "type": "structure",
        "required": [
          "CacheParameterGroupName"
        ],
        "members": {
          "CacheParameterGroupName": {},
          "ResetAllParameters": {
            "type": "boolean"
          },
          "ParameterNameValues": {
            "shape": "S77"
          }
        }
      },
      "output": {
        "shape": "S79",
        "resultWrapper": "ResetCacheParameterGroupResult"
      }
    },
    "RevokeCacheSecurityGroupIngress": {
      "input": {
        "type": "structure",
        "required": [
          "CacheSecurityGroupName",
          "EC2SecurityGroupName",
          "EC2SecurityGroupOwnerId"
        ],
        "members": {
          "CacheSecurityGroupName": {},
          "EC2SecurityGroupName": {},
          "EC2SecurityGroupOwnerId": {}
        }
      },
      "output": {
        "resultWrapper": "RevokeCacheSecurityGroupIngressResult",
        "type": "structure",
        "members": {
          "CacheSecurityGroup": {
            "shape": "S8"
          }
        }
      }
    },
    "StartMigration": {
      "input": {
        "type": "structure",
        "required": [
          "ReplicationGroupId",
          "CustomerNodeEndpointList"
        ],
        "members": {
          "ReplicationGroupId": {},
          "CustomerNodeEndpointList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Address": {},
                "Port": {
                  "type": "integer"
                }
              }
            }
          }
        }
      },
      "output": {
        "resultWrapper": "StartMigrationResult",
        "type": "structure",
        "members": {
          "ReplicationGroup": {
            "shape": "So"
          }
        }
      }
    },
    "TestFailover": {
      "input": {
        "type": "structure",
        "required": [
          "ReplicationGroupId",
          "NodeGroupId"
        ],
        "members": {
          "ReplicationGroupId": {},
          "NodeGroupId": {}
        }
      },
      "output": {
        "resultWrapper": "TestFailoverResult",
        "type": "structure",
        "members": {
          "ReplicationGroup": {
            "shape": "So"
          }
        }
      }
    }
  },
  "shapes": {
    "S3": {
      "type": "list",
      "member": {
        "locationName": "Tag",
        "type": "structure",
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "S5": {
      "type": "structure",
      "members": {
        "TagList": {
          "shape": "S3"
        }
      }
    },
    "S8": {
      "type": "structure",
      "members": {
        "OwnerId": {},
        "CacheSecurityGroupName": {},
        "Description": {},
        "EC2SecurityGroups": {
          "type": "list",
          "member": {
            "locationName": "EC2SecurityGroup",
            "type": "structure",
            "members": {
              "Status": {},
              "EC2SecurityGroupName": {},
              "EC2SecurityGroupOwnerId": {}
            }
          }
        },
        "ARN": {}
      },
      "wrapper": true
    },
    "Sc": {
      "type": "list",
      "member": {}
    },
    "Sd": {
      "type": "list",
      "member": {}
    },
    "Se": {
      "type": "structure",
      "members": {
        "ProcessedUpdateActions": {
          "type": "list",
          "member": {
            "locationName": "ProcessedUpdateAction",
            "type": "structure",
            "members": {
              "ReplicationGroupId": {},
              "CacheClusterId": {},
              "ServiceUpdateName": {},
              "UpdateActionStatus": {}
            }
          }
        },
        "UnprocessedUpdateActions": {
          "type": "list",
          "member": {
            "locationName": "UnprocessedUpdateAction",
            "type": "structure",
            "members": {
              "ReplicationGroupId": {},
              "CacheClusterId": {},
              "ServiceUpdateName": {},
              "ErrorType": {},
              "ErrorMessage": {}
            }
          }
        }
      }
    },
    "So": {
      "type": "structure",
      "members": {
        "ReplicationGroupId": {},
        "Description": {},
        "GlobalReplicationGroupInfo": {
          "type": "structure",
          "members": {
            "GlobalReplicationGroupId": {},
            "GlobalReplicationGroupMemberRole": {}
          }
        },
        "Status": {},
        "PendingModifiedValues": {
          "type": "structure",
          "members": {
            "PrimaryClusterId": {},
            "AutomaticFailoverStatus": {},
            "Resharding": {
              "type": "structure",
              "members": {
                "SlotMigration": {
                  "type": "structure",
                  "members": {
                    "ProgressPercentage": {
                      "type": "double"
                    }
                  }
                }
              }
            },
            "AuthTokenStatus": {},
            "UserGroups": {
              "type": "structure",
              "members": {
                "UserGroupIdsToAdd": {
                  "shape": "Sx"
                },
                "UserGroupIdsToRemove": {
                  "shape": "Sx"
                }
              }
            },
            "LogDeliveryConfigurations": {
              "shape": "Sz"
            }
          }
        },
        "MemberClusters": {
          "type": "list",
          "member": {
            "locationName": "ClusterId"
          }
        },
        "NodeGroups": {
          "type": "list",
          "member": {
            "locationName": "NodeGroup",
            "type": "structure",
            "members": {
              "NodeGroupId": {},
              "Status": {},
              "PrimaryEndpoint": {
                "shape": "S1a"
              },
              "ReaderEndpoint": {
                "shape": "S1a"
              },
              "Slots": {},
              "NodeGroupMembers": {
                "type": "list",
                "member": {
                  "locationName": "NodeGroupMember",
                  "type": "structure",
                  "members": {
                    "CacheClusterId": {},
                    "CacheNodeId": {},
                    "ReadEndpoint": {
                      "shape": "S1a"
                    },
                    "PreferredAvailabilityZone": {},
                    "PreferredOutpostArn": {},
                    "CurrentRole": {}
                  }
                }
              }
            }
          }
        },
        "SnapshottingClusterId": {},
        "AutomaticFailover": {},
        "MultiAZ": {},
        "ConfigurationEndpoint": {
          "shape": "S1a"
        },
        "SnapshotRetentionLimit": {
          "type": "integer"
        },
        "SnapshotWindow": {},
        "ClusterEnabled": {
          "type": "boolean"
        },
        "CacheNodeType": {},
        "AuthTokenEnabled": {
          "type": "boolean"
        },
        "AuthTokenLastModifiedDate": {
          "type": "timestamp"
        },
        "TransitEncryptionEnabled": {
          "type": "boolean"
        },
        "AtRestEncryptionEnabled": {
          "type": "boolean"
        },
        "MemberClustersOutpostArns": {
          "type": "list",
          "member": {
            "locationName": "ReplicationGroupOutpostArn"
          }
        },
        "KmsKeyId": {},
        "ARN": {},
        "UserGroupIds": {
          "shape": "Sx"
        },
        "LogDeliveryConfigurations": {
          "shape": "S1k"
        },
        "ReplicationGroupCreateTime": {
          "type": "timestamp"
        },
        "DataTiering": {}
      },
      "wrapper": true
    },
    "Sx": {
      "type": "list",
      "member": {}
    },
    "Sz": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "LogType": {},
          "DestinationType": {},
          "DestinationDetails": {
            "shape": "S13"
          },
          "LogFormat": {}
        }
      },
      "locationName": "PendingLogDeliveryConfiguration"
    },
    "S13": {
      "type": "structure",
      "members": {
        "CloudWatchLogsDetails": {
          "type": "structure",
          "members": {
            "LogGroup": {}
          }
        },
        "KinesisFirehoseDetails": {
          "type": "structure",
          "members": {
            "DeliveryStream": {}
          }
        }
      }
    },
    "S1a": {
      "type": "structure",
      "members": {
        "Address": {},
        "Port": {
          "type": "integer"
        }
      }
    },
    "S1k": {
      "type": "list",
      "member": {
        "locationName": "LogDeliveryConfiguration",
        "type": "structure",
        "members": {
          "LogType": {},
          "DestinationType": {},
          "DestinationDetails": {
            "shape": "S13"
          },
          "LogFormat": {},
          "Status": {},
          "Message": {}
        }
      }
    },
    "S1q": {
      "type": "structure",
      "members": {
        "SnapshotName": {},
        "ReplicationGroupId": {},
        "ReplicationGroupDescription": {},
        "CacheClusterId": {},
        "SnapshotStatus": {},
        "SnapshotSource": {},
        "CacheNodeType": {},
        "Engine": {},
        "EngineVersion": {},
        "NumCacheNodes": {
          "type": "integer"
        },
        "PreferredAvailabilityZone": {},
        "PreferredOutpostArn": {},
        "CacheClusterCreateTime": {
          "type": "timestamp"
        },
        "PreferredMaintenanceWindow": {},
        "TopicArn": {},
        "Port": {
          "type": "integer"
        },
        "CacheParameterGroupName": {},
        "CacheSubnetGroupName": {},
        "VpcId": {},
        "AutoMinorVersionUpgrade": {
          "type": "boolean"
        },
        "SnapshotRetentionLimit": {
          "type": "integer"
        },
        "SnapshotWindow": {},
        "NumNodeGroups": {
          "type": "integer"
        },
        "AutomaticFailover": {},
        "NodeSnapshots": {
          "type": "list",
          "member": {
            "locationName": "NodeSnapshot",
            "type": "structure",
            "members": {
              "CacheClusterId": {},
              "NodeGroupId": {},
              "CacheNodeId": {},
              "NodeGroupConfiguration": {
                "shape": "S1t"
              },
              "CacheSize": {},
              "CacheNodeCreateTime": {
                "type": "timestamp"
              },
              "SnapshotCreateTime": {
                "type": "timestamp"
              }
            },
            "wrapper": true
          }
        },
        "KmsKeyId": {},
        "ARN": {},
        "DataTiering": {}
      },
      "wrapper": true
    },
    "S1t": {
      "type": "structure",
      "members": {
        "NodeGroupId": {},
        "Slots": {},
        "ReplicaCount": {
          "type": "integer"
        },
        "PrimaryAvailabilityZone": {},
        "ReplicaAvailabilityZones": {
          "shape": "S1v"
        },
        "PrimaryOutpostArn": {},
        "ReplicaOutpostArns": {
          "type": "list",
          "member": {
            "locationName": "OutpostArn"
          }
        }
      }
    },
    "S1v": {
      "type": "list",
      "member": {
        "locationName": "AvailabilityZone"
      }
    },
    "S1z": {
      "type": "list",
      "member": {
        "locationName": "PreferredAvailabilityZone"
      }
    },
    "S20": {
      "type": "list",
      "member": {
        "locationName": "CacheSecurityGroupName"
      }
    },
    "S21": {
      "type": "list",
      "member": {
        "locationName": "SecurityGroupId"
      }
    },
    "S22": {
      "type": "list",
      "member": {
        "locationName": "SnapshotArn"
      }
    },
    "S24": {
      "type": "list",
      "member": {
        "locationName": "PreferredOutpostArn"
      }
    },
    "S25": {
      "type": "list",
      "member": {
        "locationName": "LogDeliveryConfigurationRequest",
        "type": "structure",
        "members": {
          "LogType": {},
          "DestinationType": {},
          "DestinationDetails": {
            "shape": "S13"
          },
          "LogFormat": {},
          "Enabled": {
            "type": "boolean"
          }
        }
      }
    },
    "S28": {
      "type": "structure",
      "members": {
        "CacheClusterId": {},
        "ConfigurationEndpoint": {
          "shape": "S1a"
        },
        "ClientDownloadLandingPage": {},
        "CacheNodeType": {},
        "Engine": {},
        "EngineVersion": {},
        "CacheClusterStatus": {},
        "NumCacheNodes": {
          "type": "integer"
        },
        "PreferredAvailabilityZone": {},
        "PreferredOutpostArn": {},
        "CacheClusterCreateTime": {
          "type": "timestamp"
        },
        "PreferredMaintenanceWindow": {},
        "PendingModifiedValues": {
          "type": "structure",
          "members": {
            "NumCacheNodes": {
              "type": "integer"
            },
            "CacheNodeIdsToRemove": {
              "shape": "S2a"
            },
            "EngineVersion": {},
            "CacheNodeType": {},
            "AuthTokenStatus": {},
            "LogDeliveryConfigurations": {
              "shape": "Sz"
            }
          }
        },
        "NotificationConfiguration": {
          "type": "structure",
          "members": {
            "TopicArn": {},
            "TopicStatus": {}
          }
        },
        "CacheSecurityGroups": {
          "type": "list",
          "member": {
            "locationName": "CacheSecurityGroup",
            "type": "structure",
            "members": {
              "CacheSecurityGroupName": {},
              "Status": {}
            }
          }
        },
        "CacheParameterGroup": {
          "type": "structure",
          "members": {
            "CacheParameterGroupName": {},
            "ParameterApplyStatus": {},
            "CacheNodeIdsToReboot": {
              "shape": "S2a"
            }
          }
        },
        "CacheSubnetGroupName": {},
        "CacheNodes": {
          "type": "list",
          "member": {
            "locationName": "CacheNode",
            "type": "structure",
            "members": {
              "CacheNodeId": {},
              "CacheNodeStatus": {},
              "CacheNodeCreateTime": {
                "type": "timestamp"
              },
              "Endpoint": {
                "shape": "S1a"
              },
              "ParameterGroupStatus": {},
              "SourceCacheNodeId": {},
              "CustomerAvailabilityZone": {},
              "CustomerOutpostArn": {}
            }
          }
        },
        "AutoMinorVersionUpgrade": {
          "type": "boolean"
        },
        "SecurityGroups": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "SecurityGroupId": {},
              "Status": {}
            }
          }
        },
        "ReplicationGroupId": {},
        "SnapshotRetentionLimit": {
          "type": "integer"
        },
        "SnapshotWindow": {},
        "AuthTokenEnabled": {
          "type": "boolean"
        },
        "AuthTokenLastModifiedDate": {
          "type": "timestamp"
        },
        "TransitEncryptionEnabled": {
          "type": "boolean"
        },
        "AtRestEncryptionEnabled": {
          "type": "boolean"
        },
        "ARN": {},
        "ReplicationGroupLogDeliveryEnabled": {
          "type": "boolean"
        },
        "LogDeliveryConfigurations": {
          "shape": "S1k"
        }
      },
      "wrapper": true
    },
    "S2a": {
      "type": "list",
      "member": {
        "locationName": "CacheNodeId"
      }
    },
    "S2l": {
      "type": "structure",
      "members": {
        "CacheParameterGroupName": {},
        "CacheParameterGroupFamily": {},
        "Description": {},
        "IsGlobal": {
          "type": "boolean"
        },
        "ARN": {}
      },
      "wrapper": true
    },
    "S2p": {
      "type": "list",
      "member": {
        "locationName": "SubnetIdentifier"
      }
    },
    "S2r": {
      "type": "structure",
      "members": {
        "CacheSubnetGroupName": {},
        "CacheSubnetGroupDescription": {},
        "VpcId": {},
        "Subnets": {
          "type": "list",
          "member": {
            "locationName": "Subnet",
            "type": "structure",
            "members": {
              "SubnetIdentifier": {},
              "SubnetAvailabilityZone": {
                "type": "structure",
                "members": {
                  "Name": {}
                },
                "wrapper": true
              },
              "SubnetOutpost": {
                "type": "structure",
                "members": {
                  "SubnetOutpostArn": {}
                }
              }
            }
          }
        },
        "ARN": {}
      },
      "wrapper": true
    },
    "S2y": {
      "type": "structure",
      "members": {
        "GlobalReplicationGroupId": {},
        "GlobalReplicationGroupDescription": {},
        "Status": {},
        "CacheNodeType": {},
        "Engine": {},
        "EngineVersion": {},
        "Members": {
          "type": "list",
          "member": {
            "locationName": "GlobalReplicationGroupMember",
            "type": "structure",
            "members": {
              "ReplicationGroupId": {},
              "ReplicationGroupRegion": {},
              "Role": {},
              "AutomaticFailover": {},
              "Status": {}
            },
            "wrapper": true
          }
        },
        "ClusterEnabled": {
          "type": "boolean"
        },
        "GlobalNodeGroups": {
          "type": "list",
          "member": {
            "locationName": "GlobalNodeGroup",
            "type": "structure",
            "members": {
              "GlobalNodeGroupId": {},
              "Slots": {}
            }
          }
        },
        "AuthTokenEnabled": {
          "type": "boolean"
        },
        "TransitEncryptionEnabled": {
          "type": "boolean"
        },
        "AtRestEncryptionEnabled": {
          "type": "boolean"
        },
        "ARN": {}
      },
      "wrapper": true
    },
    "S3d": {
      "type": "list",
      "member": {}
    },
    "S3f": {
      "type": "structure",
      "members": {
        "UserId": {},
        "UserName": {},
        "Status": {},
        "Engine": {},
        "MinimumEngineVersion": {},
        "AccessString": {},
        "UserGroupIds": {
          "shape": "Sx"
        },
        "Authentication": {
          "type": "structure",
          "members": {
            "Type": {},
            "PasswordCount": {
              "type": "integer"
            }
          }
        },
        "ARN": {}
      }
    },
    "S3j": {
      "type": "list",
      "member": {}
    },
    "S3k": {
      "type": "structure",
      "members": {
        "UserGroupId": {},
        "Status": {},
        "Engine": {},
        "UserIds": {
          "shape": "S3l"
        },
        "MinimumEngineVersion": {},
        "PendingChanges": {
          "type": "structure",
          "members": {
            "UserIdsToRemove": {
              "shape": "S3l"
            },
            "UserIdsToAdd": {
              "shape": "S3l"
            }
          }
        },
        "ReplicationGroups": {
          "type": "list",
          "member": {}
        },
        "ARN": {}
      }
    },
    "S3l": {
      "type": "list",
      "member": {}
    },
    "S3p": {
      "type": "list",
      "member": {
        "locationName": "GlobalNodeGroupId"
      }
    },
    "S3s": {
      "type": "list",
      "member": {
        "locationName": "ConfigureShard",
        "type": "structure",
        "required": [
          "NodeGroupId",
          "NewReplicaCount"
        ],
        "members": {
          "NodeGroupId": {},
          "NewReplicaCount": {
            "type": "integer"
          },
          "PreferredAvailabilityZones": {
            "shape": "S1z"
          },
          "PreferredOutpostArns": {
            "shape": "S24"
          }
        }
      }
    },
    "S4l": {
      "type": "list",
      "member": {
        "locationName": "Parameter",
        "type": "structure",
        "members": {
          "ParameterName": {},
          "ParameterValue": {},
          "Description": {},
          "Source": {},
          "DataType": {},
          "AllowedValues": {},
          "IsModifiable": {
            "type": "boolean"
          },
          "MinimumEngineVersion": {},
          "ChangeType": {}
        }
      }
    },
    "S4o": {
      "type": "list",
      "member": {
        "locationName": "CacheNodeTypeSpecificParameter",
        "type": "structure",
        "members": {
          "ParameterName": {},
          "Description": {},
          "Source": {},
          "DataType": {},
          "AllowedValues": {},
          "IsModifiable": {
            "type": "boolean"
          },
          "MinimumEngineVersion": {},
          "CacheNodeTypeSpecificValues": {
            "type": "list",
            "member": {
              "locationName": "CacheNodeTypeSpecificValue",
              "type": "structure",
              "members": {
                "CacheNodeType": {},
                "Value": {}
              }
            }
          },
          "ChangeType": {}
        }
      }
    },
    "S5f": {
      "type": "structure",
      "members": {
        "ReservedCacheNodeId": {},
        "ReservedCacheNodesOfferingId": {},
        "CacheNodeType": {},
        "StartTime": {
          "type": "timestamp"
        },
        "Duration": {
          "type": "integer"
        },
        "FixedPrice": {
          "type": "double"
        },
        "UsagePrice": {
          "type": "double"
        },
        "CacheNodeCount": {
          "type": "integer"
        },
        "ProductDescription": {},
        "OfferingType": {},
        "State": {},
        "RecurringCharges": {
          "shape": "S5g"
        },
        "ReservationARN": {}
      },
      "wrapper": true
    },
    "S5g": {
      "type": "list",
      "member": {
        "locationName": "RecurringCharge",
        "type": "structure",
        "members": {
          "RecurringChargeAmount": {
            "type": "double"
          },
          "RecurringChargeFrequency": {}
        },
        "wrapper": true
      }
    },
    "S5n": {
      "type": "list",
      "member": {}
    },
    "S6u": {
      "type": "list",
      "member": {
        "locationName": "ReshardingConfiguration",
        "type": "structure",
        "members": {
          "NodeGroupId": {},
          "PreferredAvailabilityZones": {
            "shape": "S1v"
          }
        }
      }
    },
    "S71": {
      "type": "list",
      "member": {}
    },
    "S77": {
      "type": "list",
      "member": {
        "locationName": "ParameterNameValue",
        "type": "structure",
        "members": {
          "ParameterName": {},
          "ParameterValue": {}
        }
      }
    },
    "S79": {
      "type": "structure",
      "members": {
        "CacheParameterGroupName": {}
      }
    }
  }
}