{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2017-07-01",
    "endpointPrefix": "translate",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceFullName": "Amazon Translate",
    "serviceId": "Translate",
    "signatureVersion": "v4",
    "signingName": "translate",
    "targetPrefix": "AWSShineFrontendService_20170701",
    "uid": "translate-2017-07-01"
  },
  "operations": {
    "CreateParallelData": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "ParallelDataConfig",
          "ClientToken"
        ],
        "members": {
          "Name": {},
          "Description": {},
          "ParallelDataConfig": {
            "shape": "S4"
          },
          "EncryptionKey": {
            "shape": "S7"
          },
          "ClientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Name": {},
          "Status": {}
        }
      }
    },
    "DeleteParallelData": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Name": {},
          "Status": {}
        }
      }
    },
    "DeleteTerminology": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      }
    },
    "DescribeTextTranslationJob": {
      "input": {
        "type": "structure",
        "required": [
          "JobId"
        ],
        "members": {
          "JobId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TextTranslationJobProperties": {
            "shape": "Sj"
          }
        }
      }
    },
    "GetParallelData": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ParallelDataProperties": {
            "shape": "S12"
          },
          "DataLocation": {
            "shape": "S16"
          },
          "AuxiliaryDataLocation": {
            "shape": "S16"
          },
          "LatestUpdateAttemptAuxiliaryDataLocation": {
            "shape": "S16"
          }
        }
      }
    },
    "GetTerminology": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {},
          "TerminologyDataFormat": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TerminologyProperties": {
            "shape": "S1b"
          },
          "TerminologyDataLocation": {
            "shape": "S1e"
          },
          "AuxiliaryDataLocation": {
            "shape": "S1e"
          }
        }
      }
    },
    "ImportTerminology": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "MergeStrategy",
          "TerminologyData"
        ],
        "members": {
          "Name": {},
          "MergeStrategy": {},
          "Description": {},
          "TerminologyData": {
            "type": "structure",
            "required": [
              "File",
              "Format"
            ],
            "members": {
              "File": {
                "type": "blob",
                "sensitive": true
              },
              "Format": {},
              "Directionality": {}
            }
          },
          "EncryptionKey": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TerminologyProperties": {
            "shape": "S1b"
          },
          "AuxiliaryDataLocation": {
            "shape": "S1e"
          }
        }
      }
    },
    "ListParallelData": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ParallelDataPropertiesList": {
            "type": "list",
            "member": {
              "shape": "S12"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListTerminologies": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TerminologyPropertiesList": {
            "type": "list",
            "member": {
              "shape": "S1b"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListTextTranslationJobs": {
      "input": {
        "type": "structure",
        "members": {
          "Filter": {
            "type": "structure",
            "members": {
              "JobName": {},
              "JobStatus": {},
              "SubmittedBeforeTime": {
                "type": "timestamp"
              },
              "SubmittedAfterTime": {
                "type": "timestamp"
              }
            }
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TextTranslationJobPropertiesList": {
            "type": "list",
            "member": {
              "shape": "Sj"
            }
          },
          "NextToken": {}
        }
      }
    },
    "StartTextTranslationJob": {
      "input": {
        "type": "structure",
        "required": [
          "InputDataConfig",
          "OutputDataConfig",
          "DataAccessRoleArn",
          "SourceLanguageCode",
          "TargetLanguageCodes",
          "ClientToken"
        ],
        "members": {
          "JobName": {},
          "InputDataConfig": {
            "shape": "St"
          },
          "OutputDataConfig": {
            "shape": "Sv"
          },
          "DataAccessRoleArn": {},
          "SourceLanguageCode": {},
          "TargetLanguageCodes": {
            "shape": "Sp"
          },
          "TerminologyNames": {
            "shape": "Sq"
          },
          "ParallelDataNames": {
            "shape": "Sq"
          },
          "ClientToken": {
            "idempotencyToken": true
          },
          "Settings": {
            "shape": "Sx"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobId": {},
          "JobStatus": {}
        }
      }
    },
    "StopTextTranslationJob": {
      "input": {
        "type": "structure",
        "required": [
          "JobId"
        ],
        "members": {
          "JobId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobId": {},
          "JobStatus": {}
        }
      }
    },
    "TranslateText": {
      "input": {
        "type": "structure",
        "required": [
          "Text",
          "SourceLanguageCode",
          "TargetLanguageCode"
        ],
        "members": {
          "Text": {},
          "TerminologyNames": {
            "shape": "Sq"
          },
          "SourceLanguageCode": {},
          "TargetLanguageCode": {},
          "Settings": {
            "shape": "Sx"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "TranslatedText",
          "SourceLanguageCode",
          "TargetLanguageCode"
        ],
        "members": {
          "TranslatedText": {},
          "SourceLanguageCode": {},
          "TargetLanguageCode": {},
          "AppliedTerminologies": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {},
                "Terms": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "SourceText": {},
                      "TargetText": {}
                    }
                  }
                }
              }
            }
          },
          "AppliedSettings": {
            "shape": "Sx"
          }
        }
      }
    },
    "UpdateParallelData": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "ParallelDataConfig",
          "ClientToken"
        ],
        "members": {
          "Name": {},
          "Description": {},
          "ParallelDataConfig": {
            "shape": "S4"
          },
          "ClientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Name": {},
          "Status": {},
          "LatestUpdateAttemptStatus": {},
          "LatestUpdateAttemptAt": {
            "type": "timestamp"
          }
        }
      }
    }
  },
  "shapes": {
    "S4": {
      "type": "structure",
      "required": [
        "S3Uri",
        "Format"
      ],
      "members": {
        "S3Uri": {},
        "Format": {}
      }
    },
    "S7": {
      "type": "structure",
      "required": [
        "Type",
        "Id"
      ],
      "members": {
        "Type": {},
        "Id": {}
      }
    },
    "Sj": {
      "type": "structure",
      "members": {
        "JobId": {},
        "JobName": {},
        "JobStatus": {},
        "JobDetails": {
          "type": "structure",
          "members": {
            "TranslatedDocumentsCount": {
              "type": "integer"
            },
            "DocumentsWithErrorsCount": {
              "type": "integer"
            },
            "InputDocumentsCount": {
              "type": "integer"
            }
          }
        },
        "SourceLanguageCode": {},
        "TargetLanguageCodes": {
          "shape": "Sp"
        },
        "TerminologyNames": {
          "shape": "Sq"
        },
        "ParallelDataNames": {
          "shape": "Sq"
        },
        "Message": {},
        "SubmittedTime": {
          "type": "timestamp"
        },
        "EndTime": {
          "type": "timestamp"
        },
        "InputDataConfig": {
          "shape": "St"
        },
        "OutputDataConfig": {
          "shape": "Sv"
        },
        "DataAccessRoleArn": {},
        "Settings": {
          "shape": "Sx"
        }
      }
    },
    "Sp": {
      "type": "list",
      "member": {}
    },
    "Sq": {
      "type": "list",
      "member": {}
    },
    "St": {
      "type": "structure",
      "required": [
        "S3Uri",
        "ContentType"
      ],
      "members": {
        "S3Uri": {},
        "ContentType": {}
      }
    },
    "Sv": {
      "type": "structure",
      "required": [
        "S3Uri"
      ],
      "members": {
        "S3Uri": {},
        "EncryptionKey": {
          "shape": "S7"
        }
      }
    },
    "Sx": {
      "type": "structure",
      "members": {
        "Formality": {},
        "Profanity": {}
      }
    },
    "S12": {
      "type": "structure",
      "members": {
        "Name": {},
        "Arn": {},
        "Description": {},
        "Status": {},
        "SourceLanguageCode": {},
        "TargetLanguageCodes": {
          "shape": "S14"
        },
        "ParallelDataConfig": {
          "shape": "S4"
        },
        "Message": {},
        "ImportedDataSize": {
          "type": "long"
        },
        "ImportedRecordCount": {
          "type": "long"
        },
        "FailedRecordCount": {
          "type": "long"
        },
        "SkippedRecordCount": {
          "type": "long"
        },
        "EncryptionKey": {
          "shape": "S7"
        },
        "CreatedAt": {
          "type": "timestamp"
        },
        "LastUpdatedAt": {
          "type": "timestamp"
        },
        "LatestUpdateAttemptStatus": {},
        "LatestUpdateAttemptAt": {
          "type": "timestamp"
        }
      }
    },
    "S14": {
      "type": "list",
      "member": {}
    },
    "S16": {
      "type": "structure",
      "required": [
        "RepositoryType",
        "Location"
      ],
      "members": {
        "RepositoryType": {},
        "Location": {}
      }
    },
    "S1b": {
      "type": "structure",
      "members": {
        "Name": {},
        "Description": {},
        "Arn": {},
        "SourceLanguageCode": {},
        "TargetLanguageCodes": {
          "shape": "S14"
        },
        "EncryptionKey": {
          "shape": "S7"
        },
        "SizeBytes": {
          "type": "integer"
        },
        "TermCount": {
          "type": "integer"
        },
        "CreatedAt": {
          "type": "timestamp"
        },
        "LastUpdatedAt": {
          "type": "timestamp"
        },
        "Directionality": {},
        "Message": {},
        "SkippedTermCount": {
          "type": "integer"
        },
        "Format": {}
      }
    },
    "S1e": {
      "type": "structure",
      "required": [
        "RepositoryType",
        "Location"
      ],
      "members": {
        "RepositoryType": {},
        "Location": {}
      }
    }
  }
}