{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2010-05-15",
    "endpointPrefix": "cloudformation",
    "protocol": "query",
    "serviceFullName": "AWS CloudFormation",
    "serviceId": "CloudFormation",
    "signatureVersion": "v4",
    "uid": "cloudformation-2010-05-15",
    "xmlNamespace": "http://cloudformation.amazonaws.com/doc/2010-05-15/"
  },
  "operations": {
    "ActivateType": {
      "input": {
        "type": "structure",
        "members": {
          "Type": {},
          "PublicTypeArn": {},
          "PublisherId": {},
          "TypeName": {},
          "TypeNameAlias": {},
          "AutoUpdate": {
            "type": "boolean"
          },
          "LoggingConfig": {
            "shape": "S7"
          },
          "ExecutionRoleArn": {},
          "VersionBump": {},
          "MajorVersion": {
            "type": "long"
          }
        }
      },
      "output": {
        "resultWrapper": "ActivateTypeResult",
        "type": "structure",
        "members": {
          "Arn": {}
        }
      },
      "idempotent": true
    },
    "BatchDescribeTypeConfigurations": {
      "input": {
        "type": "structure",
        "required": [
          "TypeConfigurationIdentifiers"
        ],
        "members": {
          "TypeConfigurationIdentifiers": {
            "type": "list",
            "member": {
              "shape": "Sg"
            }
          }
        }
      },
      "output": {
        "resultWrapper": "BatchDescribeTypeConfigurationsResult",
        "type": "structure",
        "members": {
          "Errors": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ErrorCode": {},
                "ErrorMessage": {},
                "TypeConfigurationIdentifier": {
                  "shape": "Sg"
                }
              }
            }
          },
          "UnprocessedTypeConfigurations": {
            "type": "list",
            "member": {
              "shape": "Sg"
            }
          },
          "TypeConfigurations": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Arn": {},
                "Alias": {},
                "Configuration": {},
                "LastUpdated": {
                  "type": "timestamp"
                },
                "TypeArn": {},
                "TypeName": {},
                "IsDefaultConfiguration": {
                  "type": "boolean"
                }
              }
            }
          }
        }
      }
    },
    "CancelUpdateStack": {
      "input": {
        "type": "structure",
        "required": [
          "StackName"
        ],
        "members": {
          "StackName": {},
          "ClientRequestToken": {}
        }
      }
    },
    "ContinueUpdateRollback": {
      "input": {
        "type": "structure",
        "required": [
          "StackName"
        ],
        "members": {
          "StackName": {},
          "RoleARN": {},
          "ResourcesToSkip": {
            "type": "list",
            "member": {}
          },
          "ClientRequestToken": {}
        }
      },
      "output": {
        "resultWrapper": "ContinueUpdateRollbackResult",
        "type": "structure",
        "members": {}
      }
    },
    "CreateChangeSet": {
      "input": {
        "type": "structure",
        "required": [
          "StackName",
          "ChangeSetName"
        ],
        "members": {
          "StackName": {},
          "TemplateBody": {},
          "TemplateURL": {},
          "UsePreviousTemplate": {
            "type": "boolean"
          },
          "Parameters": {
            "shape": "S18"
          },
          "Capabilities": {
            "shape": "S1d"
          },
          "ResourceTypes": {
            "shape": "S1f"
          },
          "RoleARN": {},
          "RollbackConfiguration": {
            "shape": "S1h"
          },
          "NotificationARNs": {
            "shape": "S1n"
          },
          "Tags": {
            "shape": "S1p"
          },
          "ChangeSetName": {},
          "ClientToken": {},
          "Description": {},
          "ChangeSetType": {},
          "ResourcesToImport": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "ResourceType",
                "LogicalResourceId",
                "ResourceIdentifier"
              ],
              "members": {
                "ResourceType": {},
                "LogicalResourceId": {},
                "ResourceIdentifier": {
                  "type": "map",
                  "key": {},
                  "value": {}
                }
              }
            }
          },
          "IncludeNestedStacks": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateChangeSetResult",
        "type": "structure",
        "members": {
          "Id": {},
          "StackId": {}
        }
      }
    },
    "CreateStack": {
      "input": {
        "type": "structure",
        "required": [
          "StackName"
        ],
        "members": {
          "StackName": {},
          "TemplateBody": {},
          "TemplateURL": {},
          "Parameters": {
            "shape": "S18"
          },
          "DisableRollback": {
            "type": "boolean"
          },
          "RollbackConfiguration": {
            "shape": "S1h"
          },
          "TimeoutInMinutes": {
            "type": "integer"
          },
          "NotificationARNs": {
            "shape": "S1n"
          },
          "Capabilities": {
            "shape": "S1d"
          },
          "ResourceTypes": {
            "shape": "S1f"
          },
          "RoleARN": {},
          "OnFailure": {},
          "StackPolicyBody": {},
          "StackPolicyURL": {},
          "Tags": {
            "shape": "S1p"
          },
          "ClientRequestToken": {},
          "EnableTerminationProtection": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateStackResult",
        "type": "structure",
        "members": {
          "StackId": {}
        }
      }
    },
    "CreateStackInstances": {
      "input": {
        "type": "structure",
        "required": [
          "StackSetName",
          "Regions"
        ],
        "members": {
          "StackSetName": {},
          "Accounts": {
            "shape": "S2h"
          },
          "DeploymentTargets": {
            "shape": "S2j"
          },
          "Regions": {
            "shape": "S2n"
          },
          "ParameterOverrides": {
            "shape": "S18"
          },
          "OperationPreferences": {
            "shape": "S2p"
          },
          "OperationId": {
            "idempotencyToken": true
          },
          "CallAs": {}
        }
      },
      "output": {
        "resultWrapper": "CreateStackInstancesResult",
        "type": "structure",
        "members": {
          "OperationId": {}
        }
      }
    },
    "CreateStackSet": {
      "input": {
        "type": "structure",
        "required": [
          "StackSetName"
        ],
        "members": {
          "StackSetName": {},
          "Description": {},
          "TemplateBody": {},
          "TemplateURL": {},
          "StackId": {},
          "Parameters": {
            "shape": "S18"
          },
          "Capabilities": {
            "shape": "S1d"
          },
          "Tags": {
            "shape": "S1p"
          },
          "AdministrationRoleARN": {},
          "ExecutionRoleName": {},
          "PermissionModel": {},
          "AutoDeployment": {
            "shape": "S30"
          },
          "CallAs": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "ManagedExecution": {
            "shape": "S33"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateStackSetResult",
        "type": "structure",
        "members": {
          "StackSetId": {}
        }
      }
    },
    "DeactivateType": {
      "input": {
        "type": "structure",
        "members": {
          "TypeName": {},
          "Type": {},
          "Arn": {}
        }
      },
      "output": {
        "resultWrapper": "DeactivateTypeResult",
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DeleteChangeSet": {
      "input": {
        "type": "structure",
        "required": [
          "ChangeSetName"
        ],
        "members": {
          "ChangeSetName": {},
          "StackName": {}
        }
      },
      "output": {
        "resultWrapper": "DeleteChangeSetResult",
        "type": "structure",
        "members": {}
      }
    },
    "DeleteStack": {
      "input": {
        "type": "structure",
        "required": [
          "StackName"
        ],
        "members": {
          "StackName": {},
          "RetainResources": {
            "type": "list",
            "member": {}
          },
          "RoleARN": {},
          "ClientRequestToken": {}
        }
      }
    },
    "DeleteStackInstances": {
      "input": {
        "type": "structure",
        "required": [
          "StackSetName",
          "Regions",
          "RetainStacks"
        ],
        "members": {
          "StackSetName": {},
          "Accounts": {
            "shape": "S2h"
          },
          "DeploymentTargets": {
            "shape": "S2j"
          },
          "Regions": {
            "shape": "S2n"
          },
          "OperationPreferences": {
            "shape": "S2p"
          },
          "RetainStacks": {
            "type": "boolean"
          },
          "OperationId": {
            "idempotencyToken": true
          },
          "CallAs": {}
        }
      },
      "output": {
        "resultWrapper": "DeleteStackInstancesResult",
        "type": "structure",
        "members": {
          "OperationId": {}
        }
      }
    },
    "DeleteStackSet": {
      "input": {
        "type": "structure",
        "required": [
          "StackSetName"
        ],
        "members": {
          "StackSetName": {},
          "CallAs": {}
        }
      },
      "output": {
        "resultWrapper": "DeleteStackSetResult",
        "type": "structure",
        "members": {}
      }
    },
    "DeregisterType": {
      "input": {
        "type": "structure",
        "members": {
          "Arn": {},
          "Type": {},
          "TypeName": {},
          "VersionId": {}
        }
      },
      "output": {
        "resultWrapper": "DeregisterTypeResult",
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DescribeAccountLimits": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeAccountLimitsResult",
        "type": "structure",
        "members": {
          "AccountLimits": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {},
                "Value": {
                  "type": "integer"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeChangeSet": {
      "input": {
        "type": "structure",
        "required": [
          "ChangeSetName"
        ],
        "members": {
          "ChangeSetName": {},
          "StackName": {},
          "NextToken": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeChangeSetResult",
        "type": "structure",
        "members": {
          "ChangeSetName": {},
          "ChangeSetId": {},
          "StackId": {},
          "StackName": {},
          "Description": {},
          "Parameters": {
            "shape": "S18"
          },
          "CreationTime": {
            "type": "timestamp"
          },
          "ExecutionStatus": {},
          "Status": {},
          "StatusReason": {},
          "NotificationARNs": {
            "shape": "S1n"
          },
          "RollbackConfiguration": {
            "shape": "S1h"
          },
          "Capabilities": {
            "shape": "S1d"
          },
          "Tags": {
            "shape": "S1p"
          },
          "Changes": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Type": {},
                "HookInvocationCount": {
                  "type": "integer"
                },
                "ResourceChange": {
                  "type": "structure",
                  "members": {
                    "Action": {},
                    "LogicalResourceId": {},
                    "PhysicalResourceId": {},
                    "ResourceType": {},
                    "Replacement": {},
                    "Scope": {
                      "type": "list",
                      "member": {}
                    },
                    "Details": {
                      "type": "list",
                      "member": {
                        "type": "structure",
                        "members": {
                          "Target": {
                            "type": "structure",
                            "members": {
                              "Attribute": {},
                              "Name": {},
                              "RequiresRecreation": {}
                            }
                          },
                          "Evaluation": {},
                          "ChangeSource": {},
                          "CausingEntity": {}
                        }
                      }
                    },
                    "ChangeSetId": {},
                    "ModuleInfo": {
                      "shape": "S4i"
                    }
                  }
                }
              }
            }
          },
          "NextToken": {},
          "IncludeNestedStacks": {
            "type": "boolean"
          },
          "ParentChangeSetId": {},
          "RootChangeSetId": {}
        }
      }
    },
    "DescribeChangeSetHooks": {
      "input": {
        "type": "structure",
        "required": [
          "ChangeSetName"
        ],
        "members": {
          "ChangeSetName": {},
          "StackName": {},
          "NextToken": {},
          "LogicalResourceId": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeChangeSetHooksResult",
        "type": "structure",
        "members": {
          "ChangeSetId": {},
          "ChangeSetName": {},
          "Hooks": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "InvocationPoint": {},
                "FailureMode": {},
                "TypeName": {},
                "TypeVersionId": {},
                "TypeConfigurationVersionId": {},
                "TargetDetails": {
                  "type": "structure",
                  "members": {
                    "TargetType": {},
                    "ResourceTargetDetails": {
                      "type": "structure",
                      "members": {
                        "LogicalResourceId": {},
                        "ResourceType": {},
                        "ResourceAction": {}
                      }
                    }
                  }
                }
              }
            }
          },
          "Status": {},
          "NextToken": {},
          "StackId": {},
          "StackName": {}
        }
      }
    },
    "DescribePublisher": {
      "input": {
        "type": "structure",
        "members": {
          "PublisherId": {}
        }
      },
      "output": {
        "resultWrapper": "DescribePublisherResult",
        "type": "structure",
        "members": {
          "PublisherId": {},
          "PublisherStatus": {},
          "IdentityProvider": {},
          "PublisherProfile": {}
        }
      },
      "idempotent": true
    },
    "DescribeStackDriftDetectionStatus": {
      "input": {
        "type": "structure",
        "required": [
          "StackDriftDetectionId"
        ],
        "members": {
          "StackDriftDetectionId": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeStackDriftDetectionStatusResult",
        "type": "structure",
        "required": [
          "StackId",
          "StackDriftDetectionId",
          "DetectionStatus",
          "Timestamp"
        ],
        "members": {
          "StackId": {},
          "StackDriftDetectionId": {},
          "StackDriftStatus": {},
          "DetectionStatus": {},
          "DetectionStatusReason": {},
          "DriftedStackResourceCount": {
            "type": "integer"
          },
          "Timestamp": {
            "type": "timestamp"
          }
        }
      }
    },
    "DescribeStackEvents": {
      "input": {
        "type": "structure",
        "members": {
          "StackName": {},
          "NextToken": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeStackEventsResult",
        "type": "structure",
        "members": {
          "StackEvents": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "StackId",
                "EventId",
                "StackName",
                "Timestamp"
              ],
              "members": {
                "StackId": {},
                "EventId": {},
                "StackName": {},
                "LogicalResourceId": {},
                "PhysicalResourceId": {},
                "ResourceType": {},
                "Timestamp": {
                  "type": "timestamp"
                },
                "ResourceStatus": {},
                "ResourceStatusReason": {},
                "ResourceProperties": {},
                "ClientRequestToken": {},
                "HookType": {},
                "HookStatus": {},
                "HookStatusReason": {},
                "HookInvocationPoint": {},
                "HookFailureMode": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeStackInstance": {
      "input": {
        "type": "structure",
        "required": [
          "StackSetName",
          "StackInstanceAccount",
          "StackInstanceRegion"
        ],
        "members": {
          "StackSetName": {},
          "StackInstanceAccount": {},
          "StackInstanceRegion": {},
          "CallAs": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeStackInstanceResult",
        "type": "structure",
        "members": {
          "StackInstance": {
            "type": "structure",
            "members": {
              "StackSetId": {},
              "Region": {},
              "Account": {},
              "StackId": {},
              "ParameterOverrides": {
                "shape": "S18"
              },
              "Status": {},
              "StackInstanceStatus": {
                "shape": "S5q"
              },
              "StatusReason": {},
              "OrganizationalUnitId": {},
              "DriftStatus": {},
              "LastDriftCheckTimestamp": {
                "type": "timestamp"
              }
            }
          }
        }
      }
    },
    "DescribeStackResource": {
      "input": {
        "type": "structure",
        "required": [
          "StackName",
          "LogicalResourceId"
        ],
        "members": {
          "StackName": {},
          "LogicalResourceId": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeStackResourceResult",
        "type": "structure",
        "members": {
          "StackResourceDetail": {
            "type": "structure",
            "required": [
              "LogicalResourceId",
              "ResourceType",
              "LastUpdatedTimestamp",
              "ResourceStatus"
            ],
            "members": {
              "StackName": {},
              "StackId": {},
              "LogicalResourceId": {},
              "PhysicalResourceId": {},
              "ResourceType": {},
              "LastUpdatedTimestamp": {
                "type": "timestamp"
              },
              "ResourceStatus": {},
              "ResourceStatusReason": {},
              "Description": {},
              "Metadata": {},
              "DriftInformation": {
                "shape": "S5x"
              },
              "ModuleInfo": {
                "shape": "S4i"
              }
            }
          }
        }
      }
    },
    "DescribeStackResourceDrifts": {
      "input": {
        "type": "structure",
        "required": [
          "StackName"
        ],
        "members": {
          "StackName": {},
          "StackResourceDriftStatusFilters": {
            "type": "list",
            "member": {}
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeStackResourceDriftsResult",
        "type": "structure",
        "required": [
          "StackResourceDrifts"
        ],
        "members": {
          "StackResourceDrifts": {
            "type": "list",
            "member": {
              "shape": "S64"
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeStackResources": {
      "input": {
        "type": "structure",
        "members": {
          "StackName": {},
          "LogicalResourceId": {},
          "PhysicalResourceId": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeStackResourcesResult",
        "type": "structure",
        "members": {
          "StackResources": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "LogicalResourceId",
                "ResourceType",
                "Timestamp",
                "ResourceStatus"
              ],
              "members": {
                "StackName": {},
                "StackId": {},
                "LogicalResourceId": {},
                "PhysicalResourceId": {},
                "ResourceType": {},
                "Timestamp": {
                  "type": "timestamp"
                },
                "ResourceStatus": {},
                "ResourceStatusReason": {},
                "Description": {},
                "DriftInformation": {
                  "shape": "S5x"
                },
                "ModuleInfo": {
                  "shape": "S4i"
                }
              }
            }
          }
        }
      }
    },
    "DescribeStackSet": {
      "input": {
        "type": "structure",
        "required": [
          "StackSetName"
        ],
        "members": {
          "StackSetName": {},
          "CallAs": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeStackSetResult",
        "type": "structure",
        "members": {
          "StackSet": {
            "type": "structure",
            "members": {
              "StackSetName": {},
              "StackSetId": {},
              "Description": {},
              "Status": {},
              "TemplateBody": {},
              "Parameters": {
                "shape": "S18"
              },
              "Capabilities": {
                "shape": "S1d"
              },
              "Tags": {
                "shape": "S1p"
              },
              "StackSetARN": {},
              "AdministrationRoleARN": {},
              "ExecutionRoleName": {},
              "StackSetDriftDetectionDetails": {
                "shape": "S6o"
              },
              "AutoDeployment": {
                "shape": "S30"
              },
              "PermissionModel": {},
              "OrganizationalUnitIds": {
                "shape": "S2l"
              },
              "ManagedExecution": {
                "shape": "S33"
              }
            }
          }
        }
      }
    },
    "DescribeStackSetOperation": {
      "input": {
        "type": "structure",
        "required": [
          "StackSetName",
          "OperationId"
        ],
        "members": {
          "StackSetName": {},
          "OperationId": {},
          "CallAs": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeStackSetOperationResult",
        "type": "structure",
        "members": {
          "StackSetOperation": {
            "type": "structure",
            "members": {
              "OperationId": {},
              "StackSetId": {},
              "Action": {},
              "Status": {},
              "OperationPreferences": {
                "shape": "S2p"
              },
              "RetainStacks": {
                "type": "boolean"
              },
              "AdministrationRoleARN": {},
              "ExecutionRoleName": {},
              "CreationTimestamp": {
                "type": "timestamp"
              },
              "EndTimestamp": {
                "type": "timestamp"
              },
              "DeploymentTargets": {
                "shape": "S2j"
              },
              "StackSetDriftDetectionDetails": {
                "shape": "S6o"
              }
            }
          }
        }
      }
    },
    "DescribeStacks": {
      "input": {
        "type": "structure",
        "members": {
          "StackName": {},
          "NextToken": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeStacksResult",
        "type": "structure",
        "members": {
          "Stacks": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "StackName",
                "CreationTime",
                "StackStatus"
              ],
              "members": {
                "StackId": {},
                "StackName": {},
                "ChangeSetId": {},
                "Description": {},
                "Parameters": {
                  "shape": "S18"
                },
                "CreationTime": {
                  "type": "timestamp"
                },
                "DeletionTime": {
                  "type": "timestamp"
                },
                "LastUpdatedTime": {
                  "type": "timestamp"
                },
                "RollbackConfiguration": {
                  "shape": "S1h"
                },
                "StackStatus": {},
                "StackStatusReason": {},
                "DisableRollback": {
                  "type": "boolean"
                },
                "NotificationARNs": {
                  "shape": "S1n"
                },
                "TimeoutInMinutes": {
                  "type": "integer"
                },
                "Capabilities": {
                  "shape": "S1d"
                },
                "Outputs": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "OutputKey": {},
                      "OutputValue": {},
                      "Description": {},
                      "ExportName": {}
                    }
                  }
                },
                "RoleARN": {},
                "Tags": {
                  "shape": "S1p"
                },
                "EnableTerminationProtection": {
                  "type": "boolean"
                },
                "ParentId": {},
                "RootId": {},
                "DriftInformation": {
                  "type": "structure",
                  "required": [
                    "StackDriftStatus"
                  ],
                  "members": {
                    "StackDriftStatus": {},
                    "LastCheckTimestamp": {
                      "type": "timestamp"
                    }
                  }
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeType": {
      "input": {
        "type": "structure",
        "members": {
          "Type": {},
          "TypeName": {},
          "Arn": {},
          "VersionId": {},
          "PublisherId": {},
          "PublicVersionNumber": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeTypeResult",
        "type": "structure",
        "members": {
          "Arn": {},
          "Type": {},
          "TypeName": {},
          "DefaultVersionId": {},
          "IsDefaultVersion": {
            "type": "boolean"
          },
          "TypeTestsStatus": {},
          "TypeTestsStatusDescription": {},
          "Description": {},
          "Schema": {},
          "ProvisioningType": {},
          "DeprecatedStatus": {},
          "LoggingConfig": {
            "shape": "S7"
          },
          "RequiredActivatedTypes": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "TypeNameAlias": {},
                "OriginalTypeName": {},
                "PublisherId": {},
                "SupportedMajorVersions": {
                  "type": "list",
                  "member": {
                    "type": "integer"
                  }
                }
              }
            }
          },
          "ExecutionRoleArn": {},
          "Visibility": {},
          "SourceUrl": {},
          "DocumentationUrl": {},
          "LastUpdated": {
            "type": "timestamp"
          },
          "TimeCreated": {
            "type": "timestamp"
          },
          "ConfigurationSchema": {},
          "PublisherId": {},
          "OriginalTypeName": {},
          "OriginalTypeArn": {},
          "PublicVersionNumber": {},
          "LatestPublicVersion": {},
          "IsActivated": {
            "type": "boolean"
          },
          "AutoUpdate": {
            "type": "boolean"
          }
        }
      },
      "idempotent": true
    },
    "DescribeTypeRegistration": {
      "input": {
        "type": "structure",
        "required": [
          "RegistrationToken"
        ],
        "members": {
          "RegistrationToken": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeTypeRegistrationResult",
        "type": "structure",
        "members": {
          "ProgressStatus": {},
          "Description": {},
          "TypeArn": {},
          "TypeVersionArn": {}
        }
      },
      "idempotent": true
    },
    "DetectStackDrift": {
      "input": {
        "type": "structure",
        "required": [
          "StackName"
        ],
        "members": {
          "StackName": {},
          "LogicalResourceIds": {
            "shape": "S82"
          }
        }
      },
      "output": {
        "resultWrapper": "DetectStackDriftResult",
        "type": "structure",
        "required": [
          "StackDriftDetectionId"
        ],
        "members": {
          "StackDriftDetectionId": {}
        }
      }
    },
    "DetectStackResourceDrift": {
      "input": {
        "type": "structure",
        "required": [
          "StackName",
          "LogicalResourceId"
        ],
        "members": {
          "StackName": {},
          "LogicalResourceId": {}
        }
      },
      "output": {
        "resultWrapper": "DetectStackResourceDriftResult",
        "type": "structure",
        "required": [
          "StackResourceDrift"
        ],
        "members": {
          "StackResourceDrift": {
            "shape": "S64"
          }
        }
      }
    },
    "DetectStackSetDrift": {
      "input": {
        "type": "structure",
        "required": [
          "StackSetName"
        ],
        "members": {
          "StackSetName": {},
          "OperationPreferences": {
            "shape": "S2p"
          },
          "OperationId": {
            "idempotencyToken": true
          },
          "CallAs": {}
        }
      },
      "output": {
        "resultWrapper": "DetectStackSetDriftResult",
        "type": "structure",
        "members": {
          "OperationId": {}
        }
      }
    },
    "EstimateTemplateCost": {
      "input": {
        "type": "structure",
        "members": {
          "TemplateBody": {},
          "TemplateURL": {},
          "Parameters": {
            "shape": "S18"
          }
        }
      },
      "output": {
        "resultWrapper": "EstimateTemplateCostResult",
        "type": "structure",
        "members": {
          "Url": {}
        }
      }
    },
    "ExecuteChangeSet": {
      "input": {
        "type": "structure",
        "required": [
          "ChangeSetName"
        ],
        "members": {
          "ChangeSetName": {},
          "StackName": {},
          "ClientRequestToken": {},
          "DisableRollback": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "ExecuteChangeSetResult",
        "type": "structure",
        "members": {}
      }
    },
    "GetStackPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "StackName"
        ],
        "members": {
          "StackName": {}
        }
      },
      "output": {
        "resultWrapper": "GetStackPolicyResult",
        "type": "structure",
        "members": {
          "StackPolicyBody": {}
        }
      }
    },
    "GetTemplate": {
      "input": {
        "type": "structure",
        "members": {
          "StackName": {},
          "ChangeSetName": {},
          "TemplateStage": {}
        }
      },
      "output": {
        "resultWrapper": "GetTemplateResult",
        "type": "structure",
        "members": {
          "TemplateBody": {},
          "StagesAvailable": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "GetTemplateSummary": {
      "input": {
        "type": "structure",
        "members": {
          "TemplateBody": {},
          "TemplateURL": {},
          "StackName": {},
          "StackSetName": {},
          "CallAs": {}
        }
      },
      "output": {
        "resultWrapper": "GetTemplateSummaryResult",
        "type": "structure",
        "members": {
          "Parameters": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ParameterKey": {},
                "DefaultValue": {},
                "ParameterType": {},
                "NoEcho": {
                  "type": "boolean"
                },
                "Description": {},
                "ParameterConstraints": {
                  "type": "structure",
                  "members": {
                    "AllowedValues": {
                      "type": "list",
                      "member": {}
                    }
                  }
                }
              }
            }
          },
          "Description": {},
          "Capabilities": {
            "shape": "S1d"
          },
          "CapabilitiesReason": {},
          "ResourceTypes": {
            "shape": "S1f"
          },
          "Version": {},
          "Metadata": {},
          "DeclaredTransforms": {
            "shape": "S8v"
          },
          "ResourceIdentifierSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ResourceType": {},
                "LogicalResourceIds": {
                  "shape": "S82"
                },
                "ResourceIdentifiers": {
                  "type": "list",
                  "member": {}
                }
              }
            }
          }
        }
      }
    },
    "ImportStacksToStackSet": {
      "input": {
        "type": "structure",
        "required": [
          "StackSetName"
        ],
        "members": {
          "StackSetName": {},
          "StackIds": {
            "type": "list",
            "member": {}
          },
          "StackIdsUrl": {},
          "OrganizationalUnitIds": {
            "shape": "S2l"
          },
          "OperationPreferences": {
            "shape": "S2p"
          },
          "OperationId": {
            "idempotencyToken": true
          },
          "CallAs": {}
        }
      },
      "output": {
        "resultWrapper": "ImportStacksToStackSetResult",
        "type": "structure",
        "members": {
          "OperationId": {}
        }
      }
    },
    "ListChangeSets": {
      "input": {
        "type": "structure",
        "required": [
          "StackName"
        ],
        "members": {
          "StackName": {},
          "NextToken": {}
        }
      },
      "output": {
        "resultWrapper": "ListChangeSetsResult",
        "type": "structure",
        "members": {
          "Summaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "StackId": {},
                "StackName": {},
                "ChangeSetId": {},
                "ChangeSetName": {},
                "ExecutionStatus": {},
                "Status": {},
                "StatusReason": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "Description": {},
                "IncludeNestedStacks": {
                  "type": "boolean"
                },
                "ParentChangeSetId": {},
                "RootChangeSetId": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListExports": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {}
        }
      },
      "output": {
        "resultWrapper": "ListExportsResult",
        "type": "structure",
        "members": {
          "Exports": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ExportingStackId": {},
                "Name": {},
                "Value": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListImports": {
      "input": {
        "type": "structure",
        "required": [
          "ExportName"
        ],
        "members": {
          "ExportName": {},
          "NextToken": {}
        }
      },
      "output": {
        "resultWrapper": "ListImportsResult",
        "type": "structure",
        "members": {
          "Imports": {
            "type": "list",
            "member": {}
          },
          "NextToken": {}
        }
      }
    },
    "ListStackInstances": {
      "input": {
        "type": "structure",
        "required": [
          "StackSetName"
        ],
        "members": {
          "StackSetName": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "Filters": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {},
                "Values": {}
              }
            }
          },
          "StackInstanceAccount": {},
          "StackInstanceRegion": {},
          "CallAs": {}
        }
      },
      "output": {
        "resultWrapper": "ListStackInstancesResult",
        "type": "structure",
        "members": {
          "Summaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "StackSetId": {},
                "Region": {},
                "Account": {},
                "StackId": {},
                "Status": {},
                "StatusReason": {},
                "StackInstanceStatus": {
                  "shape": "S5q"
                },
                "OrganizationalUnitId": {},
                "DriftStatus": {},
                "LastDriftCheckTimestamp": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListStackResources": {
      "input": {
        "type": "structure",
        "required": [
          "StackName"
        ],
        "members": {
          "StackName": {},
          "NextToken": {}
        }
      },
      "output": {
        "resultWrapper": "ListStackResourcesResult",
        "type": "structure",
        "members": {
          "StackResourceSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "LogicalResourceId",
                "ResourceType",
                "LastUpdatedTimestamp",
                "ResourceStatus"
              ],
              "members": {
                "LogicalResourceId": {},
                "PhysicalResourceId": {},
                "ResourceType": {},
                "LastUpdatedTimestamp": {
                  "type": "timestamp"
                },
                "ResourceStatus": {},
                "ResourceStatusReason": {},
                "DriftInformation": {
                  "type": "structure",
                  "required": [
                    "StackResourceDriftStatus"
                  ],
                  "members": {
                    "StackResourceDriftStatus": {},
                    "LastCheckTimestamp": {
                      "type": "timestamp"
                    }
                  }
                },
                "ModuleInfo": {
                  "shape": "S4i"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListStackSetOperationResults": {
      "input": {
        "type": "structure",
        "required": [
          "StackSetName",
          "OperationId"
        ],
        "members": {
          "StackSetName": {},
          "OperationId": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "CallAs": {}
        }
      },
      "output": {
        "resultWrapper": "ListStackSetOperationResultsResult",
        "type": "structure",
        "members": {
          "Summaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Account": {},
                "Region": {},
                "Status": {},
                "StatusReason": {},
                "AccountGateResult": {
                  "type": "structure",
                  "members": {
                    "Status": {},
                    "StatusReason": {}
                  }
                },
                "OrganizationalUnitId": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListStackSetOperations": {
      "input": {
        "type": "structure",
        "required": [
          "StackSetName"
        ],
        "members": {
          "StackSetName": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "CallAs": {}
        }
      },
      "output": {
        "resultWrapper": "ListStackSetOperationsResult",
        "type": "structure",
        "members": {
          "Summaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "OperationId": {},
                "Action": {},
                "Status": {},
                "CreationTimestamp": {
                  "type": "timestamp"
                },
                "EndTimestamp": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListStackSets": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "Status": {},
          "CallAs": {}
        }
      },
      "output": {
        "resultWrapper": "ListStackSetsResult",
        "type": "structure",
        "members": {
          "Summaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "StackSetName": {},
                "StackSetId": {},
                "Description": {},
                "Status": {},
                "AutoDeployment": {
                  "shape": "S30"
                },
                "PermissionModel": {},
                "DriftStatus": {},
                "LastDriftCheckTimestamp": {
                  "type": "timestamp"
                },
                "ManagedExecution": {
                  "shape": "S33"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListStacks": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "StackStatusFilter": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "resultWrapper": "ListStacksResult",
        "type": "structure",
        "members": {
          "StackSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "StackName",
                "CreationTime",
                "StackStatus"
              ],
              "members": {
                "StackId": {},
                "StackName": {},
                "TemplateDescription": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "LastUpdatedTime": {
                  "type": "timestamp"
                },
                "DeletionTime": {
                  "type": "timestamp"
                },
                "StackStatus": {},
                "StackStatusReason": {},
                "ParentId": {},
                "RootId": {},
                "DriftInformation": {
                  "type": "structure",
                  "required": [
                    "StackDriftStatus"
                  ],
                  "members": {
                    "StackDriftStatus": {},
                    "LastCheckTimestamp": {
                      "type": "timestamp"
                    }
                  }
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListTypeRegistrations": {
      "input": {
        "type": "structure",
        "members": {
          "Type": {},
          "TypeName": {},
          "TypeArn": {},
          "RegistrationStatusFilter": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "resultWrapper": "ListTypeRegistrationsResult",
        "type": "structure",
        "members": {
          "RegistrationTokenList": {
            "type": "list",
            "member": {}
          },
          "NextToken": {}
        }
      },
      "idempotent": true
    },
    "ListTypeVersions": {
      "input": {
        "type": "structure",
        "members": {
          "Type": {},
          "TypeName": {},
          "Arn": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "DeprecatedStatus": {},
          "PublisherId": {}
        }
      },
      "output": {
        "resultWrapper": "ListTypeVersionsResult",
        "type": "structure",
        "members": {
          "TypeVersionSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Type": {},
                "TypeName": {},
                "VersionId": {},
                "IsDefaultVersion": {
                  "type": "boolean"
                },
                "Arn": {},
                "TimeCreated": {
                  "type": "timestamp"
                },
                "Description": {},
                "PublicVersionNumber": {}
              }
            }
          },
          "NextToken": {}
        }
      },
      "idempotent": true
    },
    "ListTypes": {
      "input": {
        "type": "structure",
        "members": {
          "Visibility": {},
          "ProvisioningType": {},
          "DeprecatedStatus": {},
          "Type": {},
          "Filters": {
            "type": "structure",
            "members": {
              "Category": {},
              "PublisherId": {},
              "TypeNamePrefix": {}
            }
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "resultWrapper": "ListTypesResult",
        "type": "structure",
        "members": {
          "TypeSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Type": {},
                "TypeName": {},
                "DefaultVersionId": {},
                "TypeArn": {},
                "LastUpdated": {
                  "type": "timestamp"
                },
                "Description": {},
                "PublisherId": {},
                "OriginalTypeName": {},
                "PublicVersionNumber": {},
                "LatestPublicVersion": {},
                "PublisherIdentity": {},
                "PublisherName": {},
                "IsActivated": {
                  "type": "boolean"
                }
              }
            }
          },
          "NextToken": {}
        }
      },
      "idempotent": true
    },
    "PublishType": {
      "input": {
        "type": "structure",
        "members": {
          "Type": {},
          "Arn": {},
          "TypeName": {},
          "PublicVersionNumber": {}
        }
      },
      "output": {
        "resultWrapper": "PublishTypeResult",
        "type": "structure",
        "members": {
          "PublicTypeArn": {}
        }
      },
      "idempotent": true
    },
    "RecordHandlerProgress": {
      "input": {
        "type": "structure",
        "required": [
          "BearerToken",
          "OperationStatus"
        ],
        "members": {
          "BearerToken": {},
          "OperationStatus": {},
          "CurrentOperationStatus": {},
          "StatusMessage": {},
          "ErrorCode": {},
          "ResourceModel": {},
          "ClientRequestToken": {}
        }
      },
      "output": {
        "resultWrapper": "RecordHandlerProgressResult",
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "RegisterPublisher": {
      "input": {
        "type": "structure",
        "members": {
          "AcceptTermsAndConditions": {
            "type": "boolean"
          },
          "ConnectionArn": {}
        }
      },
      "output": {
        "resultWrapper": "RegisterPublisherResult",
        "type": "structure",
        "members": {
          "PublisherId": {}
        }
      },
      "idempotent": true
    },
    "RegisterType": {
      "input": {
        "type": "structure",
        "required": [
          "TypeName",
          "SchemaHandlerPackage"
        ],
        "members": {
          "Type": {},
          "TypeName": {},
          "SchemaHandlerPackage": {},
          "LoggingConfig": {
            "shape": "S7"
          },
          "ExecutionRoleArn": {},
          "ClientRequestToken": {}
        }
      },
      "output": {
        "resultWrapper": "RegisterTypeResult",
        "type": "structure",
        "members": {
          "RegistrationToken": {}
        }
      },
      "idempotent": true
    },
    "RollbackStack": {
      "input": {
        "type": "structure",
        "required": [
          "StackName"
        ],
        "members": {
          "StackName": {},
          "RoleARN": {},
          "ClientRequestToken": {}
        }
      },
      "output": {
        "resultWrapper": "RollbackStackResult",
        "type": "structure",
        "members": {
          "StackId": {}
        }
      }
    },
    "SetStackPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "StackName"
        ],
        "members": {
          "StackName": {},
          "StackPolicyBody": {},
          "StackPolicyURL": {}
        }
      }
    },
    "SetTypeConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "Configuration"
        ],
        "members": {
          "TypeArn": {},
          "Configuration": {},
          "ConfigurationAlias": {},
          "TypeName": {},
          "Type": {}
        }
      },
      "output": {
        "resultWrapper": "SetTypeConfigurationResult",
        "type": "structure",
        "members": {
          "ConfigurationArn": {}
        }
      }
    },
    "SetTypeDefaultVersion": {
      "input": {
        "type": "structure",
        "members": {
          "Arn": {},
          "Type": {},
          "TypeName": {},
          "VersionId": {}
        }
      },
      "output": {
        "resultWrapper": "SetTypeDefaultVersionResult",
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "SignalResource": {
      "input": {
        "type": "structure",
        "required": [
          "StackName",
          "LogicalResourceId",
          "UniqueId",
          "Status"
        ],
        "members": {
          "StackName": {},
          "LogicalResourceId": {},
          "UniqueId": {},
          "Status": {}
        }
      }
    },
    "StopStackSetOperation": {
      "input": {
        "type": "structure",
        "required": [
          "StackSetName",
          "OperationId"
        ],
        "members": {
          "StackSetName": {},
          "OperationId": {},
          "CallAs": {}
        }
      },
      "output": {
        "resultWrapper": "StopStackSetOperationResult",
        "type": "structure",
        "members": {}
      }
    },
    "TestType": {
      "input": {
        "type": "structure",
        "members": {
          "Arn": {},
          "Type": {},
          "TypeName": {},
          "VersionId": {},
          "LogDeliveryBucket": {}
        }
      },
      "output": {
        "resultWrapper": "TestTypeResult",
        "type": "structure",
        "members": {
          "TypeVersionArn": {}
        }
      },
      "idempotent": true
    },
    "UpdateStack": {
      "input": {
        "type": "structure",
        "required": [
          "StackName"
        ],
        "members": {
          "StackName": {},
          "TemplateBody": {},
          "TemplateURL": {},
          "UsePreviousTemplate": {
            "type": "boolean"
          },
          "StackPolicyDuringUpdateBody": {},
          "StackPolicyDuringUpdateURL": {},
          "Parameters": {
            "shape": "S18"
          },
          "Capabilities": {
            "shape": "S1d"
          },
          "ResourceTypes": {
            "shape": "S1f"
          },
          "RoleARN": {},
          "RollbackConfiguration": {
            "shape": "S1h"
          },
          "StackPolicyBody": {},
          "StackPolicyURL": {},
          "NotificationARNs": {
            "shape": "S1n"
          },
          "Tags": {
            "shape": "S1p"
          },
          "DisableRollback": {
            "type": "boolean"
          },
          "ClientRequestToken": {}
        }
      },
      "output": {
        "resultWrapper": "UpdateStackResult",
        "type": "structure",
        "members": {
          "StackId": {}
        }
      }
    },
    "UpdateStackInstances": {
      "input": {
        "type": "structure",
        "required": [
          "StackSetName",
          "Regions"
        ],
        "members": {
          "StackSetName": {},
          "Accounts": {
            "shape": "S2h"
          },
          "DeploymentTargets": {
            "shape": "S2j"
          },
          "Regions": {
            "shape": "S2n"
          },
          "ParameterOverrides": {
            "shape": "S18"
          },
          "OperationPreferences": {
            "shape": "S2p"
          },
          "OperationId": {
            "idempotencyToken": true
          },
          "CallAs": {}
        }
      },
      "output": {
        "resultWrapper": "UpdateStackInstancesResult",
        "type": "structure",
        "members": {
          "OperationId": {}
        }
      }
    },
    "UpdateStackSet": {
      "input": {
        "type": "structure",
        "required": [
          "StackSetName"
        ],
        "members": {
          "StackSetName": {},
          "Description": {},
          "TemplateBody": {},
          "TemplateURL": {},
          "UsePreviousTemplate": {
            "type": "boolean"
          },
          "Parameters": {
            "shape": "S18"
          },
          "Capabilities": {
            "shape": "S1d"
          },
          "Tags": {
            "shape": "S1p"
          },
          "OperationPreferences": {
            "shape": "S2p"
          },
          "AdministrationRoleARN": {},
          "ExecutionRoleName": {},
          "DeploymentTargets": {
            "shape": "S2j"
          },
          "PermissionModel": {},
          "AutoDeployment": {
            "shape": "S30"
          },
          "OperationId": {
            "idempotencyToken": true
          },
          "Accounts": {
            "shape": "S2h"
          },
          "Regions": {
            "shape": "S2n"
          },
          "CallAs": {},
          "ManagedExecution": {
            "shape": "S33"
          }
        }
      },
      "output": {
        "resultWrapper": "UpdateStackSetResult",
        "type": "structure",
        "members": {
          "OperationId": {}
        }
      }
    },
    "UpdateTerminationProtection": {
      "input": {
        "type": "structure",
        "required": [
          "EnableTerminationProtection",
          "StackName"
        ],
        "members": {
          "EnableTerminationProtection": {
            "type": "boolean"
          },
          "StackName": {}
        }
      },
      "output": {
        "resultWrapper": "UpdateTerminationProtectionResult",
        "type": "structure",
        "members": {
          "StackId": {}
        }
      }
    },
    "ValidateTemplate": {
      "input": {
        "type": "structure",
        "members": {
          "TemplateBody": {},
          "TemplateURL": {}
        }
      },
      "output": {
        "resultWrapper": "ValidateTemplateResult",
        "type": "structure",
        "members": {
          "Parameters": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ParameterKey": {},
                "DefaultValue": {},
                "NoEcho": {
                  "type": "boolean"
                },
                "Description": {}
              }
            }
          },
          "Description": {},
          "Capabilities": {
            "shape": "S1d"
          },
          "CapabilitiesReason": {},
          "DeclaredTransforms": {
            "shape": "S8v"
          }
        }
      }
    }
  },
  "shapes": {
    "S7": {
      "type": "structure",
      "required": [
        "LogRoleArn",
        "LogGroupName"
      ],
      "members": {
        "LogRoleArn": {},
        "LogGroupName": {}
      }
    },
    "Sg": {
      "type": "structure",
      "members": {
        "TypeArn": {},
        "TypeConfigurationAlias": {},
        "TypeConfigurationArn": {},
        "Type": {},
        "TypeName": {}
      }
    },
    "S18": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "ParameterKey": {},
          "ParameterValue": {},
          "UsePreviousValue": {
            "type": "boolean"
          },
          "ResolvedValue": {}
        }
      }
    },
    "S1d": {
      "type": "list",
      "member": {}
    },
    "S1f": {
      "type": "list",
      "member": {}
    },
    "S1h": {
      "type": "structure",
      "members": {
        "RollbackTriggers": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "Arn",
              "Type"
            ],
            "members": {
              "Arn": {},
              "Type": {}
            }
          }
        },
        "MonitoringTimeInMinutes": {
          "type": "integer"
        }
      }
    },
    "S1n": {
      "type": "list",
      "member": {}
    },
    "S1p": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key",
          "Value"
        ],
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "S2h": {
      "type": "list",
      "member": {}
    },
    "S2j": {
      "type": "structure",
      "members": {
        "Accounts": {
          "shape": "S2h"
        },
        "AccountsUrl": {},
        "OrganizationalUnitIds": {
          "shape": "S2l"
        }
      }
    },
    "S2l": {
      "type": "list",
      "member": {}
    },
    "S2n": {
      "type": "list",
      "member": {}
    },
    "S2p": {
      "type": "structure",
      "members": {
        "RegionConcurrencyType": {},
        "RegionOrder": {
          "shape": "S2n"
        },
        "FailureToleranceCount": {
          "type": "integer"
        },
        "FailureTolerancePercentage": {
          "type": "integer"
        },
        "MaxConcurrentCount": {
          "type": "integer"
        },
        "MaxConcurrentPercentage": {
          "type": "integer"
        }
      }
    },
    "S30": {
      "type": "structure",
      "members": {
        "Enabled": {
          "type": "boolean"
        },
        "RetainStacksOnAccountRemoval": {
          "type": "boolean"
        }
      }
    },
    "S33": {
      "type": "structure",
      "members": {
        "Active": {
          "type": "boolean"
        }
      }
    },
    "S4i": {
      "type": "structure",
      "members": {
        "TypeHierarchy": {},
        "LogicalIdHierarchy": {}
      }
    },
    "S5q": {
      "type": "structure",
      "members": {
        "DetailedStatus": {}
      }
    },
    "S5x": {
      "type": "structure",
      "required": [
        "StackResourceDriftStatus"
      ],
      "members": {
        "StackResourceDriftStatus": {},
        "LastCheckTimestamp": {
          "type": "timestamp"
        }
      }
    },
    "S64": {
      "type": "structure",
      "required": [
        "StackId",
        "LogicalResourceId",
        "ResourceType",
        "StackResourceDriftStatus",
        "Timestamp"
      ],
      "members": {
        "StackId": {},
        "LogicalResourceId": {},
        "PhysicalResourceId": {},
        "PhysicalResourceIdContext": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "Key",
              "Value"
            ],
            "members": {
              "Key": {},
              "Value": {}
            }
          }
        },
        "ResourceType": {},
        "ExpectedProperties": {},
        "ActualProperties": {},
        "PropertyDifferences": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "PropertyPath",
              "ExpectedValue",
              "ActualValue",
              "DifferenceType"
            ],
            "members": {
              "PropertyPath": {},
              "ExpectedValue": {},
              "ActualValue": {},
              "DifferenceType": {}
            }
          }
        },
        "StackResourceDriftStatus": {},
        "Timestamp": {
          "type": "timestamp"
        },
        "ModuleInfo": {
          "shape": "S4i"
        }
      }
    },
    "S6o": {
      "type": "structure",
      "members": {
        "DriftStatus": {},
        "DriftDetectionStatus": {},
        "LastDriftCheckTimestamp": {
          "type": "timestamp"
        },
        "TotalStackInstancesCount": {
          "type": "integer"
        },
        "DriftedStackInstancesCount": {
          "type": "integer"
        },
        "InSyncStackInstancesCount": {
          "type": "integer"
        },
        "InProgressStackInstancesCount": {
          "type": "integer"
        },
        "FailedStackInstancesCount": {
          "type": "integer"
        }
      }
    },
    "S82": {
      "type": "list",
      "member": {}
    },
    "S8v": {
      "type": "list",
      "member": {}
    }
  }
}