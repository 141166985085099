{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2017-11-27",
    "endpointPrefix": "comprehend",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceFullName": "Amazon Comprehend",
    "serviceId": "Comprehend",
    "signatureVersion": "v4",
    "signingName": "comprehend",
    "targetPrefix": "Comprehend_20171127",
    "uid": "comprehend-2017-11-27"
  },
  "operations": {
    "BatchDetectDominantLanguage": {
      "input": {
        "type": "structure",
        "required": [
          "TextList"
        ],
        "members": {
          "TextList": {
            "shape": "S2"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ResultList",
          "ErrorList"
        ],
        "members": {
          "ResultList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Index": {
                  "type": "integer"
                },
                "Languages": {
                  "shape": "S8"
                }
              }
            }
          },
          "ErrorList": {
            "shape": "Sc"
          }
        },
        "sensitive": true
      }
    },
    "BatchDetectEntities": {
      "input": {
        "type": "structure",
        "required": [
          "TextList",
          "LanguageCode"
        ],
        "members": {
          "TextList": {
            "shape": "S2"
          },
          "LanguageCode": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ResultList",
          "ErrorList"
        ],
        "members": {
          "ResultList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Index": {
                  "type": "integer"
                },
                "Entities": {
                  "shape": "Sj"
                }
              }
            }
          },
          "ErrorList": {
            "shape": "Sc"
          }
        },
        "sensitive": true
      }
    },
    "BatchDetectKeyPhrases": {
      "input": {
        "type": "structure",
        "required": [
          "TextList",
          "LanguageCode"
        ],
        "members": {
          "TextList": {
            "shape": "S2"
          },
          "LanguageCode": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ResultList",
          "ErrorList"
        ],
        "members": {
          "ResultList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Index": {
                  "type": "integer"
                },
                "KeyPhrases": {
                  "shape": "Sq"
                }
              }
            }
          },
          "ErrorList": {
            "shape": "Sc"
          }
        },
        "sensitive": true
      }
    },
    "BatchDetectSentiment": {
      "input": {
        "type": "structure",
        "required": [
          "TextList",
          "LanguageCode"
        ],
        "members": {
          "TextList": {
            "shape": "S2"
          },
          "LanguageCode": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ResultList",
          "ErrorList"
        ],
        "members": {
          "ResultList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Index": {
                  "type": "integer"
                },
                "Sentiment": {},
                "SentimentScore": {
                  "shape": "Sx"
                }
              }
            }
          },
          "ErrorList": {
            "shape": "Sc"
          }
        },
        "sensitive": true
      }
    },
    "BatchDetectSyntax": {
      "input": {
        "type": "structure",
        "required": [
          "TextList",
          "LanguageCode"
        ],
        "members": {
          "TextList": {
            "shape": "S2"
          },
          "LanguageCode": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ResultList",
          "ErrorList"
        ],
        "members": {
          "ResultList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Index": {
                  "type": "integer"
                },
                "SyntaxTokens": {
                  "shape": "S13"
                }
              }
            }
          },
          "ErrorList": {
            "shape": "Sc"
          }
        },
        "sensitive": true
      }
    },
    "ClassifyDocument": {
      "input": {
        "type": "structure",
        "required": [
          "Text",
          "EndpointArn"
        ],
        "members": {
          "Text": {
            "shape": "S3"
          },
          "EndpointArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Classes": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {},
                "Score": {
                  "type": "float"
                }
              }
            }
          },
          "Labels": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {},
                "Score": {
                  "type": "float"
                }
              }
            }
          }
        },
        "sensitive": true
      }
    },
    "ContainsPiiEntities": {
      "input": {
        "type": "structure",
        "required": [
          "Text",
          "LanguageCode"
        ],
        "members": {
          "Text": {},
          "LanguageCode": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Labels": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {},
                "Score": {
                  "type": "float"
                }
              }
            }
          }
        }
      }
    },
    "CreateDocumentClassifier": {
      "input": {
        "type": "structure",
        "required": [
          "DocumentClassifierName",
          "DataAccessRoleArn",
          "InputDataConfig",
          "LanguageCode"
        ],
        "members": {
          "DocumentClassifierName": {},
          "VersionName": {},
          "DataAccessRoleArn": {},
          "Tags": {
            "shape": "S1n"
          },
          "InputDataConfig": {
            "shape": "S1r"
          },
          "OutputDataConfig": {
            "shape": "S21"
          },
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "LanguageCode": {},
          "VolumeKmsKeyId": {},
          "VpcConfig": {
            "shape": "S24"
          },
          "Mode": {},
          "ModelKmsKeyId": {},
          "ModelPolicy": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DocumentClassifierArn": {}
        }
      }
    },
    "CreateEndpoint": {
      "input": {
        "type": "structure",
        "required": [
          "EndpointName",
          "ModelArn",
          "DesiredInferenceUnits"
        ],
        "members": {
          "EndpointName": {},
          "ModelArn": {},
          "DesiredInferenceUnits": {
            "type": "integer"
          },
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "Tags": {
            "shape": "S1n"
          },
          "DataAccessRoleArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EndpointArn": {}
        }
      }
    },
    "CreateEntityRecognizer": {
      "input": {
        "type": "structure",
        "required": [
          "RecognizerName",
          "DataAccessRoleArn",
          "InputDataConfig",
          "LanguageCode"
        ],
        "members": {
          "RecognizerName": {},
          "VersionName": {},
          "DataAccessRoleArn": {},
          "Tags": {
            "shape": "S1n"
          },
          "InputDataConfig": {
            "shape": "S2k"
          },
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "LanguageCode": {},
          "VolumeKmsKeyId": {},
          "VpcConfig": {
            "shape": "S24"
          },
          "ModelKmsKeyId": {},
          "ModelPolicy": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EntityRecognizerArn": {}
        }
      }
    },
    "DeleteDocumentClassifier": {
      "input": {
        "type": "structure",
        "required": [
          "DocumentClassifierArn"
        ],
        "members": {
          "DocumentClassifierArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteEndpoint": {
      "input": {
        "type": "structure",
        "required": [
          "EndpointArn"
        ],
        "members": {
          "EndpointArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteEntityRecognizer": {
      "input": {
        "type": "structure",
        "required": [
          "EntityRecognizerArn"
        ],
        "members": {
          "EntityRecognizerArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteResourcePolicy": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "ResourceArn": {},
          "PolicyRevisionId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DescribeDocumentClassificationJob": {
      "input": {
        "type": "structure",
        "required": [
          "JobId"
        ],
        "members": {
          "JobId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DocumentClassificationJobProperties": {
            "shape": "S38"
          }
        }
      }
    },
    "DescribeDocumentClassifier": {
      "input": {
        "type": "structure",
        "required": [
          "DocumentClassifierArn"
        ],
        "members": {
          "DocumentClassifierArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DocumentClassifierProperties": {
            "shape": "S3n"
          }
        }
      }
    },
    "DescribeDominantLanguageDetectionJob": {
      "input": {
        "type": "structure",
        "required": [
          "JobId"
        ],
        "members": {
          "JobId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DominantLanguageDetectionJobProperties": {
            "shape": "S3u"
          }
        }
      }
    },
    "DescribeEndpoint": {
      "input": {
        "type": "structure",
        "required": [
          "EndpointArn"
        ],
        "members": {
          "EndpointArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EndpointProperties": {
            "shape": "S3x"
          }
        }
      }
    },
    "DescribeEntitiesDetectionJob": {
      "input": {
        "type": "structure",
        "required": [
          "JobId"
        ],
        "members": {
          "JobId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EntitiesDetectionJobProperties": {
            "shape": "S41"
          }
        }
      }
    },
    "DescribeEntityRecognizer": {
      "input": {
        "type": "structure",
        "required": [
          "EntityRecognizerArn"
        ],
        "members": {
          "EntityRecognizerArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EntityRecognizerProperties": {
            "shape": "S44"
          }
        }
      }
    },
    "DescribeEventsDetectionJob": {
      "input": {
        "type": "structure",
        "required": [
          "JobId"
        ],
        "members": {
          "JobId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EventsDetectionJobProperties": {
            "shape": "S4c"
          }
        }
      }
    },
    "DescribeKeyPhrasesDetectionJob": {
      "input": {
        "type": "structure",
        "required": [
          "JobId"
        ],
        "members": {
          "JobId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "KeyPhrasesDetectionJobProperties": {
            "shape": "S4h"
          }
        }
      }
    },
    "DescribePiiEntitiesDetectionJob": {
      "input": {
        "type": "structure",
        "required": [
          "JobId"
        ],
        "members": {
          "JobId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PiiEntitiesDetectionJobProperties": {
            "shape": "S4k"
          }
        }
      }
    },
    "DescribeResourcePolicy": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "ResourceArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ResourcePolicy": {},
          "CreationTime": {
            "type": "timestamp"
          },
          "LastModifiedTime": {
            "type": "timestamp"
          },
          "PolicyRevisionId": {}
        }
      }
    },
    "DescribeSentimentDetectionJob": {
      "input": {
        "type": "structure",
        "required": [
          "JobId"
        ],
        "members": {
          "JobId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SentimentDetectionJobProperties": {
            "shape": "S4v"
          }
        }
      }
    },
    "DescribeTargetedSentimentDetectionJob": {
      "input": {
        "type": "structure",
        "required": [
          "JobId"
        ],
        "members": {
          "JobId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TargetedSentimentDetectionJobProperties": {
            "shape": "S4y"
          }
        }
      }
    },
    "DescribeTopicsDetectionJob": {
      "input": {
        "type": "structure",
        "required": [
          "JobId"
        ],
        "members": {
          "JobId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TopicsDetectionJobProperties": {
            "shape": "S51"
          }
        }
      }
    },
    "DetectDominantLanguage": {
      "input": {
        "type": "structure",
        "required": [
          "Text"
        ],
        "members": {
          "Text": {
            "shape": "S3"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Languages": {
            "shape": "S8"
          }
        },
        "sensitive": true
      }
    },
    "DetectEntities": {
      "input": {
        "type": "structure",
        "required": [
          "Text"
        ],
        "members": {
          "Text": {
            "shape": "S3"
          },
          "LanguageCode": {},
          "EndpointArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Entities": {
            "shape": "Sj"
          }
        },
        "sensitive": true
      }
    },
    "DetectKeyPhrases": {
      "input": {
        "type": "structure",
        "required": [
          "Text",
          "LanguageCode"
        ],
        "members": {
          "Text": {
            "shape": "S3"
          },
          "LanguageCode": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "KeyPhrases": {
            "shape": "Sq"
          }
        },
        "sensitive": true
      }
    },
    "DetectPiiEntities": {
      "input": {
        "type": "structure",
        "required": [
          "Text",
          "LanguageCode"
        ],
        "members": {
          "Text": {},
          "LanguageCode": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Entities": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Score": {
                  "type": "float"
                },
                "Type": {},
                "BeginOffset": {
                  "type": "integer"
                },
                "EndOffset": {
                  "type": "integer"
                }
              }
            }
          }
        }
      }
    },
    "DetectSentiment": {
      "input": {
        "type": "structure",
        "required": [
          "Text",
          "LanguageCode"
        ],
        "members": {
          "Text": {
            "shape": "S3"
          },
          "LanguageCode": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Sentiment": {},
          "SentimentScore": {
            "shape": "Sx"
          }
        },
        "sensitive": true
      }
    },
    "DetectSyntax": {
      "input": {
        "type": "structure",
        "required": [
          "Text",
          "LanguageCode"
        ],
        "members": {
          "Text": {
            "shape": "S3"
          },
          "LanguageCode": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SyntaxTokens": {
            "shape": "S13"
          }
        },
        "sensitive": true
      }
    },
    "ImportModel": {
      "input": {
        "type": "structure",
        "required": [
          "SourceModelArn"
        ],
        "members": {
          "SourceModelArn": {},
          "ModelName": {},
          "VersionName": {},
          "ModelKmsKeyId": {},
          "DataAccessRoleArn": {},
          "Tags": {
            "shape": "S1n"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ModelArn": {}
        }
      }
    },
    "ListDocumentClassificationJobs": {
      "input": {
        "type": "structure",
        "members": {
          "Filter": {
            "type": "structure",
            "members": {
              "JobName": {},
              "JobStatus": {},
              "SubmitTimeBefore": {
                "type": "timestamp"
              },
              "SubmitTimeAfter": {
                "type": "timestamp"
              }
            }
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DocumentClassificationJobPropertiesList": {
            "type": "list",
            "member": {
              "shape": "S38"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListDocumentClassifierSummaries": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DocumentClassifierSummariesList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "DocumentClassifierName": {},
                "NumberOfVersions": {
                  "type": "integer"
                },
                "LatestVersionCreatedAt": {
                  "type": "timestamp"
                },
                "LatestVersionName": {},
                "LatestVersionStatus": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListDocumentClassifiers": {
      "input": {
        "type": "structure",
        "members": {
          "Filter": {
            "type": "structure",
            "members": {
              "Status": {},
              "DocumentClassifierName": {},
              "SubmitTimeBefore": {
                "type": "timestamp"
              },
              "SubmitTimeAfter": {
                "type": "timestamp"
              }
            }
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DocumentClassifierPropertiesList": {
            "type": "list",
            "member": {
              "shape": "S3n"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListDominantLanguageDetectionJobs": {
      "input": {
        "type": "structure",
        "members": {
          "Filter": {
            "type": "structure",
            "members": {
              "JobName": {},
              "JobStatus": {},
              "SubmitTimeBefore": {
                "type": "timestamp"
              },
              "SubmitTimeAfter": {
                "type": "timestamp"
              }
            }
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DominantLanguageDetectionJobPropertiesList": {
            "type": "list",
            "member": {
              "shape": "S3u"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListEndpoints": {
      "input": {
        "type": "structure",
        "members": {
          "Filter": {
            "type": "structure",
            "members": {
              "ModelArn": {},
              "Status": {},
              "CreationTimeBefore": {
                "type": "timestamp"
              },
              "CreationTimeAfter": {
                "type": "timestamp"
              }
            }
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EndpointPropertiesList": {
            "type": "list",
            "member": {
              "shape": "S3x"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListEntitiesDetectionJobs": {
      "input": {
        "type": "structure",
        "members": {
          "Filter": {
            "type": "structure",
            "members": {
              "JobName": {},
              "JobStatus": {},
              "SubmitTimeBefore": {
                "type": "timestamp"
              },
              "SubmitTimeAfter": {
                "type": "timestamp"
              }
            }
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EntitiesDetectionJobPropertiesList": {
            "type": "list",
            "member": {
              "shape": "S41"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListEntityRecognizerSummaries": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EntityRecognizerSummariesList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "RecognizerName": {},
                "NumberOfVersions": {
                  "type": "integer"
                },
                "LatestVersionCreatedAt": {
                  "type": "timestamp"
                },
                "LatestVersionName": {},
                "LatestVersionStatus": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListEntityRecognizers": {
      "input": {
        "type": "structure",
        "members": {
          "Filter": {
            "type": "structure",
            "members": {
              "Status": {},
              "RecognizerName": {},
              "SubmitTimeBefore": {
                "type": "timestamp"
              },
              "SubmitTimeAfter": {
                "type": "timestamp"
              }
            }
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EntityRecognizerPropertiesList": {
            "type": "list",
            "member": {
              "shape": "S44"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListEventsDetectionJobs": {
      "input": {
        "type": "structure",
        "members": {
          "Filter": {
            "type": "structure",
            "members": {
              "JobName": {},
              "JobStatus": {},
              "SubmitTimeBefore": {
                "type": "timestamp"
              },
              "SubmitTimeAfter": {
                "type": "timestamp"
              }
            }
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EventsDetectionJobPropertiesList": {
            "type": "list",
            "member": {
              "shape": "S4c"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListKeyPhrasesDetectionJobs": {
      "input": {
        "type": "structure",
        "members": {
          "Filter": {
            "type": "structure",
            "members": {
              "JobName": {},
              "JobStatus": {},
              "SubmitTimeBefore": {
                "type": "timestamp"
              },
              "SubmitTimeAfter": {
                "type": "timestamp"
              }
            }
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "KeyPhrasesDetectionJobPropertiesList": {
            "type": "list",
            "member": {
              "shape": "S4h"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListPiiEntitiesDetectionJobs": {
      "input": {
        "type": "structure",
        "members": {
          "Filter": {
            "type": "structure",
            "members": {
              "JobName": {},
              "JobStatus": {},
              "SubmitTimeBefore": {
                "type": "timestamp"
              },
              "SubmitTimeAfter": {
                "type": "timestamp"
              }
            }
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PiiEntitiesDetectionJobPropertiesList": {
            "type": "list",
            "member": {
              "shape": "S4k"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListSentimentDetectionJobs": {
      "input": {
        "type": "structure",
        "members": {
          "Filter": {
            "type": "structure",
            "members": {
              "JobName": {},
              "JobStatus": {},
              "SubmitTimeBefore": {
                "type": "timestamp"
              },
              "SubmitTimeAfter": {
                "type": "timestamp"
              }
            }
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SentimentDetectionJobPropertiesList": {
            "type": "list",
            "member": {
              "shape": "S4v"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListTagsForResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "ResourceArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ResourceArn": {},
          "Tags": {
            "shape": "S1n"
          }
        }
      }
    },
    "ListTargetedSentimentDetectionJobs": {
      "input": {
        "type": "structure",
        "members": {
          "Filter": {
            "type": "structure",
            "members": {
              "JobName": {},
              "JobStatus": {},
              "SubmitTimeBefore": {
                "type": "timestamp"
              },
              "SubmitTimeAfter": {
                "type": "timestamp"
              }
            }
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TargetedSentimentDetectionJobPropertiesList": {
            "type": "list",
            "member": {
              "shape": "S4y"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListTopicsDetectionJobs": {
      "input": {
        "type": "structure",
        "members": {
          "Filter": {
            "type": "structure",
            "members": {
              "JobName": {},
              "JobStatus": {},
              "SubmitTimeBefore": {
                "type": "timestamp"
              },
              "SubmitTimeAfter": {
                "type": "timestamp"
              }
            }
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TopicsDetectionJobPropertiesList": {
            "type": "list",
            "member": {
              "shape": "S51"
            }
          },
          "NextToken": {}
        }
      }
    },
    "PutResourcePolicy": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "ResourcePolicy"
        ],
        "members": {
          "ResourceArn": {},
          "ResourcePolicy": {},
          "PolicyRevisionId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PolicyRevisionId": {}
        }
      }
    },
    "StartDocumentClassificationJob": {
      "input": {
        "type": "structure",
        "required": [
          "DocumentClassifierArn",
          "InputDataConfig",
          "OutputDataConfig",
          "DataAccessRoleArn"
        ],
        "members": {
          "JobName": {},
          "DocumentClassifierArn": {},
          "InputDataConfig": {
            "shape": "S3e"
          },
          "OutputDataConfig": {
            "shape": "S3k"
          },
          "DataAccessRoleArn": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "VolumeKmsKeyId": {},
          "VpcConfig": {
            "shape": "S24"
          },
          "Tags": {
            "shape": "S1n"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobId": {},
          "JobArn": {},
          "JobStatus": {}
        }
      }
    },
    "StartDominantLanguageDetectionJob": {
      "input": {
        "type": "structure",
        "required": [
          "InputDataConfig",
          "OutputDataConfig",
          "DataAccessRoleArn"
        ],
        "members": {
          "InputDataConfig": {
            "shape": "S3e"
          },
          "OutputDataConfig": {
            "shape": "S3k"
          },
          "DataAccessRoleArn": {},
          "JobName": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "VolumeKmsKeyId": {},
          "VpcConfig": {
            "shape": "S24"
          },
          "Tags": {
            "shape": "S1n"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobId": {},
          "JobArn": {},
          "JobStatus": {}
        }
      }
    },
    "StartEntitiesDetectionJob": {
      "input": {
        "type": "structure",
        "required": [
          "InputDataConfig",
          "OutputDataConfig",
          "DataAccessRoleArn",
          "LanguageCode"
        ],
        "members": {
          "InputDataConfig": {
            "shape": "S3e"
          },
          "OutputDataConfig": {
            "shape": "S3k"
          },
          "DataAccessRoleArn": {},
          "JobName": {},
          "EntityRecognizerArn": {},
          "LanguageCode": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "VolumeKmsKeyId": {},
          "VpcConfig": {
            "shape": "S24"
          },
          "Tags": {
            "shape": "S1n"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobId": {},
          "JobArn": {},
          "JobStatus": {}
        }
      }
    },
    "StartEventsDetectionJob": {
      "input": {
        "type": "structure",
        "required": [
          "InputDataConfig",
          "OutputDataConfig",
          "DataAccessRoleArn",
          "LanguageCode",
          "TargetEventTypes"
        ],
        "members": {
          "InputDataConfig": {
            "shape": "S3e"
          },
          "OutputDataConfig": {
            "shape": "S3k"
          },
          "DataAccessRoleArn": {},
          "JobName": {},
          "LanguageCode": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "TargetEventTypes": {
            "shape": "S4d"
          },
          "Tags": {
            "shape": "S1n"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobId": {},
          "JobArn": {},
          "JobStatus": {}
        }
      }
    },
    "StartKeyPhrasesDetectionJob": {
      "input": {
        "type": "structure",
        "required": [
          "InputDataConfig",
          "OutputDataConfig",
          "DataAccessRoleArn",
          "LanguageCode"
        ],
        "members": {
          "InputDataConfig": {
            "shape": "S3e"
          },
          "OutputDataConfig": {
            "shape": "S3k"
          },
          "DataAccessRoleArn": {},
          "JobName": {},
          "LanguageCode": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "VolumeKmsKeyId": {},
          "VpcConfig": {
            "shape": "S24"
          },
          "Tags": {
            "shape": "S1n"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobId": {},
          "JobArn": {},
          "JobStatus": {}
        }
      }
    },
    "StartPiiEntitiesDetectionJob": {
      "input": {
        "type": "structure",
        "required": [
          "InputDataConfig",
          "OutputDataConfig",
          "Mode",
          "DataAccessRoleArn",
          "LanguageCode"
        ],
        "members": {
          "InputDataConfig": {
            "shape": "S3e"
          },
          "OutputDataConfig": {
            "shape": "S3k"
          },
          "Mode": {},
          "RedactionConfig": {
            "shape": "S4m"
          },
          "DataAccessRoleArn": {},
          "JobName": {},
          "LanguageCode": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "Tags": {
            "shape": "S1n"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobId": {},
          "JobArn": {},
          "JobStatus": {}
        }
      }
    },
    "StartSentimentDetectionJob": {
      "input": {
        "type": "structure",
        "required": [
          "InputDataConfig",
          "OutputDataConfig",
          "DataAccessRoleArn",
          "LanguageCode"
        ],
        "members": {
          "InputDataConfig": {
            "shape": "S3e"
          },
          "OutputDataConfig": {
            "shape": "S3k"
          },
          "DataAccessRoleArn": {},
          "JobName": {},
          "LanguageCode": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "VolumeKmsKeyId": {},
          "VpcConfig": {
            "shape": "S24"
          },
          "Tags": {
            "shape": "S1n"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobId": {},
          "JobArn": {},
          "JobStatus": {}
        }
      }
    },
    "StartTargetedSentimentDetectionJob": {
      "input": {
        "type": "structure",
        "required": [
          "InputDataConfig",
          "OutputDataConfig",
          "DataAccessRoleArn",
          "LanguageCode"
        ],
        "members": {
          "InputDataConfig": {
            "shape": "S3e"
          },
          "OutputDataConfig": {
            "shape": "S3k"
          },
          "DataAccessRoleArn": {},
          "JobName": {},
          "LanguageCode": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "VolumeKmsKeyId": {},
          "VpcConfig": {
            "shape": "S24"
          },
          "Tags": {
            "shape": "S1n"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobId": {},
          "JobArn": {},
          "JobStatus": {}
        }
      }
    },
    "StartTopicsDetectionJob": {
      "input": {
        "type": "structure",
        "required": [
          "InputDataConfig",
          "OutputDataConfig",
          "DataAccessRoleArn"
        ],
        "members": {
          "InputDataConfig": {
            "shape": "S3e"
          },
          "OutputDataConfig": {
            "shape": "S3k"
          },
          "DataAccessRoleArn": {},
          "JobName": {},
          "NumberOfTopics": {
            "type": "integer"
          },
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "VolumeKmsKeyId": {},
          "VpcConfig": {
            "shape": "S24"
          },
          "Tags": {
            "shape": "S1n"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobId": {},
          "JobArn": {},
          "JobStatus": {}
        }
      }
    },
    "StopDominantLanguageDetectionJob": {
      "input": {
        "type": "structure",
        "required": [
          "JobId"
        ],
        "members": {
          "JobId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobId": {},
          "JobStatus": {}
        }
      }
    },
    "StopEntitiesDetectionJob": {
      "input": {
        "type": "structure",
        "required": [
          "JobId"
        ],
        "members": {
          "JobId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobId": {},
          "JobStatus": {}
        }
      }
    },
    "StopEventsDetectionJob": {
      "input": {
        "type": "structure",
        "required": [
          "JobId"
        ],
        "members": {
          "JobId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobId": {},
          "JobStatus": {}
        }
      }
    },
    "StopKeyPhrasesDetectionJob": {
      "input": {
        "type": "structure",
        "required": [
          "JobId"
        ],
        "members": {
          "JobId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobId": {},
          "JobStatus": {}
        }
      }
    },
    "StopPiiEntitiesDetectionJob": {
      "input": {
        "type": "structure",
        "required": [
          "JobId"
        ],
        "members": {
          "JobId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobId": {},
          "JobStatus": {}
        }
      }
    },
    "StopSentimentDetectionJob": {
      "input": {
        "type": "structure",
        "required": [
          "JobId"
        ],
        "members": {
          "JobId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobId": {},
          "JobStatus": {}
        }
      }
    },
    "StopTargetedSentimentDetectionJob": {
      "input": {
        "type": "structure",
        "required": [
          "JobId"
        ],
        "members": {
          "JobId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobId": {},
          "JobStatus": {}
        }
      }
    },
    "StopTrainingDocumentClassifier": {
      "input": {
        "type": "structure",
        "required": [
          "DocumentClassifierArn"
        ],
        "members": {
          "DocumentClassifierArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "StopTrainingEntityRecognizer": {
      "input": {
        "type": "structure",
        "required": [
          "EntityRecognizerArn"
        ],
        "members": {
          "EntityRecognizerArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "TagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "Tags"
        ],
        "members": {
          "ResourceArn": {},
          "Tags": {
            "shape": "S1n"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "TagKeys"
        ],
        "members": {
          "ResourceArn": {},
          "TagKeys": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateEndpoint": {
      "input": {
        "type": "structure",
        "required": [
          "EndpointArn"
        ],
        "members": {
          "EndpointArn": {},
          "DesiredModelArn": {},
          "DesiredInferenceUnits": {
            "type": "integer"
          },
          "DesiredDataAccessRoleArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    }
  },
  "shapes": {
    "S2": {
      "type": "list",
      "member": {
        "shape": "S3"
      },
      "sensitive": true
    },
    "S3": {
      "type": "string",
      "sensitive": true
    },
    "S8": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "LanguageCode": {},
          "Score": {
            "type": "float"
          }
        }
      }
    },
    "Sc": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Index": {
            "type": "integer"
          },
          "ErrorCode": {},
          "ErrorMessage": {}
        }
      }
    },
    "Sj": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Score": {
            "type": "float"
          },
          "Type": {},
          "Text": {},
          "BeginOffset": {
            "type": "integer"
          },
          "EndOffset": {
            "type": "integer"
          }
        }
      }
    },
    "Sq": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Score": {
            "type": "float"
          },
          "Text": {},
          "BeginOffset": {
            "type": "integer"
          },
          "EndOffset": {
            "type": "integer"
          }
        }
      }
    },
    "Sx": {
      "type": "structure",
      "members": {
        "Positive": {
          "type": "float"
        },
        "Negative": {
          "type": "float"
        },
        "Neutral": {
          "type": "float"
        },
        "Mixed": {
          "type": "float"
        }
      }
    },
    "S13": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "TokenId": {
            "type": "integer"
          },
          "Text": {},
          "BeginOffset": {
            "type": "integer"
          },
          "EndOffset": {
            "type": "integer"
          },
          "PartOfSpeech": {
            "type": "structure",
            "members": {
              "Tag": {},
              "Score": {
                "type": "float"
              }
            }
          }
        }
      }
    },
    "S1n": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key"
        ],
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "S1r": {
      "type": "structure",
      "members": {
        "DataFormat": {},
        "S3Uri": {},
        "TestS3Uri": {},
        "LabelDelimiter": {},
        "AugmentedManifests": {
          "type": "list",
          "member": {
            "shape": "S1w"
          }
        }
      }
    },
    "S1w": {
      "type": "structure",
      "required": [
        "S3Uri",
        "AttributeNames"
      ],
      "members": {
        "S3Uri": {},
        "Split": {},
        "AttributeNames": {
          "type": "list",
          "member": {}
        },
        "AnnotationDataS3Uri": {},
        "SourceDocumentsS3Uri": {},
        "DocumentType": {}
      }
    },
    "S21": {
      "type": "structure",
      "members": {
        "S3Uri": {},
        "KmsKeyId": {}
      }
    },
    "S24": {
      "type": "structure",
      "required": [
        "SecurityGroupIds",
        "Subnets"
      ],
      "members": {
        "SecurityGroupIds": {
          "type": "list",
          "member": {}
        },
        "Subnets": {
          "type": "list",
          "member": {}
        }
      }
    },
    "S2k": {
      "type": "structure",
      "required": [
        "EntityTypes"
      ],
      "members": {
        "DataFormat": {},
        "EntityTypes": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "Type"
            ],
            "members": {
              "Type": {}
            }
          }
        },
        "Documents": {
          "type": "structure",
          "required": [
            "S3Uri"
          ],
          "members": {
            "S3Uri": {},
            "TestS3Uri": {},
            "InputFormat": {}
          }
        },
        "Annotations": {
          "type": "structure",
          "required": [
            "S3Uri"
          ],
          "members": {
            "S3Uri": {},
            "TestS3Uri": {}
          }
        },
        "EntityList": {
          "type": "structure",
          "required": [
            "S3Uri"
          ],
          "members": {
            "S3Uri": {}
          }
        },
        "AugmentedManifests": {
          "type": "list",
          "member": {
            "shape": "S1w"
          }
        }
      }
    },
    "S38": {
      "type": "structure",
      "members": {
        "JobId": {},
        "JobArn": {},
        "JobName": {},
        "JobStatus": {},
        "Message": {},
        "SubmitTime": {
          "type": "timestamp"
        },
        "EndTime": {
          "type": "timestamp"
        },
        "DocumentClassifierArn": {},
        "InputDataConfig": {
          "shape": "S3e"
        },
        "OutputDataConfig": {
          "shape": "S3k"
        },
        "DataAccessRoleArn": {},
        "VolumeKmsKeyId": {},
        "VpcConfig": {
          "shape": "S24"
        }
      }
    },
    "S3e": {
      "type": "structure",
      "required": [
        "S3Uri"
      ],
      "members": {
        "S3Uri": {},
        "InputFormat": {},
        "DocumentReaderConfig": {
          "type": "structure",
          "required": [
            "DocumentReadAction"
          ],
          "members": {
            "DocumentReadAction": {},
            "DocumentReadMode": {},
            "FeatureTypes": {
              "type": "list",
              "member": {}
            }
          }
        }
      }
    },
    "S3k": {
      "type": "structure",
      "required": [
        "S3Uri"
      ],
      "members": {
        "S3Uri": {},
        "KmsKeyId": {}
      }
    },
    "S3n": {
      "type": "structure",
      "members": {
        "DocumentClassifierArn": {},
        "LanguageCode": {},
        "Status": {},
        "Message": {},
        "SubmitTime": {
          "type": "timestamp"
        },
        "EndTime": {
          "type": "timestamp"
        },
        "TrainingStartTime": {
          "type": "timestamp"
        },
        "TrainingEndTime": {
          "type": "timestamp"
        },
        "InputDataConfig": {
          "shape": "S1r"
        },
        "OutputDataConfig": {
          "shape": "S21"
        },
        "ClassifierMetadata": {
          "type": "structure",
          "members": {
            "NumberOfLabels": {
              "type": "integer"
            },
            "NumberOfTrainedDocuments": {
              "type": "integer"
            },
            "NumberOfTestDocuments": {
              "type": "integer"
            },
            "EvaluationMetrics": {
              "type": "structure",
              "members": {
                "Accuracy": {
                  "type": "double"
                },
                "Precision": {
                  "type": "double"
                },
                "Recall": {
                  "type": "double"
                },
                "F1Score": {
                  "type": "double"
                },
                "MicroPrecision": {
                  "type": "double"
                },
                "MicroRecall": {
                  "type": "double"
                },
                "MicroF1Score": {
                  "type": "double"
                },
                "HammingLoss": {
                  "type": "double"
                }
              }
            }
          },
          "sensitive": true
        },
        "DataAccessRoleArn": {},
        "VolumeKmsKeyId": {},
        "VpcConfig": {
          "shape": "S24"
        },
        "Mode": {},
        "ModelKmsKeyId": {},
        "VersionName": {},
        "SourceModelArn": {}
      }
    },
    "S3u": {
      "type": "structure",
      "members": {
        "JobId": {},
        "JobArn": {},
        "JobName": {},
        "JobStatus": {},
        "Message": {},
        "SubmitTime": {
          "type": "timestamp"
        },
        "EndTime": {
          "type": "timestamp"
        },
        "InputDataConfig": {
          "shape": "S3e"
        },
        "OutputDataConfig": {
          "shape": "S3k"
        },
        "DataAccessRoleArn": {},
        "VolumeKmsKeyId": {},
        "VpcConfig": {
          "shape": "S24"
        }
      }
    },
    "S3x": {
      "type": "structure",
      "members": {
        "EndpointArn": {},
        "Status": {},
        "Message": {},
        "ModelArn": {},
        "DesiredModelArn": {},
        "DesiredInferenceUnits": {
          "type": "integer"
        },
        "CurrentInferenceUnits": {
          "type": "integer"
        },
        "CreationTime": {
          "type": "timestamp"
        },
        "LastModifiedTime": {
          "type": "timestamp"
        },
        "DataAccessRoleArn": {},
        "DesiredDataAccessRoleArn": {}
      }
    },
    "S41": {
      "type": "structure",
      "members": {
        "JobId": {},
        "JobArn": {},
        "JobName": {},
        "JobStatus": {},
        "Message": {},
        "SubmitTime": {
          "type": "timestamp"
        },
        "EndTime": {
          "type": "timestamp"
        },
        "EntityRecognizerArn": {},
        "InputDataConfig": {
          "shape": "S3e"
        },
        "OutputDataConfig": {
          "shape": "S3k"
        },
        "LanguageCode": {},
        "DataAccessRoleArn": {},
        "VolumeKmsKeyId": {},
        "VpcConfig": {
          "shape": "S24"
        }
      }
    },
    "S44": {
      "type": "structure",
      "members": {
        "EntityRecognizerArn": {},
        "LanguageCode": {},
        "Status": {},
        "Message": {},
        "SubmitTime": {
          "type": "timestamp"
        },
        "EndTime": {
          "type": "timestamp"
        },
        "TrainingStartTime": {
          "type": "timestamp"
        },
        "TrainingEndTime": {
          "type": "timestamp"
        },
        "InputDataConfig": {
          "shape": "S2k"
        },
        "RecognizerMetadata": {
          "type": "structure",
          "members": {
            "NumberOfTrainedDocuments": {
              "type": "integer"
            },
            "NumberOfTestDocuments": {
              "type": "integer"
            },
            "EvaluationMetrics": {
              "type": "structure",
              "members": {
                "Precision": {
                  "type": "double"
                },
                "Recall": {
                  "type": "double"
                },
                "F1Score": {
                  "type": "double"
                }
              }
            },
            "EntityTypes": {
              "type": "list",
              "member": {
                "type": "structure",
                "members": {
                  "Type": {},
                  "EvaluationMetrics": {
                    "type": "structure",
                    "members": {
                      "Precision": {
                        "type": "double"
                      },
                      "Recall": {
                        "type": "double"
                      },
                      "F1Score": {
                        "type": "double"
                      }
                    }
                  },
                  "NumberOfTrainMentions": {
                    "type": "integer"
                  }
                }
              }
            }
          },
          "sensitive": true
        },
        "DataAccessRoleArn": {},
        "VolumeKmsKeyId": {},
        "VpcConfig": {
          "shape": "S24"
        },
        "ModelKmsKeyId": {},
        "VersionName": {},
        "SourceModelArn": {}
      }
    },
    "S4c": {
      "type": "structure",
      "members": {
        "JobId": {},
        "JobArn": {},
        "JobName": {},
        "JobStatus": {},
        "Message": {},
        "SubmitTime": {
          "type": "timestamp"
        },
        "EndTime": {
          "type": "timestamp"
        },
        "InputDataConfig": {
          "shape": "S3e"
        },
        "OutputDataConfig": {
          "shape": "S3k"
        },
        "LanguageCode": {},
        "DataAccessRoleArn": {},
        "TargetEventTypes": {
          "shape": "S4d"
        }
      }
    },
    "S4d": {
      "type": "list",
      "member": {}
    },
    "S4h": {
      "type": "structure",
      "members": {
        "JobId": {},
        "JobArn": {},
        "JobName": {},
        "JobStatus": {},
        "Message": {},
        "SubmitTime": {
          "type": "timestamp"
        },
        "EndTime": {
          "type": "timestamp"
        },
        "InputDataConfig": {
          "shape": "S3e"
        },
        "OutputDataConfig": {
          "shape": "S3k"
        },
        "LanguageCode": {},
        "DataAccessRoleArn": {},
        "VolumeKmsKeyId": {},
        "VpcConfig": {
          "shape": "S24"
        }
      }
    },
    "S4k": {
      "type": "structure",
      "members": {
        "JobId": {},
        "JobArn": {},
        "JobName": {},
        "JobStatus": {},
        "Message": {},
        "SubmitTime": {
          "type": "timestamp"
        },
        "EndTime": {
          "type": "timestamp"
        },
        "InputDataConfig": {
          "shape": "S3e"
        },
        "OutputDataConfig": {
          "type": "structure",
          "required": [
            "S3Uri"
          ],
          "members": {
            "S3Uri": {},
            "KmsKeyId": {}
          }
        },
        "RedactionConfig": {
          "shape": "S4m"
        },
        "LanguageCode": {},
        "DataAccessRoleArn": {},
        "Mode": {}
      }
    },
    "S4m": {
      "type": "structure",
      "members": {
        "PiiEntityTypes": {
          "type": "list",
          "member": {}
        },
        "MaskMode": {},
        "MaskCharacter": {}
      }
    },
    "S4v": {
      "type": "structure",
      "members": {
        "JobId": {},
        "JobArn": {},
        "JobName": {},
        "JobStatus": {},
        "Message": {},
        "SubmitTime": {
          "type": "timestamp"
        },
        "EndTime": {
          "type": "timestamp"
        },
        "InputDataConfig": {
          "shape": "S3e"
        },
        "OutputDataConfig": {
          "shape": "S3k"
        },
        "LanguageCode": {},
        "DataAccessRoleArn": {},
        "VolumeKmsKeyId": {},
        "VpcConfig": {
          "shape": "S24"
        }
      }
    },
    "S4y": {
      "type": "structure",
      "members": {
        "JobId": {},
        "JobArn": {},
        "JobName": {},
        "JobStatus": {},
        "Message": {},
        "SubmitTime": {
          "type": "timestamp"
        },
        "EndTime": {
          "type": "timestamp"
        },
        "InputDataConfig": {
          "shape": "S3e"
        },
        "OutputDataConfig": {
          "shape": "S3k"
        },
        "LanguageCode": {},
        "DataAccessRoleArn": {},
        "VolumeKmsKeyId": {},
        "VpcConfig": {
          "shape": "S24"
        }
      }
    },
    "S51": {
      "type": "structure",
      "members": {
        "JobId": {},
        "JobArn": {},
        "JobName": {},
        "JobStatus": {},
        "Message": {},
        "SubmitTime": {
          "type": "timestamp"
        },
        "EndTime": {
          "type": "timestamp"
        },
        "InputDataConfig": {
          "shape": "S3e"
        },
        "OutputDataConfig": {
          "shape": "S3k"
        },
        "NumberOfTopics": {
          "type": "integer"
        },
        "DataAccessRoleArn": {},
        "VolumeKmsKeyId": {},
        "VpcConfig": {
          "shape": "S24"
        }
      }
    }
  }
}