"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _axios = _interopRequireDefault(require("axios"));
var _axiosRetry = _interopRequireDefault(require("axios-retry"));
var _sha = _interopRequireDefault(require("crypto-js/sha256"));
var _encHex = _interopRequireDefault(require("crypto-js/enc-hex"));
var _hmacSha = _interopRequireDefault(require("crypto-js/hmac-sha256"));
var _url = _interopRequireDefault(require("url"));
var _utils = _interopRequireDefault(require("./utils"));
function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);
  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    });
    keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};
    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        (0, _defineProperty2["default"])(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }
  return target;
}
var sigV4ClientFactory = {};
sigV4ClientFactory.newClient = function (config) {
  var AWS_SHA_256 = 'AWS4-HMAC-SHA256';
  var AWS4_REQUEST = 'aws4_request';
  var AWS4 = 'AWS4';
  var X_AMZ_DATE = 'x-amz-date';
  var X_AMZ_SECURITY_TOKEN = 'x-amz-security-token';
  var HOST = 'host';
  var AUTHORIZATION = 'Authorization';
  function hash(value) {
    return (0, _sha["default"])(value); // eslint-disable-line
  }
  function hexEncode(value) {
    return value.toString(_encHex["default"]);
  }
  function hmac(secret, value) {
    return (0, _hmacSha["default"])(value, secret, {
      asBytes: true
    }); // eslint-disable-line
  }
  function buildCanonicalRequest(method, path, queryParams, headers, payload) {
    return method + '\n' + buildCanonicalUri(path) + '\n' + buildCanonicalQueryString(queryParams) + '\n' + buildCanonicalHeaders(headers) + '\n' + buildCanonicalSignedHeaders(headers) + '\n' + hexEncode(hash(payload));
  }
  function hashCanonicalRequest(request) {
    return hexEncode(hash(request));
  }
  function buildCanonicalUri(uri) {
    return encodeURI(uri);
  }
  function buildCanonicalQueryString(queryParams) {
    if (Object.keys(queryParams).length < 1) {
      return '';
    }
    var sortedQueryParams = [];
    for (var property in queryParams) {
      if (Object.prototype.hasOwnProperty.call(queryParams, property)) {
        sortedQueryParams.push(property);
      }
    }
    sortedQueryParams.sort();
    var canonicalQueryString = '';
    for (var i = 0; i < sortedQueryParams.length; i++) {
      canonicalQueryString += sortedQueryParams[i] + '=' + fixedEncodeURIComponent(queryParams[sortedQueryParams[i]]) + '&';
    }
    return canonicalQueryString.substr(0, canonicalQueryString.length - 1);
  }
  function fixedEncodeURIComponent(str) {
    return encodeURIComponent(str).replace(/[!'()*]/g, function (c) {
      return '%' + c.charCodeAt(0).toString(16);
    });
  }
  function buildCanonicalHeaders(headers) {
    var canonicalHeaders = '';
    var sortedKeys = [];
    for (var property in headers) {
      if (Object.prototype.hasOwnProperty.call(headers, property)) {
        sortedKeys.push(property);
      }
    }
    sortedKeys.sort(function (a, b) {
      return a.toLowerCase().localeCompare(b.toLowerCase());
    });
    for (var i = 0; i < sortedKeys.length; i++) {
      canonicalHeaders += sortedKeys[i].toLowerCase() + ':' + headers[sortedKeys[i]] + '\n';
    }
    return canonicalHeaders;
  }
  function buildCanonicalSignedHeaders(headers) {
    var sortedKeys = [];
    for (var property in headers) {
      if (Object.prototype.hasOwnProperty.call(headers, property)) {
        sortedKeys.push(property.toLowerCase());
      }
    }
    sortedKeys.sort();
    return sortedKeys.join(';');
  }
  function buildStringToSign(datetime, credentialScope, hashedCanonicalRequest) {
    return AWS_SHA_256 + '\n' + datetime + '\n' + credentialScope + '\n' + hashedCanonicalRequest;
  }
  function buildCredentialScope(datetime, region, service) {
    return datetime.substr(0, 8) + '/' + region + '/' + service + '/' + AWS4_REQUEST;
  }
  function calculateSigningKey(secretKey, datetime, region, service) {
    return hmac(hmac(hmac(hmac(AWS4 + secretKey, datetime.substr(0, 8)), region), service), AWS4_REQUEST);
  }
  function calculateSignature(key, stringToSign) {
    return hexEncode(hmac(key, stringToSign));
  }
  function buildAuthorizationHeader(accessKey, credentialScope, headers, signature) {
    return AWS_SHA_256 + ' Credential=' + accessKey + '/' + credentialScope + ', SignedHeaders=' + buildCanonicalSignedHeaders(headers) + ', Signature=' + signature;
  }
  var awsSigV4Client = {};
  if (config.accessKey === undefined || config.secretKey === undefined) {
    return awsSigV4Client;
  }
  awsSigV4Client.accessKey = _utils["default"].assertDefined(config.accessKey, 'accessKey');
  awsSigV4Client.secretKey = _utils["default"].assertDefined(config.secretKey, 'secretKey');
  awsSigV4Client.sessionToken = config.sessionToken;
  awsSigV4Client.serviceName = _utils["default"].assertDefined(config.serviceName, 'serviceName');
  awsSigV4Client.region = _utils["default"].assertDefined(config.region, 'region');
  awsSigV4Client.endpoint = _utils["default"].assertDefined(config.endpoint, 'endpoint');
  awsSigV4Client.retries = config.retries;
  awsSigV4Client.retryCondition = config.retryCondition;
  awsSigV4Client.retryDelay = config.retryDelay;
  awsSigV4Client.host = config.host;
  awsSigV4Client.makeRequest = function (request) {
    var verb = _utils["default"].assertDefined(request.verb, 'verb');
    var path = _utils["default"].assertDefined(request.path, 'path');
    var queryParams = _utils["default"].copy(request.queryParams);
    var timeout = _utils["default"].copy(request.timeout);
    if (queryParams === undefined) {
      queryParams = {};
    }
    if (timeout === undefined) {
      timeout = 0;
    }
    var headers = _utils["default"].copy(request.headers);
    if (headers === undefined) {
      headers = {};
    } // If the user has not specified an override for Content type the use default

    if (headers['Content-Type'] === undefined) {
      headers['Content-Type'] = config.defaultContentType;
    } // If the user has not specified an override for Accept type the use default

    if (headers['Accept'] === undefined) {
      headers['Accept'] = config.defaultAcceptType;
    }
    var body = _utils["default"].copy(request.body); // stringify request body if content type is JSON

    if (body && headers['Content-Type'] && headers['Content-Type'] === 'application/json') {
      body = JSON.stringify(body);
    } // If there is no body remove the content-type header so it is not included in SigV4 calculation

    if (body === '' || body === undefined || body === null) {
      delete headers['Content-Type'];
    }
    var datetime = new Date(new Date().getTime() + config.systemClockOffset).toISOString().replace(/\.\d{3}Z$/, 'Z').replace(/[:-]|\.\d{3}/g, '');
    headers[X_AMZ_DATE] = datetime;
    if (awsSigV4Client.host) {
      headers[HOST] = awsSigV4Client.host;
    } else {
      var parser = _url["default"].parse(awsSigV4Client.endpoint);
      headers[HOST] = parser.hostname;
    }
    var canonicalRequest = buildCanonicalRequest(verb, path, queryParams, headers, body);
    var hashedCanonicalRequest = hashCanonicalRequest(canonicalRequest);
    var credentialScope = buildCredentialScope(datetime, awsSigV4Client.region, awsSigV4Client.serviceName);
    var stringToSign = buildStringToSign(datetime, credentialScope, hashedCanonicalRequest);
    var signingKey = calculateSigningKey(awsSigV4Client.secretKey, datetime, awsSigV4Client.region, awsSigV4Client.serviceName);
    var signature = calculateSignature(signingKey, stringToSign);
    headers[AUTHORIZATION] = buildAuthorizationHeader(awsSigV4Client.accessKey, credentialScope, headers, signature);
    if (awsSigV4Client.sessionToken !== undefined && awsSigV4Client.sessionToken !== '') {
      headers[X_AMZ_SECURITY_TOKEN] = awsSigV4Client.sessionToken;
    }
    delete headers[HOST];
    var url = config.endpoint + path;
    var queryString = buildCanonicalQueryString(queryParams);
    if (queryString !== '') {
      url += '?' + queryString;
    } // Need to re-attach Content-Type if it is not specified at this point

    if (headers['Content-Type'] === undefined) {
      headers['Content-Type'] = config.defaultContentType;
    }
    var signedRequest = {
      headers: headers,
      timeout: timeout,
      data: body,
      method: verb,
      url: url
    };
    if (config.retries !== undefined) {
      signedRequest.baseURL = url;
      var client = _axios["default"].create(signedRequest); // Allow user configurable delay, or built-in exponential delay

      var retryDelay = function retryDelay() {
        return 0;
      };
      if (config.retryDelay === 'exponential') {
        retryDelay = _axiosRetry["default"].exponentialDelay;
      } else if (typeof config.retryDelay === 'number') {
        retryDelay = function retryDelay() {
          return parseInt(config.retryDelay);
        };
      } else if (typeof config.retryDelay === 'function') {
        retryDelay = config.retryDelay;
      }
      (0, _axiosRetry["default"])(client, _objectSpread(_objectSpread({}, config), {}, {
        retryCondition: config.retryCondition,
        retryDelay: retryDelay
      }));
      return client.request(signedRequest);
    }
    return (0, _axios["default"])(signedRequest);
  };
  return awsSigV4Client;
};
var _default = sigV4ClientFactory;
exports["default"] = _default;
