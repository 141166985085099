{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2012-08-10",
    "endpointPrefix": "dynamodb",
    "jsonVersion": "1.0",
    "protocol": "json",
    "serviceAbbreviation": "DynamoDB",
    "serviceFullName": "Amazon DynamoDB",
    "serviceId": "DynamoDB",
    "signatureVersion": "v4",
    "targetPrefix": "DynamoDB_20120810",
    "uid": "dynamodb-2012-08-10"
  },
  "operations": {
    "BatchExecuteStatement": {
      "input": {
        "type": "structure",
        "required": [
          "Statements"
        ],
        "members": {
          "Statements": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Statement"
              ],
              "members": {
                "Statement": {},
                "Parameters": {
                  "shape": "S5"
                },
                "ConsistentRead": {
                  "type": "boolean"
                }
              }
            }
          },
          "ReturnConsumedCapacity": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Responses": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Error": {
                  "type": "structure",
                  "members": {
                    "Code": {},
                    "Message": {}
                  }
                },
                "TableName": {},
                "Item": {
                  "shape": "Sr"
                }
              }
            }
          },
          "ConsumedCapacity": {
            "shape": "Ss"
          }
        }
      }
    },
    "BatchGetItem": {
      "input": {
        "type": "structure",
        "required": [
          "RequestItems"
        ],
        "members": {
          "RequestItems": {
            "shape": "Sz"
          },
          "ReturnConsumedCapacity": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Responses": {
            "type": "map",
            "key": {},
            "value": {
              "shape": "S19"
            }
          },
          "UnprocessedKeys": {
            "shape": "Sz"
          },
          "ConsumedCapacity": {
            "shape": "Ss"
          }
        }
      },
      "endpointdiscovery": {}
    },
    "BatchWriteItem": {
      "input": {
        "type": "structure",
        "required": [
          "RequestItems"
        ],
        "members": {
          "RequestItems": {
            "shape": "S1b"
          },
          "ReturnConsumedCapacity": {},
          "ReturnItemCollectionMetrics": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UnprocessedItems": {
            "shape": "S1b"
          },
          "ItemCollectionMetrics": {
            "shape": "S1j"
          },
          "ConsumedCapacity": {
            "shape": "Ss"
          }
        }
      },
      "endpointdiscovery": {}
    },
    "CreateBackup": {
      "input": {
        "type": "structure",
        "required": [
          "TableName",
          "BackupName"
        ],
        "members": {
          "TableName": {},
          "BackupName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BackupDetails": {
            "shape": "S1s"
          }
        }
      },
      "endpointdiscovery": {}
    },
    "CreateGlobalTable": {
      "input": {
        "type": "structure",
        "required": [
          "GlobalTableName",
          "ReplicationGroup"
        ],
        "members": {
          "GlobalTableName": {},
          "ReplicationGroup": {
            "shape": "S20"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GlobalTableDescription": {
            "shape": "S24"
          }
        }
      },
      "endpointdiscovery": {}
    },
    "CreateTable": {
      "input": {
        "type": "structure",
        "required": [
          "AttributeDefinitions",
          "TableName",
          "KeySchema"
        ],
        "members": {
          "AttributeDefinitions": {
            "shape": "S2k"
          },
          "TableName": {},
          "KeySchema": {
            "shape": "S2o"
          },
          "LocalSecondaryIndexes": {
            "shape": "S2r"
          },
          "GlobalSecondaryIndexes": {
            "shape": "S2x"
          },
          "BillingMode": {},
          "ProvisionedThroughput": {
            "shape": "S2z"
          },
          "StreamSpecification": {
            "shape": "S31"
          },
          "SSESpecification": {
            "shape": "S34"
          },
          "Tags": {
            "shape": "S37"
          },
          "TableClass": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TableDescription": {
            "shape": "S3c"
          }
        }
      },
      "endpointdiscovery": {}
    },
    "DeleteBackup": {
      "input": {
        "type": "structure",
        "required": [
          "BackupArn"
        ],
        "members": {
          "BackupArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BackupDescription": {
            "shape": "S40"
          }
        }
      },
      "endpointdiscovery": {}
    },
    "DeleteItem": {
      "input": {
        "type": "structure",
        "required": [
          "TableName",
          "Key"
        ],
        "members": {
          "TableName": {},
          "Key": {
            "shape": "S12"
          },
          "Expected": {
            "shape": "S4d"
          },
          "ConditionalOperator": {},
          "ReturnValues": {},
          "ReturnConsumedCapacity": {},
          "ReturnItemCollectionMetrics": {},
          "ConditionExpression": {},
          "ExpressionAttributeNames": {
            "shape": "S15"
          },
          "ExpressionAttributeValues": {
            "shape": "S4l"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Attributes": {
            "shape": "Sr"
          },
          "ConsumedCapacity": {
            "shape": "St"
          },
          "ItemCollectionMetrics": {
            "shape": "S1l"
          }
        }
      },
      "endpointdiscovery": {}
    },
    "DeleteTable": {
      "input": {
        "type": "structure",
        "required": [
          "TableName"
        ],
        "members": {
          "TableName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TableDescription": {
            "shape": "S3c"
          }
        }
      },
      "endpointdiscovery": {}
    },
    "DescribeBackup": {
      "input": {
        "type": "structure",
        "required": [
          "BackupArn"
        ],
        "members": {
          "BackupArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BackupDescription": {
            "shape": "S40"
          }
        }
      },
      "endpointdiscovery": {}
    },
    "DescribeContinuousBackups": {
      "input": {
        "type": "structure",
        "required": [
          "TableName"
        ],
        "members": {
          "TableName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ContinuousBackupsDescription": {
            "shape": "S4u"
          }
        }
      },
      "endpointdiscovery": {}
    },
    "DescribeContributorInsights": {
      "input": {
        "type": "structure",
        "required": [
          "TableName"
        ],
        "members": {
          "TableName": {},
          "IndexName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TableName": {},
          "IndexName": {},
          "ContributorInsightsRuleList": {
            "type": "list",
            "member": {}
          },
          "ContributorInsightsStatus": {},
          "LastUpdateDateTime": {
            "type": "timestamp"
          },
          "FailureException": {
            "type": "structure",
            "members": {
              "ExceptionName": {},
              "ExceptionDescription": {}
            }
          }
        }
      }
    },
    "DescribeEndpoints": {
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "required": [
          "Endpoints"
        ],
        "members": {
          "Endpoints": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Address",
                "CachePeriodInMinutes"
              ],
              "members": {
                "Address": {},
                "CachePeriodInMinutes": {
                  "type": "long"
                }
              }
            }
          }
        }
      },
      "endpointoperation": true
    },
    "DescribeExport": {
      "input": {
        "type": "structure",
        "required": [
          "ExportArn"
        ],
        "members": {
          "ExportArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ExportDescription": {
            "shape": "S5e"
          }
        }
      }
    },
    "DescribeGlobalTable": {
      "input": {
        "type": "structure",
        "required": [
          "GlobalTableName"
        ],
        "members": {
          "GlobalTableName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GlobalTableDescription": {
            "shape": "S24"
          }
        }
      },
      "endpointdiscovery": {}
    },
    "DescribeGlobalTableSettings": {
      "input": {
        "type": "structure",
        "required": [
          "GlobalTableName"
        ],
        "members": {
          "GlobalTableName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GlobalTableName": {},
          "ReplicaSettings": {
            "shape": "S5y"
          }
        }
      },
      "endpointdiscovery": {}
    },
    "DescribeKinesisStreamingDestination": {
      "input": {
        "type": "structure",
        "required": [
          "TableName"
        ],
        "members": {
          "TableName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TableName": {},
          "KinesisDataStreamDestinations": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "StreamArn": {},
                "DestinationStatus": {},
                "DestinationStatusDescription": {}
              }
            }
          }
        }
      },
      "endpointdiscovery": {}
    },
    "DescribeLimits": {
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "AccountMaxReadCapacityUnits": {
            "type": "long"
          },
          "AccountMaxWriteCapacityUnits": {
            "type": "long"
          },
          "TableMaxReadCapacityUnits": {
            "type": "long"
          },
          "TableMaxWriteCapacityUnits": {
            "type": "long"
          }
        }
      },
      "endpointdiscovery": {}
    },
    "DescribeTable": {
      "input": {
        "type": "structure",
        "required": [
          "TableName"
        ],
        "members": {
          "TableName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Table": {
            "shape": "S3c"
          }
        }
      },
      "endpointdiscovery": {}
    },
    "DescribeTableReplicaAutoScaling": {
      "input": {
        "type": "structure",
        "required": [
          "TableName"
        ],
        "members": {
          "TableName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TableAutoScalingDescription": {
            "shape": "S6k"
          }
        }
      }
    },
    "DescribeTimeToLive": {
      "input": {
        "type": "structure",
        "required": [
          "TableName"
        ],
        "members": {
          "TableName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TimeToLiveDescription": {
            "shape": "S49"
          }
        }
      },
      "endpointdiscovery": {}
    },
    "DisableKinesisStreamingDestination": {
      "input": {
        "shape": "S6r"
      },
      "output": {
        "shape": "S6s"
      },
      "endpointdiscovery": {}
    },
    "EnableKinesisStreamingDestination": {
      "input": {
        "shape": "S6r"
      },
      "output": {
        "shape": "S6s"
      },
      "endpointdiscovery": {}
    },
    "ExecuteStatement": {
      "input": {
        "type": "structure",
        "required": [
          "Statement"
        ],
        "members": {
          "Statement": {},
          "Parameters": {
            "shape": "S5"
          },
          "ConsistentRead": {
            "type": "boolean"
          },
          "NextToken": {},
          "ReturnConsumedCapacity": {},
          "Limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Items": {
            "shape": "S19"
          },
          "NextToken": {},
          "ConsumedCapacity": {
            "shape": "St"
          },
          "LastEvaluatedKey": {
            "shape": "S12"
          }
        }
      }
    },
    "ExecuteTransaction": {
      "input": {
        "type": "structure",
        "required": [
          "TransactStatements"
        ],
        "members": {
          "TransactStatements": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Statement"
              ],
              "members": {
                "Statement": {},
                "Parameters": {
                  "shape": "S5"
                }
              }
            }
          },
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "ReturnConsumedCapacity": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Responses": {
            "shape": "S72"
          },
          "ConsumedCapacity": {
            "shape": "Ss"
          }
        }
      }
    },
    "ExportTableToPointInTime": {
      "input": {
        "type": "structure",
        "required": [
          "TableArn",
          "S3Bucket"
        ],
        "members": {
          "TableArn": {},
          "ExportTime": {
            "type": "timestamp"
          },
          "ClientToken": {
            "idempotencyToken": true
          },
          "S3Bucket": {},
          "S3BucketOwner": {},
          "S3Prefix": {},
          "S3SseAlgorithm": {},
          "S3SseKmsKeyId": {},
          "ExportFormat": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ExportDescription": {
            "shape": "S5e"
          }
        }
      }
    },
    "GetItem": {
      "input": {
        "type": "structure",
        "required": [
          "TableName",
          "Key"
        ],
        "members": {
          "TableName": {},
          "Key": {
            "shape": "S12"
          },
          "AttributesToGet": {
            "shape": "S13"
          },
          "ConsistentRead": {
            "type": "boolean"
          },
          "ReturnConsumedCapacity": {},
          "ProjectionExpression": {},
          "ExpressionAttributeNames": {
            "shape": "S15"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Item": {
            "shape": "Sr"
          },
          "ConsumedCapacity": {
            "shape": "St"
          }
        }
      },
      "endpointdiscovery": {}
    },
    "ListBackups": {
      "input": {
        "type": "structure",
        "members": {
          "TableName": {},
          "Limit": {
            "type": "integer"
          },
          "TimeRangeLowerBound": {
            "type": "timestamp"
          },
          "TimeRangeUpperBound": {
            "type": "timestamp"
          },
          "ExclusiveStartBackupArn": {},
          "BackupType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BackupSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "TableName": {},
                "TableId": {},
                "TableArn": {},
                "BackupArn": {},
                "BackupName": {},
                "BackupCreationDateTime": {
                  "type": "timestamp"
                },
                "BackupExpiryDateTime": {
                  "type": "timestamp"
                },
                "BackupStatus": {},
                "BackupType": {},
                "BackupSizeBytes": {
                  "type": "long"
                }
              }
            }
          },
          "LastEvaluatedBackupArn": {}
        }
      },
      "endpointdiscovery": {}
    },
    "ListContributorInsights": {
      "input": {
        "type": "structure",
        "members": {
          "TableName": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ContributorInsightsSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "TableName": {},
                "IndexName": {},
                "ContributorInsightsStatus": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListExports": {
      "input": {
        "type": "structure",
        "members": {
          "TableArn": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ExportSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ExportArn": {},
                "ExportStatus": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListGlobalTables": {
      "input": {
        "type": "structure",
        "members": {
          "ExclusiveStartGlobalTableName": {},
          "Limit": {
            "type": "integer"
          },
          "RegionName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GlobalTables": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "GlobalTableName": {},
                "ReplicationGroup": {
                  "shape": "S20"
                }
              }
            }
          },
          "LastEvaluatedGlobalTableName": {}
        }
      },
      "endpointdiscovery": {}
    },
    "ListTables": {
      "input": {
        "type": "structure",
        "members": {
          "ExclusiveStartTableName": {},
          "Limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TableNames": {
            "type": "list",
            "member": {}
          },
          "LastEvaluatedTableName": {}
        }
      },
      "endpointdiscovery": {}
    },
    "ListTagsOfResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "ResourceArn": {},
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Tags": {
            "shape": "S37"
          },
          "NextToken": {}
        }
      },
      "endpointdiscovery": {}
    },
    "PutItem": {
      "input": {
        "type": "structure",
        "required": [
          "TableName",
          "Item"
        ],
        "members": {
          "TableName": {},
          "Item": {
            "shape": "S1f"
          },
          "Expected": {
            "shape": "S4d"
          },
          "ReturnValues": {},
          "ReturnConsumedCapacity": {},
          "ReturnItemCollectionMetrics": {},
          "ConditionalOperator": {},
          "ConditionExpression": {},
          "ExpressionAttributeNames": {
            "shape": "S15"
          },
          "ExpressionAttributeValues": {
            "shape": "S4l"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Attributes": {
            "shape": "Sr"
          },
          "ConsumedCapacity": {
            "shape": "St"
          },
          "ItemCollectionMetrics": {
            "shape": "S1l"
          }
        }
      },
      "endpointdiscovery": {}
    },
    "Query": {
      "input": {
        "type": "structure",
        "required": [
          "TableName"
        ],
        "members": {
          "TableName": {},
          "IndexName": {},
          "Select": {},
          "AttributesToGet": {
            "shape": "S13"
          },
          "Limit": {
            "type": "integer"
          },
          "ConsistentRead": {
            "type": "boolean"
          },
          "KeyConditions": {
            "type": "map",
            "key": {},
            "value": {
              "shape": "S88"
            }
          },
          "QueryFilter": {
            "shape": "S89"
          },
          "ConditionalOperator": {},
          "ScanIndexForward": {
            "type": "boolean"
          },
          "ExclusiveStartKey": {
            "shape": "S12"
          },
          "ReturnConsumedCapacity": {},
          "ProjectionExpression": {},
          "FilterExpression": {},
          "KeyConditionExpression": {},
          "ExpressionAttributeNames": {
            "shape": "S15"
          },
          "ExpressionAttributeValues": {
            "shape": "S4l"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Items": {
            "shape": "S19"
          },
          "Count": {
            "type": "integer"
          },
          "ScannedCount": {
            "type": "integer"
          },
          "LastEvaluatedKey": {
            "shape": "S12"
          },
          "ConsumedCapacity": {
            "shape": "St"
          }
        }
      },
      "endpointdiscovery": {}
    },
    "RestoreTableFromBackup": {
      "input": {
        "type": "structure",
        "required": [
          "TargetTableName",
          "BackupArn"
        ],
        "members": {
          "TargetTableName": {},
          "BackupArn": {},
          "BillingModeOverride": {},
          "GlobalSecondaryIndexOverride": {
            "shape": "S2x"
          },
          "LocalSecondaryIndexOverride": {
            "shape": "S2r"
          },
          "ProvisionedThroughputOverride": {
            "shape": "S2z"
          },
          "SSESpecificationOverride": {
            "shape": "S34"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TableDescription": {
            "shape": "S3c"
          }
        }
      },
      "endpointdiscovery": {}
    },
    "RestoreTableToPointInTime": {
      "input": {
        "type": "structure",
        "required": [
          "TargetTableName"
        ],
        "members": {
          "SourceTableArn": {},
          "SourceTableName": {},
          "TargetTableName": {},
          "UseLatestRestorableTime": {
            "type": "boolean"
          },
          "RestoreDateTime": {
            "type": "timestamp"
          },
          "BillingModeOverride": {},
          "GlobalSecondaryIndexOverride": {
            "shape": "S2x"
          },
          "LocalSecondaryIndexOverride": {
            "shape": "S2r"
          },
          "ProvisionedThroughputOverride": {
            "shape": "S2z"
          },
          "SSESpecificationOverride": {
            "shape": "S34"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TableDescription": {
            "shape": "S3c"
          }
        }
      },
      "endpointdiscovery": {}
    },
    "Scan": {
      "input": {
        "type": "structure",
        "required": [
          "TableName"
        ],
        "members": {
          "TableName": {},
          "IndexName": {},
          "AttributesToGet": {
            "shape": "S13"
          },
          "Limit": {
            "type": "integer"
          },
          "Select": {},
          "ScanFilter": {
            "shape": "S89"
          },
          "ConditionalOperator": {},
          "ExclusiveStartKey": {
            "shape": "S12"
          },
          "ReturnConsumedCapacity": {},
          "TotalSegments": {
            "type": "integer"
          },
          "Segment": {
            "type": "integer"
          },
          "ProjectionExpression": {},
          "FilterExpression": {},
          "ExpressionAttributeNames": {
            "shape": "S15"
          },
          "ExpressionAttributeValues": {
            "shape": "S4l"
          },
          "ConsistentRead": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Items": {
            "shape": "S19"
          },
          "Count": {
            "type": "integer"
          },
          "ScannedCount": {
            "type": "integer"
          },
          "LastEvaluatedKey": {
            "shape": "S12"
          },
          "ConsumedCapacity": {
            "shape": "St"
          }
        }
      },
      "endpointdiscovery": {}
    },
    "TagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "Tags"
        ],
        "members": {
          "ResourceArn": {},
          "Tags": {
            "shape": "S37"
          }
        }
      },
      "endpointdiscovery": {}
    },
    "TransactGetItems": {
      "input": {
        "type": "structure",
        "required": [
          "TransactItems"
        ],
        "members": {
          "TransactItems": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Get"
              ],
              "members": {
                "Get": {
                  "type": "structure",
                  "required": [
                    "Key",
                    "TableName"
                  ],
                  "members": {
                    "Key": {
                      "shape": "S12"
                    },
                    "TableName": {},
                    "ProjectionExpression": {},
                    "ExpressionAttributeNames": {
                      "shape": "S15"
                    }
                  }
                }
              }
            }
          },
          "ReturnConsumedCapacity": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ConsumedCapacity": {
            "shape": "Ss"
          },
          "Responses": {
            "shape": "S72"
          }
        }
      },
      "endpointdiscovery": {}
    },
    "TransactWriteItems": {
      "input": {
        "type": "structure",
        "required": [
          "TransactItems"
        ],
        "members": {
          "TransactItems": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ConditionCheck": {
                  "type": "structure",
                  "required": [
                    "Key",
                    "TableName",
                    "ConditionExpression"
                  ],
                  "members": {
                    "Key": {
                      "shape": "S12"
                    },
                    "TableName": {},
                    "ConditionExpression": {},
                    "ExpressionAttributeNames": {
                      "shape": "S15"
                    },
                    "ExpressionAttributeValues": {
                      "shape": "S4l"
                    },
                    "ReturnValuesOnConditionCheckFailure": {}
                  }
                },
                "Put": {
                  "type": "structure",
                  "required": [
                    "Item",
                    "TableName"
                  ],
                  "members": {
                    "Item": {
                      "shape": "S1f"
                    },
                    "TableName": {},
                    "ConditionExpression": {},
                    "ExpressionAttributeNames": {
                      "shape": "S15"
                    },
                    "ExpressionAttributeValues": {
                      "shape": "S4l"
                    },
                    "ReturnValuesOnConditionCheckFailure": {}
                  }
                },
                "Delete": {
                  "type": "structure",
                  "required": [
                    "Key",
                    "TableName"
                  ],
                  "members": {
                    "Key": {
                      "shape": "S12"
                    },
                    "TableName": {},
                    "ConditionExpression": {},
                    "ExpressionAttributeNames": {
                      "shape": "S15"
                    },
                    "ExpressionAttributeValues": {
                      "shape": "S4l"
                    },
                    "ReturnValuesOnConditionCheckFailure": {}
                  }
                },
                "Update": {
                  "type": "structure",
                  "required": [
                    "Key",
                    "UpdateExpression",
                    "TableName"
                  ],
                  "members": {
                    "Key": {
                      "shape": "S12"
                    },
                    "UpdateExpression": {},
                    "TableName": {},
                    "ConditionExpression": {},
                    "ExpressionAttributeNames": {
                      "shape": "S15"
                    },
                    "ExpressionAttributeValues": {
                      "shape": "S4l"
                    },
                    "ReturnValuesOnConditionCheckFailure": {}
                  }
                }
              }
            }
          },
          "ReturnConsumedCapacity": {},
          "ReturnItemCollectionMetrics": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ConsumedCapacity": {
            "shape": "Ss"
          },
          "ItemCollectionMetrics": {
            "shape": "S1j"
          }
        }
      },
      "endpointdiscovery": {}
    },
    "UntagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "TagKeys"
        ],
        "members": {
          "ResourceArn": {},
          "TagKeys": {
            "type": "list",
            "member": {}
          }
        }
      },
      "endpointdiscovery": {}
    },
    "UpdateContinuousBackups": {
      "input": {
        "type": "structure",
        "required": [
          "TableName",
          "PointInTimeRecoverySpecification"
        ],
        "members": {
          "TableName": {},
          "PointInTimeRecoverySpecification": {
            "type": "structure",
            "required": [
              "PointInTimeRecoveryEnabled"
            ],
            "members": {
              "PointInTimeRecoveryEnabled": {
                "type": "boolean"
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ContinuousBackupsDescription": {
            "shape": "S4u"
          }
        }
      },
      "endpointdiscovery": {}
    },
    "UpdateContributorInsights": {
      "input": {
        "type": "structure",
        "required": [
          "TableName",
          "ContributorInsightsAction"
        ],
        "members": {
          "TableName": {},
          "IndexName": {},
          "ContributorInsightsAction": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TableName": {},
          "IndexName": {},
          "ContributorInsightsStatus": {}
        }
      }
    },
    "UpdateGlobalTable": {
      "input": {
        "type": "structure",
        "required": [
          "GlobalTableName",
          "ReplicaUpdates"
        ],
        "members": {
          "GlobalTableName": {},
          "ReplicaUpdates": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Create": {
                  "type": "structure",
                  "required": [
                    "RegionName"
                  ],
                  "members": {
                    "RegionName": {}
                  }
                },
                "Delete": {
                  "type": "structure",
                  "required": [
                    "RegionName"
                  ],
                  "members": {
                    "RegionName": {}
                  }
                }
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GlobalTableDescription": {
            "shape": "S24"
          }
        }
      },
      "endpointdiscovery": {}
    },
    "UpdateGlobalTableSettings": {
      "input": {
        "type": "structure",
        "required": [
          "GlobalTableName"
        ],
        "members": {
          "GlobalTableName": {},
          "GlobalTableBillingMode": {},
          "GlobalTableProvisionedWriteCapacityUnits": {
            "type": "long"
          },
          "GlobalTableProvisionedWriteCapacityAutoScalingSettingsUpdate": {
            "shape": "S9g"
          },
          "GlobalTableGlobalSecondaryIndexSettingsUpdate": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "IndexName"
              ],
              "members": {
                "IndexName": {},
                "ProvisionedWriteCapacityUnits": {
                  "type": "long"
                },
                "ProvisionedWriteCapacityAutoScalingSettingsUpdate": {
                  "shape": "S9g"
                }
              }
            }
          },
          "ReplicaSettingsUpdate": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "RegionName"
              ],
              "members": {
                "RegionName": {},
                "ReplicaProvisionedReadCapacityUnits": {
                  "type": "long"
                },
                "ReplicaProvisionedReadCapacityAutoScalingSettingsUpdate": {
                  "shape": "S9g"
                },
                "ReplicaGlobalSecondaryIndexSettingsUpdate": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "required": [
                      "IndexName"
                    ],
                    "members": {
                      "IndexName": {},
                      "ProvisionedReadCapacityUnits": {
                        "type": "long"
                      },
                      "ProvisionedReadCapacityAutoScalingSettingsUpdate": {
                        "shape": "S9g"
                      }
                    }
                  }
                },
                "ReplicaTableClass": {}
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GlobalTableName": {},
          "ReplicaSettings": {
            "shape": "S5y"
          }
        }
      },
      "endpointdiscovery": {}
    },
    "UpdateItem": {
      "input": {
        "type": "structure",
        "required": [
          "TableName",
          "Key"
        ],
        "members": {
          "TableName": {},
          "Key": {
            "shape": "S12"
          },
          "AttributeUpdates": {
            "type": "map",
            "key": {},
            "value": {
              "type": "structure",
              "members": {
                "Value": {
                  "shape": "S6"
                },
                "Action": {}
              }
            }
          },
          "Expected": {
            "shape": "S4d"
          },
          "ConditionalOperator": {},
          "ReturnValues": {},
          "ReturnConsumedCapacity": {},
          "ReturnItemCollectionMetrics": {},
          "UpdateExpression": {},
          "ConditionExpression": {},
          "ExpressionAttributeNames": {
            "shape": "S15"
          },
          "ExpressionAttributeValues": {
            "shape": "S4l"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Attributes": {
            "shape": "Sr"
          },
          "ConsumedCapacity": {
            "shape": "St"
          },
          "ItemCollectionMetrics": {
            "shape": "S1l"
          }
        }
      },
      "endpointdiscovery": {}
    },
    "UpdateTable": {
      "input": {
        "type": "structure",
        "required": [
          "TableName"
        ],
        "members": {
          "AttributeDefinitions": {
            "shape": "S2k"
          },
          "TableName": {},
          "BillingMode": {},
          "ProvisionedThroughput": {
            "shape": "S2z"
          },
          "GlobalSecondaryIndexUpdates": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Update": {
                  "type": "structure",
                  "required": [
                    "IndexName",
                    "ProvisionedThroughput"
                  ],
                  "members": {
                    "IndexName": {},
                    "ProvisionedThroughput": {
                      "shape": "S2z"
                    }
                  }
                },
                "Create": {
                  "type": "structure",
                  "required": [
                    "IndexName",
                    "KeySchema",
                    "Projection"
                  ],
                  "members": {
                    "IndexName": {},
                    "KeySchema": {
                      "shape": "S2o"
                    },
                    "Projection": {
                      "shape": "S2t"
                    },
                    "ProvisionedThroughput": {
                      "shape": "S2z"
                    }
                  }
                },
                "Delete": {
                  "type": "structure",
                  "required": [
                    "IndexName"
                  ],
                  "members": {
                    "IndexName": {}
                  }
                }
              }
            }
          },
          "StreamSpecification": {
            "shape": "S31"
          },
          "SSESpecification": {
            "shape": "S34"
          },
          "ReplicaUpdates": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Create": {
                  "type": "structure",
                  "required": [
                    "RegionName"
                  ],
                  "members": {
                    "RegionName": {},
                    "KMSMasterKeyId": {},
                    "ProvisionedThroughputOverride": {
                      "shape": "S2b"
                    },
                    "GlobalSecondaryIndexes": {
                      "shape": "Sa5"
                    },
                    "TableClassOverride": {}
                  }
                },
                "Update": {
                  "type": "structure",
                  "required": [
                    "RegionName"
                  ],
                  "members": {
                    "RegionName": {},
                    "KMSMasterKeyId": {},
                    "ProvisionedThroughputOverride": {
                      "shape": "S2b"
                    },
                    "GlobalSecondaryIndexes": {
                      "shape": "Sa5"
                    },
                    "TableClassOverride": {}
                  }
                },
                "Delete": {
                  "type": "structure",
                  "required": [
                    "RegionName"
                  ],
                  "members": {
                    "RegionName": {}
                  }
                }
              }
            }
          },
          "TableClass": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TableDescription": {
            "shape": "S3c"
          }
        }
      },
      "endpointdiscovery": {}
    },
    "UpdateTableReplicaAutoScaling": {
      "input": {
        "type": "structure",
        "required": [
          "TableName"
        ],
        "members": {
          "GlobalSecondaryIndexUpdates": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "IndexName": {},
                "ProvisionedWriteCapacityAutoScalingUpdate": {
                  "shape": "S9g"
                }
              }
            }
          },
          "TableName": {},
          "ProvisionedWriteCapacityAutoScalingUpdate": {
            "shape": "S9g"
          },
          "ReplicaUpdates": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "RegionName"
              ],
              "members": {
                "RegionName": {},
                "ReplicaGlobalSecondaryIndexUpdates": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "IndexName": {},
                      "ProvisionedReadCapacityAutoScalingUpdate": {
                        "shape": "S9g"
                      }
                    }
                  }
                },
                "ReplicaProvisionedReadCapacityAutoScalingUpdate": {
                  "shape": "S9g"
                }
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TableAutoScalingDescription": {
            "shape": "S6k"
          }
        }
      }
    },
    "UpdateTimeToLive": {
      "input": {
        "type": "structure",
        "required": [
          "TableName",
          "TimeToLiveSpecification"
        ],
        "members": {
          "TableName": {},
          "TimeToLiveSpecification": {
            "shape": "Saj"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TimeToLiveSpecification": {
            "shape": "Saj"
          }
        }
      },
      "endpointdiscovery": {}
    }
  },
  "shapes": {
    "S5": {
      "type": "list",
      "member": {
        "shape": "S6"
      }
    },
    "S6": {
      "type": "structure",
      "members": {
        "S": {},
        "N": {},
        "B": {
          "type": "blob"
        },
        "SS": {
          "type": "list",
          "member": {}
        },
        "NS": {
          "type": "list",
          "member": {}
        },
        "BS": {
          "type": "list",
          "member": {
            "type": "blob"
          }
        },
        "M": {
          "type": "map",
          "key": {},
          "value": {
            "shape": "S6"
          }
        },
        "L": {
          "type": "list",
          "member": {
            "shape": "S6"
          }
        },
        "NULL": {
          "type": "boolean"
        },
        "BOOL": {
          "type": "boolean"
        }
      }
    },
    "Sr": {
      "type": "map",
      "key": {},
      "value": {
        "shape": "S6"
      }
    },
    "Ss": {
      "type": "list",
      "member": {
        "shape": "St"
      }
    },
    "St": {
      "type": "structure",
      "members": {
        "TableName": {},
        "CapacityUnits": {
          "type": "double"
        },
        "ReadCapacityUnits": {
          "type": "double"
        },
        "WriteCapacityUnits": {
          "type": "double"
        },
        "Table": {
          "shape": "Sv"
        },
        "LocalSecondaryIndexes": {
          "shape": "Sw"
        },
        "GlobalSecondaryIndexes": {
          "shape": "Sw"
        }
      }
    },
    "Sv": {
      "type": "structure",
      "members": {
        "ReadCapacityUnits": {
          "type": "double"
        },
        "WriteCapacityUnits": {
          "type": "double"
        },
        "CapacityUnits": {
          "type": "double"
        }
      }
    },
    "Sw": {
      "type": "map",
      "key": {},
      "value": {
        "shape": "Sv"
      }
    },
    "Sz": {
      "type": "map",
      "key": {},
      "value": {
        "type": "structure",
        "required": [
          "Keys"
        ],
        "members": {
          "Keys": {
            "type": "list",
            "member": {
              "shape": "S12"
            }
          },
          "AttributesToGet": {
            "shape": "S13"
          },
          "ConsistentRead": {
            "type": "boolean"
          },
          "ProjectionExpression": {},
          "ExpressionAttributeNames": {
            "shape": "S15"
          }
        }
      }
    },
    "S12": {
      "type": "map",
      "key": {},
      "value": {
        "shape": "S6"
      }
    },
    "S13": {
      "type": "list",
      "member": {}
    },
    "S15": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S19": {
      "type": "list",
      "member": {
        "shape": "Sr"
      }
    },
    "S1b": {
      "type": "map",
      "key": {},
      "value": {
        "type": "list",
        "member": {
          "type": "structure",
          "members": {
            "PutRequest": {
              "type": "structure",
              "required": [
                "Item"
              ],
              "members": {
                "Item": {
                  "shape": "S1f"
                }
              }
            },
            "DeleteRequest": {
              "type": "structure",
              "required": [
                "Key"
              ],
              "members": {
                "Key": {
                  "shape": "S12"
                }
              }
            }
          }
        }
      }
    },
    "S1f": {
      "type": "map",
      "key": {},
      "value": {
        "shape": "S6"
      }
    },
    "S1j": {
      "type": "map",
      "key": {},
      "value": {
        "type": "list",
        "member": {
          "shape": "S1l"
        }
      }
    },
    "S1l": {
      "type": "structure",
      "members": {
        "ItemCollectionKey": {
          "type": "map",
          "key": {},
          "value": {
            "shape": "S6"
          }
        },
        "SizeEstimateRangeGB": {
          "type": "list",
          "member": {
            "type": "double"
          }
        }
      }
    },
    "S1s": {
      "type": "structure",
      "required": [
        "BackupArn",
        "BackupName",
        "BackupStatus",
        "BackupType",
        "BackupCreationDateTime"
      ],
      "members": {
        "BackupArn": {},
        "BackupName": {},
        "BackupSizeBytes": {
          "type": "long"
        },
        "BackupStatus": {},
        "BackupType": {},
        "BackupCreationDateTime": {
          "type": "timestamp"
        },
        "BackupExpiryDateTime": {
          "type": "timestamp"
        }
      }
    },
    "S20": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "RegionName": {}
        }
      }
    },
    "S24": {
      "type": "structure",
      "members": {
        "ReplicationGroup": {
          "shape": "S25"
        },
        "GlobalTableArn": {},
        "CreationDateTime": {
          "type": "timestamp"
        },
        "GlobalTableStatus": {},
        "GlobalTableName": {}
      }
    },
    "S25": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "RegionName": {},
          "ReplicaStatus": {},
          "ReplicaStatusDescription": {},
          "ReplicaStatusPercentProgress": {},
          "KMSMasterKeyId": {},
          "ProvisionedThroughputOverride": {
            "shape": "S2b"
          },
          "GlobalSecondaryIndexes": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "IndexName": {},
                "ProvisionedThroughputOverride": {
                  "shape": "S2b"
                }
              }
            }
          },
          "ReplicaInaccessibleDateTime": {
            "type": "timestamp"
          },
          "ReplicaTableClassSummary": {
            "shape": "S2f"
          }
        }
      }
    },
    "S2b": {
      "type": "structure",
      "members": {
        "ReadCapacityUnits": {
          "type": "long"
        }
      }
    },
    "S2f": {
      "type": "structure",
      "members": {
        "TableClass": {},
        "LastUpdateDateTime": {
          "type": "timestamp"
        }
      }
    },
    "S2k": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "AttributeName",
          "AttributeType"
        ],
        "members": {
          "AttributeName": {},
          "AttributeType": {}
        }
      }
    },
    "S2o": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "AttributeName",
          "KeyType"
        ],
        "members": {
          "AttributeName": {},
          "KeyType": {}
        }
      }
    },
    "S2r": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "IndexName",
          "KeySchema",
          "Projection"
        ],
        "members": {
          "IndexName": {},
          "KeySchema": {
            "shape": "S2o"
          },
          "Projection": {
            "shape": "S2t"
          }
        }
      }
    },
    "S2t": {
      "type": "structure",
      "members": {
        "ProjectionType": {},
        "NonKeyAttributes": {
          "type": "list",
          "member": {}
        }
      }
    },
    "S2x": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "IndexName",
          "KeySchema",
          "Projection"
        ],
        "members": {
          "IndexName": {},
          "KeySchema": {
            "shape": "S2o"
          },
          "Projection": {
            "shape": "S2t"
          },
          "ProvisionedThroughput": {
            "shape": "S2z"
          }
        }
      }
    },
    "S2z": {
      "type": "structure",
      "required": [
        "ReadCapacityUnits",
        "WriteCapacityUnits"
      ],
      "members": {
        "ReadCapacityUnits": {
          "type": "long"
        },
        "WriteCapacityUnits": {
          "type": "long"
        }
      }
    },
    "S31": {
      "type": "structure",
      "required": [
        "StreamEnabled"
      ],
      "members": {
        "StreamEnabled": {
          "type": "boolean"
        },
        "StreamViewType": {}
      }
    },
    "S34": {
      "type": "structure",
      "members": {
        "Enabled": {
          "type": "boolean"
        },
        "SSEType": {},
        "KMSMasterKeyId": {}
      }
    },
    "S37": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key",
          "Value"
        ],
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "S3c": {
      "type": "structure",
      "members": {
        "AttributeDefinitions": {
          "shape": "S2k"
        },
        "TableName": {},
        "KeySchema": {
          "shape": "S2o"
        },
        "TableStatus": {},
        "CreationDateTime": {
          "type": "timestamp"
        },
        "ProvisionedThroughput": {
          "shape": "S3e"
        },
        "TableSizeBytes": {
          "type": "long"
        },
        "ItemCount": {
          "type": "long"
        },
        "TableArn": {},
        "TableId": {},
        "BillingModeSummary": {
          "shape": "S3i"
        },
        "LocalSecondaryIndexes": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "IndexName": {},
              "KeySchema": {
                "shape": "S2o"
              },
              "Projection": {
                "shape": "S2t"
              },
              "IndexSizeBytes": {
                "type": "long"
              },
              "ItemCount": {
                "type": "long"
              },
              "IndexArn": {}
            }
          }
        },
        "GlobalSecondaryIndexes": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "IndexName": {},
              "KeySchema": {
                "shape": "S2o"
              },
              "Projection": {
                "shape": "S2t"
              },
              "IndexStatus": {},
              "Backfilling": {
                "type": "boolean"
              },
              "ProvisionedThroughput": {
                "shape": "S3e"
              },
              "IndexSizeBytes": {
                "type": "long"
              },
              "ItemCount": {
                "type": "long"
              },
              "IndexArn": {}
            }
          }
        },
        "StreamSpecification": {
          "shape": "S31"
        },
        "LatestStreamLabel": {},
        "LatestStreamArn": {},
        "GlobalTableVersion": {},
        "Replicas": {
          "shape": "S25"
        },
        "RestoreSummary": {
          "type": "structure",
          "required": [
            "RestoreDateTime",
            "RestoreInProgress"
          ],
          "members": {
            "SourceBackupArn": {},
            "SourceTableArn": {},
            "RestoreDateTime": {
              "type": "timestamp"
            },
            "RestoreInProgress": {
              "type": "boolean"
            }
          }
        },
        "SSEDescription": {
          "shape": "S3t"
        },
        "ArchivalSummary": {
          "type": "structure",
          "members": {
            "ArchivalDateTime": {
              "type": "timestamp"
            },
            "ArchivalReason": {},
            "ArchivalBackupArn": {}
          }
        },
        "TableClassSummary": {
          "shape": "S2f"
        }
      }
    },
    "S3e": {
      "type": "structure",
      "members": {
        "LastIncreaseDateTime": {
          "type": "timestamp"
        },
        "LastDecreaseDateTime": {
          "type": "timestamp"
        },
        "NumberOfDecreasesToday": {
          "type": "long"
        },
        "ReadCapacityUnits": {
          "type": "long"
        },
        "WriteCapacityUnits": {
          "type": "long"
        }
      }
    },
    "S3i": {
      "type": "structure",
      "members": {
        "BillingMode": {},
        "LastUpdateToPayPerRequestDateTime": {
          "type": "timestamp"
        }
      }
    },
    "S3t": {
      "type": "structure",
      "members": {
        "Status": {},
        "SSEType": {},
        "KMSMasterKeyArn": {},
        "InaccessibleEncryptionDateTime": {
          "type": "timestamp"
        }
      }
    },
    "S40": {
      "type": "structure",
      "members": {
        "BackupDetails": {
          "shape": "S1s"
        },
        "SourceTableDetails": {
          "type": "structure",
          "required": [
            "TableName",
            "TableId",
            "KeySchema",
            "TableCreationDateTime",
            "ProvisionedThroughput"
          ],
          "members": {
            "TableName": {},
            "TableId": {},
            "TableArn": {},
            "TableSizeBytes": {
              "type": "long"
            },
            "KeySchema": {
              "shape": "S2o"
            },
            "TableCreationDateTime": {
              "type": "timestamp"
            },
            "ProvisionedThroughput": {
              "shape": "S2z"
            },
            "ItemCount": {
              "type": "long"
            },
            "BillingMode": {}
          }
        },
        "SourceTableFeatureDetails": {
          "type": "structure",
          "members": {
            "LocalSecondaryIndexes": {
              "type": "list",
              "member": {
                "type": "structure",
                "members": {
                  "IndexName": {},
                  "KeySchema": {
                    "shape": "S2o"
                  },
                  "Projection": {
                    "shape": "S2t"
                  }
                }
              }
            },
            "GlobalSecondaryIndexes": {
              "type": "list",
              "member": {
                "type": "structure",
                "members": {
                  "IndexName": {},
                  "KeySchema": {
                    "shape": "S2o"
                  },
                  "Projection": {
                    "shape": "S2t"
                  },
                  "ProvisionedThroughput": {
                    "shape": "S2z"
                  }
                }
              }
            },
            "StreamDescription": {
              "shape": "S31"
            },
            "TimeToLiveDescription": {
              "shape": "S49"
            },
            "SSEDescription": {
              "shape": "S3t"
            }
          }
        }
      }
    },
    "S49": {
      "type": "structure",
      "members": {
        "TimeToLiveStatus": {},
        "AttributeName": {}
      }
    },
    "S4d": {
      "type": "map",
      "key": {},
      "value": {
        "type": "structure",
        "members": {
          "Value": {
            "shape": "S6"
          },
          "Exists": {
            "type": "boolean"
          },
          "ComparisonOperator": {},
          "AttributeValueList": {
            "shape": "S4h"
          }
        }
      }
    },
    "S4h": {
      "type": "list",
      "member": {
        "shape": "S6"
      }
    },
    "S4l": {
      "type": "map",
      "key": {},
      "value": {
        "shape": "S6"
      }
    },
    "S4u": {
      "type": "structure",
      "required": [
        "ContinuousBackupsStatus"
      ],
      "members": {
        "ContinuousBackupsStatus": {},
        "PointInTimeRecoveryDescription": {
          "type": "structure",
          "members": {
            "PointInTimeRecoveryStatus": {},
            "EarliestRestorableDateTime": {
              "type": "timestamp"
            },
            "LatestRestorableDateTime": {
              "type": "timestamp"
            }
          }
        }
      }
    },
    "S5e": {
      "type": "structure",
      "members": {
        "ExportArn": {},
        "ExportStatus": {},
        "StartTime": {
          "type": "timestamp"
        },
        "EndTime": {
          "type": "timestamp"
        },
        "ExportManifest": {},
        "TableArn": {},
        "TableId": {},
        "ExportTime": {
          "type": "timestamp"
        },
        "ClientToken": {},
        "S3Bucket": {},
        "S3BucketOwner": {},
        "S3Prefix": {},
        "S3SseAlgorithm": {},
        "S3SseKmsKeyId": {},
        "FailureCode": {},
        "FailureMessage": {},
        "ExportFormat": {},
        "BilledSizeBytes": {
          "type": "long"
        },
        "ItemCount": {
          "type": "long"
        }
      }
    },
    "S5y": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "RegionName"
        ],
        "members": {
          "RegionName": {},
          "ReplicaStatus": {},
          "ReplicaBillingModeSummary": {
            "shape": "S3i"
          },
          "ReplicaProvisionedReadCapacityUnits": {
            "type": "long"
          },
          "ReplicaProvisionedReadCapacityAutoScalingSettings": {
            "shape": "S60"
          },
          "ReplicaProvisionedWriteCapacityUnits": {
            "type": "long"
          },
          "ReplicaProvisionedWriteCapacityAutoScalingSettings": {
            "shape": "S60"
          },
          "ReplicaGlobalSecondaryIndexSettings": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "IndexName"
              ],
              "members": {
                "IndexName": {},
                "IndexStatus": {},
                "ProvisionedReadCapacityUnits": {
                  "type": "long"
                },
                "ProvisionedReadCapacityAutoScalingSettings": {
                  "shape": "S60"
                },
                "ProvisionedWriteCapacityUnits": {
                  "type": "long"
                },
                "ProvisionedWriteCapacityAutoScalingSettings": {
                  "shape": "S60"
                }
              }
            }
          },
          "ReplicaTableClassSummary": {
            "shape": "S2f"
          }
        }
      }
    },
    "S60": {
      "type": "structure",
      "members": {
        "MinimumUnits": {
          "type": "long"
        },
        "MaximumUnits": {
          "type": "long"
        },
        "AutoScalingDisabled": {
          "type": "boolean"
        },
        "AutoScalingRoleArn": {},
        "ScalingPolicies": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "PolicyName": {},
              "TargetTrackingScalingPolicyConfiguration": {
                "type": "structure",
                "required": [
                  "TargetValue"
                ],
                "members": {
                  "DisableScaleIn": {
                    "type": "boolean"
                  },
                  "ScaleInCooldown": {
                    "type": "integer"
                  },
                  "ScaleOutCooldown": {
                    "type": "integer"
                  },
                  "TargetValue": {
                    "type": "double"
                  }
                }
              }
            }
          }
        }
      }
    },
    "S6k": {
      "type": "structure",
      "members": {
        "TableName": {},
        "TableStatus": {},
        "Replicas": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "RegionName": {},
              "GlobalSecondaryIndexes": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "IndexName": {},
                    "IndexStatus": {},
                    "ProvisionedReadCapacityAutoScalingSettings": {
                      "shape": "S60"
                    },
                    "ProvisionedWriteCapacityAutoScalingSettings": {
                      "shape": "S60"
                    }
                  }
                }
              },
              "ReplicaProvisionedReadCapacityAutoScalingSettings": {
                "shape": "S60"
              },
              "ReplicaProvisionedWriteCapacityAutoScalingSettings": {
                "shape": "S60"
              },
              "ReplicaStatus": {}
            }
          }
        }
      }
    },
    "S6r": {
      "type": "structure",
      "required": [
        "TableName",
        "StreamArn"
      ],
      "members": {
        "TableName": {},
        "StreamArn": {}
      }
    },
    "S6s": {
      "type": "structure",
      "members": {
        "TableName": {},
        "StreamArn": {},
        "DestinationStatus": {}
      }
    },
    "S72": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Item": {
            "shape": "Sr"
          }
        }
      }
    },
    "S88": {
      "type": "structure",
      "required": [
        "ComparisonOperator"
      ],
      "members": {
        "AttributeValueList": {
          "shape": "S4h"
        },
        "ComparisonOperator": {}
      }
    },
    "S89": {
      "type": "map",
      "key": {},
      "value": {
        "shape": "S88"
      }
    },
    "S9g": {
      "type": "structure",
      "members": {
        "MinimumUnits": {
          "type": "long"
        },
        "MaximumUnits": {
          "type": "long"
        },
        "AutoScalingDisabled": {
          "type": "boolean"
        },
        "AutoScalingRoleArn": {},
        "ScalingPolicyUpdate": {
          "type": "structure",
          "required": [
            "TargetTrackingScalingPolicyConfiguration"
          ],
          "members": {
            "PolicyName": {},
            "TargetTrackingScalingPolicyConfiguration": {
              "type": "structure",
              "required": [
                "TargetValue"
              ],
              "members": {
                "DisableScaleIn": {
                  "type": "boolean"
                },
                "ScaleInCooldown": {
                  "type": "integer"
                },
                "ScaleOutCooldown": {
                  "type": "integer"
                },
                "TargetValue": {
                  "type": "double"
                }
              }
            }
          }
        }
      }
    },
    "Sa5": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "IndexName"
        ],
        "members": {
          "IndexName": {},
          "ProvisionedThroughputOverride": {
            "shape": "S2b"
          }
        }
      }
    },
    "Saj": {
      "type": "structure",
      "required": [
        "Enabled",
        "AttributeName"
      ],
      "members": {
        "Enabled": {
          "type": "boolean"
        },
        "AttributeName": {}
      }
    }
  }
}