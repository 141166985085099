"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.NTACorporateInfoProcess = exports.NTACorporateInfoKind = exports.NTACorporateInfoCloseCause = exports.KENALL = void 0;
var v1_1 = require("./v1");
Object.defineProperty(exports, "KENALL", {
  enumerable: true,
  get: function () {
    return v1_1.KENALL;
  }
});
Object.defineProperty(exports, "NTACorporateInfoCloseCause", {
  enumerable: true,
  get: function () {
    return v1_1.NTACorporateInfoCloseCause;
  }
});
Object.defineProperty(exports, "NTACorporateInfoKind", {
  enumerable: true,
  get: function () {
    return v1_1.NTACorporateInfoKind;
  }
});
Object.defineProperty(exports, "NTACorporateInfoProcess", {
  enumerable: true,
  get: function () {
    return v1_1.NTACorporateInfoProcess;
  }
});
