{
  "pagination": {
    "DescribeAggregateComplianceByConfigRules": {
      "input_token": "NextToken",
      "limit_key": "Limit",
      "output_token": "NextToken"
    },
    "DescribeAggregateComplianceByConformancePacks": {
      "input_token": "NextToken",
      "limit_key": "Limit",
      "output_token": "NextToken",
      "result_key": "AggregateComplianceByConformancePacks"
    },
    "DescribeAggregationAuthorizations": {
      "input_token": "NextToken",
      "limit_key": "Limit",
      "output_token": "NextToken",
      "result_key": "AggregationAuthorizations"
    },
    "DescribeComplianceByConfigRule": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "result_key": "ComplianceByConfigRules"
    },
    "DescribeComplianceByResource": {
      "input_token": "NextToken",
      "limit_key": "Limit",
      "output_token": "NextToken",
      "result_key": "ComplianceByResources"
    },
    "DescribeConfigRuleEvaluationStatus": {
      "input_token": "NextToken",
      "limit_key": "Limit",
      "output_token": "NextToken",
      "result_key": "ConfigRulesEvaluationStatus"
    },
    "DescribeConfigRules": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "result_key": "ConfigRules"
    },
    "DescribeConfigurationAggregatorSourcesStatus": {
      "input_token": "NextToken",
      "limit_key": "Limit",
      "output_token": "NextToken",
      "result_key": "AggregatedSourceStatusList"
    },
    "DescribeConfigurationAggregators": {
      "input_token": "NextToken",
      "limit_key": "Limit",
      "output_token": "NextToken",
      "result_key": "ConfigurationAggregators"
    },
    "DescribeConformancePackCompliance": {
      "input_token": "NextToken",
      "limit_key": "Limit",
      "output_token": "NextToken"
    },
    "DescribeConformancePackStatus": {
      "input_token": "NextToken",
      "limit_key": "Limit",
      "output_token": "NextToken",
      "result_key": "ConformancePackStatusDetails"
    },
    "DescribeConformancePacks": {
      "input_token": "NextToken",
      "limit_key": "Limit",
      "output_token": "NextToken",
      "result_key": "ConformancePackDetails"
    },
    "DescribeOrganizationConfigRuleStatuses": {
      "input_token": "NextToken",
      "limit_key": "Limit",
      "output_token": "NextToken",
      "result_key": "OrganizationConfigRuleStatuses"
    },
    "DescribeOrganizationConfigRules": {
      "input_token": "NextToken",
      "limit_key": "Limit",
      "output_token": "NextToken",
      "result_key": "OrganizationConfigRules"
    },
    "DescribeOrganizationConformancePackStatuses": {
      "input_token": "NextToken",
      "limit_key": "Limit",
      "output_token": "NextToken",
      "result_key": "OrganizationConformancePackStatuses"
    },
    "DescribeOrganizationConformancePacks": {
      "input_token": "NextToken",
      "limit_key": "Limit",
      "output_token": "NextToken",
      "result_key": "OrganizationConformancePacks"
    },
    "DescribePendingAggregationRequests": {
      "input_token": "NextToken",
      "limit_key": "Limit",
      "output_token": "NextToken",
      "result_key": "PendingAggregationRequests"
    },
    "DescribeRemediationExceptions": {
      "input_token": "NextToken",
      "limit_key": "Limit",
      "output_token": "NextToken"
    },
    "DescribeRemediationExecutionStatus": {
      "input_token": "NextToken",
      "limit_key": "Limit",
      "output_token": "NextToken",
      "result_key": "RemediationExecutionStatuses"
    },
    "DescribeRetentionConfigurations": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "result_key": "RetentionConfigurations"
    },
    "GetAggregateComplianceDetailsByConfigRule": {
      "input_token": "NextToken",
      "limit_key": "Limit",
      "output_token": "NextToken",
      "result_key": "AggregateEvaluationResults"
    },
    "GetAggregateConfigRuleComplianceSummary": {
      "input_token": "NextToken",
      "limit_key": "Limit",
      "output_token": "NextToken"
    },
    "GetAggregateConformancePackComplianceSummary": {
      "input_token": "NextToken",
      "limit_key": "Limit",
      "output_token": "NextToken"
    },
    "GetAggregateDiscoveredResourceCounts": {
      "input_token": "NextToken",
      "limit_key": "Limit",
      "output_token": "NextToken"
    },
    "GetComplianceDetailsByConfigRule": {
      "input_token": "NextToken",
      "limit_key": "Limit",
      "output_token": "NextToken",
      "result_key": "EvaluationResults"
    },
    "GetComplianceDetailsByResource": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "result_key": "EvaluationResults"
    },
    "GetConformancePackComplianceDetails": {
      "input_token": "NextToken",
      "limit_key": "Limit",
      "output_token": "NextToken"
    },
    "GetConformancePackComplianceSummary": {
      "input_token": "NextToken",
      "limit_key": "Limit",
      "output_token": "NextToken",
      "result_key": "ConformancePackComplianceSummaryList"
    },
    "GetDiscoveredResourceCounts": {
      "input_token": "nextToken",
      "limit_key": "limit",
      "output_token": "nextToken"
    },
    "GetOrganizationConfigRuleDetailedStatus": {
      "input_token": "NextToken",
      "limit_key": "Limit",
      "output_token": "NextToken",
      "result_key": "OrganizationConfigRuleDetailedStatus"
    },
    "GetOrganizationConformancePackDetailedStatus": {
      "input_token": "NextToken",
      "limit_key": "Limit",
      "output_token": "NextToken",
      "result_key": "OrganizationConformancePackDetailedStatuses"
    },
    "GetResourceConfigHistory": {
      "input_token": "nextToken",
      "limit_key": "limit",
      "output_token": "nextToken",
      "result_key": "configurationItems"
    },
    "ListAggregateDiscoveredResources": {
      "input_token": "NextToken",
      "limit_key": "Limit",
      "output_token": "NextToken",
      "result_key": "ResourceIdentifiers"
    },
    "ListDiscoveredResources": {
      "input_token": "nextToken",
      "limit_key": "limit",
      "output_token": "nextToken",
      "result_key": "resourceIdentifiers"
    },
    "ListStoredQueries": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken"
    },
    "ListTagsForResource": {
      "input_token": "NextToken",
      "limit_key": "Limit",
      "output_token": "NextToken",
      "result_key": "Tags"
    },
    "SelectAggregateResourceConfig": {
      "input_token": "NextToken",
      "limit_key": "Limit",
      "non_aggregate_keys": [
        "QueryInfo"
      ],
      "output_token": "NextToken",
      "result_key": "Results"
    },
    "SelectResourceConfig": {
      "input_token": "NextToken",
      "limit_key": "Limit",
      "non_aggregate_keys": [
        "QueryInfo"
      ],
      "output_token": "NextToken",
      "result_key": "Results"
    }
  }
}