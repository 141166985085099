"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _axios = _interopRequireDefault(require("axios"));
var _axiosRetry = _interopRequireDefault(require("axios-retry"));
var _utils = _interopRequireDefault(require("./utils"));
function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);
  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    });
    keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};
    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        (0, _defineProperty2["default"])(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }
  return target;
}
var simpleHttpClientFactory = {};
simpleHttpClientFactory.newClient = function (config) {
  function buildCanonicalQueryString(queryParams) {
    // Build a properly encoded query string from a QueryParam object
    if (Object.keys(queryParams).length < 1) {
      return '';
    }
    var canonicalQueryString = '';
    for (var property in queryParams) {
      if (Object.prototype.hasOwnProperty.call(queryParams, property)) {
        canonicalQueryString += encodeURIComponent(property) + '=' + encodeURIComponent(queryParams[property]) + '&';
      }
    }
    return canonicalQueryString.substr(0, canonicalQueryString.length - 1);
  }
  var simpleHttpClient = {};
  simpleHttpClient.endpoint = _utils["default"].assertDefined(config.endpoint, 'endpoint');
  simpleHttpClient.makeRequest = function (request) {
    var verb = _utils["default"].assertDefined(request.verb, 'verb');
    var path = _utils["default"].assertDefined(request.path, 'path');
    var queryParams = _utils["default"].copy(request.queryParams);
    var timeout = _utils["default"].copy(request.timeout);
    if (queryParams === undefined) {
      queryParams = {};
    }
    if (timeout === undefined) {
      timeout = 0;
    }
    var headers = _objectSpread(_objectSpread({}, _utils["default"].copy(request.headers)), config.headers); // If the user has not specified an override for Content type the use default

    if (headers['Content-Type'] === undefined) {
      headers['Content-Type'] = config.defaultContentType;
    } // If the user has not specified an override for Accept type the use default

    if (headers['Accept'] === undefined) {
      headers['Accept'] = config.defaultAcceptType;
    }
    var body = _utils["default"].copy(request.body);
    var url = config.endpoint + path;
    var queryString = buildCanonicalQueryString(queryParams);
    if (queryString !== '') {
      url += '?' + queryString;
    }
    var simpleHttpRequest = {
      headers: headers,
      timeout: timeout,
      data: body,
      method: verb,
      url: url
    };
    if (config.retries !== undefined) {
      simpleHttpRequest.baseURL = url;
      var client = _axios["default"].create(simpleHttpRequest); // Allow user configurable delay, or built-in exponential delay

      var retryDelay = function retryDelay() {
        return 0;
      };
      if (config.retryDelay === 'exponential') {
        retryDelay = _axiosRetry["default"].exponentialDelay;
      } else if (typeof config.retryDelay === 'number') {
        retryDelay = function retryDelay() {
          return parseInt(config.retryDelay);
        };
      } else if (typeof config.retryDelay === 'function') {
        retryDelay = config.retryDelay;
      }
      (0, _axiosRetry["default"])(client, _objectSpread(_objectSpread({}, config), {}, {
        retryCondition: typeof config.retryCondition === 'function' ? config.retryCondition : _axiosRetry["default"].isNetworkOrIdempotentRequestError,
        retryDelay: retryDelay
      }));
      return client.request(simpleHttpRequest);
    }
    return (0, _axios["default"])(simpleHttpRequest);
  };
  return simpleHttpClient;
};
var _default = simpleHttpClientFactory;
exports["default"] = _default;
