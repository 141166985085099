"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.NTACorporateInfoCloseCause = exports.NTACorporateInfoKind = exports.NTACorporateInfoProcess = void 0;
/**
 * A `NTACorporateInfoProcess` represents the reason why the record
 * was added.
 */
var NTACorporateInfoProcess;
(function (NTACorporateInfoProcess) {
  /**
   * Indicates new record.
   */
  NTACorporateInfoProcess["NEW"] = "1";
  /**
   * Indicates trade name or corporate name was changed.
   */
  NTACorporateInfoProcess["NAME_CHANGED"] = "11";
  /**
   * Indicates domestic address was changed.
   */
  NTACorporateInfoProcess["DOMESTIC_ADDRESS_CHANGED"] = "12";
  /**
   * Indicates overseas address was changed.
   */
  NTACorporateInfoProcess["OVERSEAS_ADDRESS_CHANGED"] = "13";
  /**
   * Indicates registration record was closed.
   */
  NTACorporateInfoProcess["REGISTRATION_RECORD_CLOSED"] = "21";
  /**
   * Indicates registration record was reopened.
   */
  NTACorporateInfoProcess["REGISTRATION_RECORD_REOPENED"] = "22";
  /**
   * Indicates absorption merger completed.
   */
  NTACorporateInfoProcess["ABSORPTION_MERGER_COMPLETED"] = "71";
  /**
   * Indicates absorption merger was invalidated.
   */
  NTACorporateInfoProcess["ABSORPTION_MERGER_INVALIDATED"] = "72";
  /**
   * Indicates registration record was erased.
   */
  NTACorporateInfoProcess["REGISTRATION_RECORD_ERASED"] = "81";
  /**
   * Indicates the record was deleted.
   */
  NTACorporateInfoProcess["DELETED"] = "99";
})(NTACorporateInfoProcess = exports.NTACorporateInfoProcess || (exports.NTACorporateInfoProcess = {}));
/**
 * A `NTACorporateInfoKind` represents the kind of corporate.
 */
var NTACorporateInfoKind;
(function (NTACorporateInfoKind) {
  /**
   * National public entity
   */
  NTACorporateInfoKind["NATIONAL_PUBLIC_ENTITY"] = "101";
  /**
   * Local public entity
   */
  NTACorporateInfoKind["LOCAL_PUBLIC_ENTITY"] = "201";
  /**
   * Limited liability company by share, aka K.K.
   */
  NTACorporateInfoKind["KABUSHIKI_KAISHA"] = "301";
  /**
   * Old style of limited liability company not by share
   */
  NTACorporateInfoKind["YUGEN_KAISHA"] = "302";
  /**
   * General partnership company
   */
  NTACorporateInfoKind["GOMEI_KAISHA"] = "303";
  /**
   * Limited partnership company
   */
  NTACorporateInfoKind["GOSHI_KAISHA"] = "304";
  /**
   * Limited liability company, aka G.K.
   */
  NTACorporateInfoKind["GODO_KAISHA"] = "305";
  /**
   * Other types of company
   */
  NTACorporateInfoKind["OTHER_COMPANY"] = "399";
  /**
   * Foreign company
   */
  NTACorporateInfoKind["FOREIGN_COMPANY"] = "401";
  /**
   * Other corporate kinds which do not belong to the above
   */
  NTACorporateInfoKind["OTHER"] = "499";
})(NTACorporateInfoKind = exports.NTACorporateInfoKind || (exports.NTACorporateInfoKind = {}));
/**
 * A `NTACorporateInfoCloseCause` represents the reason why the corporate
 * was closed.
 */
var NTACorporateInfoCloseCause;
(function (NTACorporateInfoCloseCause) {
  /**
   *  Liquidation completed
   */
  NTACorporateInfoCloseCause["LIQUIDATION_COMPLETED"] = "1";
  /**
   *  Dissolution by merger
   */
  NTACorporateInfoCloseCause["DISSOLUTION_BY_MERGER"] = "11";
  /**
   *  A registerer closed the corporate
   */
  NTACorporateInfoCloseCause["CLOSED_BY_REGISTERER"] = "21";
  /**
   *  Other reasons
   */
  NTACorporateInfoCloseCause["OTHER"] = "31";
})(NTACorporateInfoCloseCause = exports.NTACorporateInfoCloseCause || (exports.NTACorporateInfoCloseCause = {}));
