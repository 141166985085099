{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2015-05-28",
    "endpointPrefix": "iot",
    "protocol": "rest-json",
    "serviceFullName": "AWS IoT",
    "serviceId": "IoT",
    "signatureVersion": "v4",
    "signingName": "execute-api",
    "uid": "iot-2015-05-28"
  },
  "operations": {
    "AcceptCertificateTransfer": {
      "http": {
        "method": "PATCH",
        "requestUri": "/accept-certificate-transfer/{certificateId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "certificateId"
        ],
        "members": {
          "certificateId": {
            "location": "uri",
            "locationName": "certificateId"
          },
          "setAsActive": {
            "location": "querystring",
            "locationName": "setAsActive",
            "type": "boolean"
          }
        }
      }
    },
    "AddThingToBillingGroup": {
      "http": {
        "method": "PUT",
        "requestUri": "/billing-groups/addThingToBillingGroup"
      },
      "input": {
        "type": "structure",
        "members": {
          "billingGroupName": {},
          "billingGroupArn": {},
          "thingName": {},
          "thingArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "AddThingToThingGroup": {
      "http": {
        "method": "PUT",
        "requestUri": "/thing-groups/addThingToThingGroup"
      },
      "input": {
        "type": "structure",
        "members": {
          "thingGroupName": {},
          "thingGroupArn": {},
          "thingName": {},
          "thingArn": {},
          "overrideDynamicGroups": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "AssociateTargetsWithJob": {
      "http": {
        "requestUri": "/jobs/{jobId}/targets"
      },
      "input": {
        "type": "structure",
        "required": [
          "targets",
          "jobId"
        ],
        "members": {
          "targets": {
            "shape": "Sg"
          },
          "jobId": {
            "location": "uri",
            "locationName": "jobId"
          },
          "comment": {},
          "namespaceId": {
            "location": "querystring",
            "locationName": "namespaceId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "jobArn": {},
          "jobId": {},
          "description": {}
        }
      }
    },
    "AttachPolicy": {
      "http": {
        "method": "PUT",
        "requestUri": "/target-policies/{policyName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "policyName",
          "target"
        ],
        "members": {
          "policyName": {
            "location": "uri",
            "locationName": "policyName"
          },
          "target": {}
        }
      }
    },
    "AttachPrincipalPolicy": {
      "http": {
        "method": "PUT",
        "requestUri": "/principal-policies/{policyName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "policyName",
          "principal"
        ],
        "members": {
          "policyName": {
            "location": "uri",
            "locationName": "policyName"
          },
          "principal": {
            "location": "header",
            "locationName": "x-amzn-iot-principal"
          }
        }
      },
      "deprecated": true
    },
    "AttachSecurityProfile": {
      "http": {
        "method": "PUT",
        "requestUri": "/security-profiles/{securityProfileName}/targets"
      },
      "input": {
        "type": "structure",
        "required": [
          "securityProfileName",
          "securityProfileTargetArn"
        ],
        "members": {
          "securityProfileName": {
            "location": "uri",
            "locationName": "securityProfileName"
          },
          "securityProfileTargetArn": {
            "location": "querystring",
            "locationName": "securityProfileTargetArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "AttachThingPrincipal": {
      "http": {
        "method": "PUT",
        "requestUri": "/things/{thingName}/principals"
      },
      "input": {
        "type": "structure",
        "required": [
          "thingName",
          "principal"
        ],
        "members": {
          "thingName": {
            "location": "uri",
            "locationName": "thingName"
          },
          "principal": {
            "location": "header",
            "locationName": "x-amzn-principal"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "CancelAuditMitigationActionsTask": {
      "http": {
        "method": "PUT",
        "requestUri": "/audit/mitigationactions/tasks/{taskId}/cancel"
      },
      "input": {
        "type": "structure",
        "required": [
          "taskId"
        ],
        "members": {
          "taskId": {
            "location": "uri",
            "locationName": "taskId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "CancelAuditTask": {
      "http": {
        "method": "PUT",
        "requestUri": "/audit/tasks/{taskId}/cancel"
      },
      "input": {
        "type": "structure",
        "required": [
          "taskId"
        ],
        "members": {
          "taskId": {
            "location": "uri",
            "locationName": "taskId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "CancelCertificateTransfer": {
      "http": {
        "method": "PATCH",
        "requestUri": "/cancel-certificate-transfer/{certificateId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "certificateId"
        ],
        "members": {
          "certificateId": {
            "location": "uri",
            "locationName": "certificateId"
          }
        }
      }
    },
    "CancelDetectMitigationActionsTask": {
      "http": {
        "method": "PUT",
        "requestUri": "/detect/mitigationactions/tasks/{taskId}/cancel"
      },
      "input": {
        "type": "structure",
        "required": [
          "taskId"
        ],
        "members": {
          "taskId": {
            "location": "uri",
            "locationName": "taskId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "CancelJob": {
      "http": {
        "method": "PUT",
        "requestUri": "/jobs/{jobId}/cancel"
      },
      "input": {
        "type": "structure",
        "required": [
          "jobId"
        ],
        "members": {
          "jobId": {
            "location": "uri",
            "locationName": "jobId"
          },
          "reasonCode": {},
          "comment": {},
          "force": {
            "location": "querystring",
            "locationName": "force",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "jobArn": {},
          "jobId": {},
          "description": {}
        }
      }
    },
    "CancelJobExecution": {
      "http": {
        "method": "PUT",
        "requestUri": "/things/{thingName}/jobs/{jobId}/cancel"
      },
      "input": {
        "type": "structure",
        "required": [
          "jobId",
          "thingName"
        ],
        "members": {
          "jobId": {
            "location": "uri",
            "locationName": "jobId"
          },
          "thingName": {
            "location": "uri",
            "locationName": "thingName"
          },
          "force": {
            "location": "querystring",
            "locationName": "force",
            "type": "boolean"
          },
          "expectedVersion": {
            "type": "long"
          },
          "statusDetails": {
            "shape": "S1e"
          }
        }
      }
    },
    "ClearDefaultAuthorizer": {
      "http": {
        "method": "DELETE",
        "requestUri": "/default-authorizer"
      },
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "ConfirmTopicRuleDestination": {
      "http": {
        "method": "GET",
        "requestUri": "/confirmdestination/{confirmationToken+}"
      },
      "input": {
        "type": "structure",
        "required": [
          "confirmationToken"
        ],
        "members": {
          "confirmationToken": {
            "location": "uri",
            "locationName": "confirmationToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "CreateAuditSuppression": {
      "http": {
        "requestUri": "/audit/suppressions/create"
      },
      "input": {
        "type": "structure",
        "required": [
          "checkName",
          "resourceIdentifier",
          "clientRequestToken"
        ],
        "members": {
          "checkName": {},
          "resourceIdentifier": {
            "shape": "S1o"
          },
          "expirationDate": {
            "type": "timestamp"
          },
          "suppressIndefinitely": {
            "type": "boolean"
          },
          "description": {},
          "clientRequestToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "CreateAuthorizer": {
      "http": {
        "requestUri": "/authorizer/{authorizerName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "authorizerName",
          "authorizerFunctionArn"
        ],
        "members": {
          "authorizerName": {
            "location": "uri",
            "locationName": "authorizerName"
          },
          "authorizerFunctionArn": {},
          "tokenKeyName": {},
          "tokenSigningPublicKeys": {
            "shape": "S25"
          },
          "status": {},
          "tags": {
            "shape": "S29"
          },
          "signingDisabled": {
            "type": "boolean"
          },
          "enableCachingForHttp": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "authorizerName": {},
          "authorizerArn": {}
        }
      }
    },
    "CreateBillingGroup": {
      "http": {
        "requestUri": "/billing-groups/{billingGroupName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "billingGroupName"
        ],
        "members": {
          "billingGroupName": {
            "location": "uri",
            "locationName": "billingGroupName"
          },
          "billingGroupProperties": {
            "shape": "S2i"
          },
          "tags": {
            "shape": "S29"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "billingGroupName": {},
          "billingGroupArn": {},
          "billingGroupId": {}
        }
      }
    },
    "CreateCertificateFromCsr": {
      "http": {
        "requestUri": "/certificates"
      },
      "input": {
        "type": "structure",
        "required": [
          "certificateSigningRequest"
        ],
        "members": {
          "certificateSigningRequest": {},
          "setAsActive": {
            "location": "querystring",
            "locationName": "setAsActive",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "certificateArn": {},
          "certificateId": {},
          "certificatePem": {}
        }
      }
    },
    "CreateCustomMetric": {
      "http": {
        "requestUri": "/custom-metric/{metricName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "metricName",
          "metricType",
          "clientRequestToken"
        ],
        "members": {
          "metricName": {
            "location": "uri",
            "locationName": "metricName"
          },
          "displayName": {},
          "metricType": {},
          "tags": {
            "shape": "S29"
          },
          "clientRequestToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "metricName": {},
          "metricArn": {}
        }
      }
    },
    "CreateDimension": {
      "http": {
        "requestUri": "/dimensions/{name}"
      },
      "input": {
        "type": "structure",
        "required": [
          "name",
          "type",
          "stringValues",
          "clientRequestToken"
        ],
        "members": {
          "name": {
            "location": "uri",
            "locationName": "name"
          },
          "type": {},
          "stringValues": {
            "shape": "S30"
          },
          "tags": {
            "shape": "S29"
          },
          "clientRequestToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "name": {},
          "arn": {}
        }
      }
    },
    "CreateDomainConfiguration": {
      "http": {
        "requestUri": "/domainConfigurations/{domainConfigurationName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "domainConfigurationName"
        ],
        "members": {
          "domainConfigurationName": {
            "location": "uri",
            "locationName": "domainConfigurationName"
          },
          "domainName": {},
          "serverCertificateArns": {
            "type": "list",
            "member": {}
          },
          "validationCertificateArn": {},
          "authorizerConfig": {
            "shape": "S39"
          },
          "serviceType": {},
          "tags": {
            "shape": "S29"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "domainConfigurationName": {},
          "domainConfigurationArn": {}
        }
      }
    },
    "CreateDynamicThingGroup": {
      "http": {
        "requestUri": "/dynamic-thing-groups/{thingGroupName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "thingGroupName",
          "queryString"
        ],
        "members": {
          "thingGroupName": {
            "location": "uri",
            "locationName": "thingGroupName"
          },
          "thingGroupProperties": {
            "shape": "S3f"
          },
          "indexName": {},
          "queryString": {},
          "queryVersion": {},
          "tags": {
            "shape": "S29"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "thingGroupName": {},
          "thingGroupArn": {},
          "thingGroupId": {},
          "indexName": {},
          "queryString": {},
          "queryVersion": {}
        }
      }
    },
    "CreateFleetMetric": {
      "http": {
        "method": "PUT",
        "requestUri": "/fleet-metric/{metricName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "metricName",
          "queryString",
          "aggregationType",
          "period",
          "aggregationField"
        ],
        "members": {
          "metricName": {
            "location": "uri",
            "locationName": "metricName"
          },
          "queryString": {},
          "aggregationType": {
            "shape": "S3t"
          },
          "period": {
            "type": "integer"
          },
          "aggregationField": {},
          "description": {},
          "queryVersion": {},
          "indexName": {},
          "unit": {},
          "tags": {
            "shape": "S29"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "metricName": {},
          "metricArn": {}
        }
      }
    },
    "CreateJob": {
      "http": {
        "method": "PUT",
        "requestUri": "/jobs/{jobId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "jobId",
          "targets"
        ],
        "members": {
          "jobId": {
            "location": "uri",
            "locationName": "jobId"
          },
          "targets": {
            "shape": "Sg"
          },
          "documentSource": {},
          "document": {},
          "description": {},
          "presignedUrlConfig": {
            "shape": "S46"
          },
          "targetSelection": {},
          "jobExecutionsRolloutConfig": {
            "shape": "S49"
          },
          "abortConfig": {
            "shape": "S4g"
          },
          "timeoutConfig": {
            "shape": "S4n"
          },
          "tags": {
            "shape": "S29"
          },
          "namespaceId": {},
          "jobTemplateArn": {},
          "jobExecutionsRetryConfig": {
            "shape": "S4q"
          },
          "documentParameters": {
            "shape": "S4v"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "jobArn": {},
          "jobId": {},
          "description": {}
        }
      }
    },
    "CreateJobTemplate": {
      "http": {
        "method": "PUT",
        "requestUri": "/job-templates/{jobTemplateId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "jobTemplateId",
          "description"
        ],
        "members": {
          "jobTemplateId": {
            "location": "uri",
            "locationName": "jobTemplateId"
          },
          "jobArn": {},
          "documentSource": {},
          "document": {},
          "description": {},
          "presignedUrlConfig": {
            "shape": "S46"
          },
          "jobExecutionsRolloutConfig": {
            "shape": "S49"
          },
          "abortConfig": {
            "shape": "S4g"
          },
          "timeoutConfig": {
            "shape": "S4n"
          },
          "tags": {
            "shape": "S29"
          },
          "jobExecutionsRetryConfig": {
            "shape": "S4q"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "jobTemplateArn": {},
          "jobTemplateId": {}
        }
      }
    },
    "CreateKeysAndCertificate": {
      "http": {
        "requestUri": "/keys-and-certificate"
      },
      "input": {
        "type": "structure",
        "members": {
          "setAsActive": {
            "location": "querystring",
            "locationName": "setAsActive",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "certificateArn": {},
          "certificateId": {},
          "certificatePem": {},
          "keyPair": {
            "shape": "S54"
          }
        }
      }
    },
    "CreateMitigationAction": {
      "http": {
        "requestUri": "/mitigationactions/actions/{actionName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "actionName",
          "roleArn",
          "actionParams"
        ],
        "members": {
          "actionName": {
            "location": "uri",
            "locationName": "actionName"
          },
          "roleArn": {},
          "actionParams": {
            "shape": "S59"
          },
          "tags": {
            "shape": "S29"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "actionArn": {},
          "actionId": {}
        }
      }
    },
    "CreateOTAUpdate": {
      "http": {
        "requestUri": "/otaUpdates/{otaUpdateId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "otaUpdateId",
          "targets",
          "files",
          "roleArn"
        ],
        "members": {
          "otaUpdateId": {
            "location": "uri",
            "locationName": "otaUpdateId"
          },
          "description": {},
          "targets": {
            "shape": "S5t"
          },
          "protocols": {
            "shape": "S5v"
          },
          "targetSelection": {},
          "awsJobExecutionsRolloutConfig": {
            "shape": "S5x"
          },
          "awsJobPresignedUrlConfig": {
            "shape": "S64"
          },
          "awsJobAbortConfig": {
            "type": "structure",
            "required": [
              "abortCriteriaList"
            ],
            "members": {
              "abortCriteriaList": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "required": [
                    "failureType",
                    "action",
                    "thresholdPercentage",
                    "minNumberOfExecutedThings"
                  ],
                  "members": {
                    "failureType": {},
                    "action": {},
                    "thresholdPercentage": {
                      "type": "double"
                    },
                    "minNumberOfExecutedThings": {
                      "type": "integer"
                    }
                  }
                }
              }
            }
          },
          "awsJobTimeoutConfig": {
            "type": "structure",
            "members": {
              "inProgressTimeoutInMinutes": {
                "type": "long"
              }
            }
          },
          "files": {
            "shape": "S6f"
          },
          "roleArn": {},
          "additionalParameters": {
            "shape": "S7d"
          },
          "tags": {
            "shape": "S29"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "otaUpdateId": {},
          "awsIotJobId": {},
          "otaUpdateArn": {},
          "awsIotJobArn": {},
          "otaUpdateStatus": {}
        }
      }
    },
    "CreatePolicy": {
      "http": {
        "requestUri": "/policies/{policyName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "policyName",
          "policyDocument"
        ],
        "members": {
          "policyName": {
            "location": "uri",
            "locationName": "policyName"
          },
          "policyDocument": {},
          "tags": {
            "shape": "S29"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "policyName": {},
          "policyArn": {},
          "policyDocument": {},
          "policyVersionId": {}
        }
      }
    },
    "CreatePolicyVersion": {
      "http": {
        "requestUri": "/policies/{policyName}/version"
      },
      "input": {
        "type": "structure",
        "required": [
          "policyName",
          "policyDocument"
        ],
        "members": {
          "policyName": {
            "location": "uri",
            "locationName": "policyName"
          },
          "policyDocument": {},
          "setAsDefault": {
            "location": "querystring",
            "locationName": "setAsDefault",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "policyArn": {},
          "policyDocument": {},
          "policyVersionId": {},
          "isDefaultVersion": {
            "type": "boolean"
          }
        }
      }
    },
    "CreateProvisioningClaim": {
      "http": {
        "requestUri": "/provisioning-templates/{templateName}/provisioning-claim"
      },
      "input": {
        "type": "structure",
        "required": [
          "templateName"
        ],
        "members": {
          "templateName": {
            "location": "uri",
            "locationName": "templateName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "certificateId": {},
          "certificatePem": {},
          "keyPair": {
            "shape": "S54"
          },
          "expiration": {
            "type": "timestamp"
          }
        }
      }
    },
    "CreateProvisioningTemplate": {
      "http": {
        "requestUri": "/provisioning-templates"
      },
      "input": {
        "type": "structure",
        "required": [
          "templateName",
          "templateBody",
          "provisioningRoleArn"
        ],
        "members": {
          "templateName": {},
          "description": {},
          "templateBody": {},
          "enabled": {
            "type": "boolean"
          },
          "provisioningRoleArn": {},
          "preProvisioningHook": {
            "shape": "S7z"
          },
          "tags": {
            "shape": "S29"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "templateArn": {},
          "templateName": {},
          "defaultVersionId": {
            "type": "integer"
          }
        }
      }
    },
    "CreateProvisioningTemplateVersion": {
      "http": {
        "requestUri": "/provisioning-templates/{templateName}/versions"
      },
      "input": {
        "type": "structure",
        "required": [
          "templateName",
          "templateBody"
        ],
        "members": {
          "templateName": {
            "location": "uri",
            "locationName": "templateName"
          },
          "templateBody": {},
          "setAsDefault": {
            "location": "querystring",
            "locationName": "setAsDefault",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "templateArn": {},
          "templateName": {},
          "versionId": {
            "type": "integer"
          },
          "isDefaultVersion": {
            "type": "boolean"
          }
        }
      }
    },
    "CreateRoleAlias": {
      "http": {
        "requestUri": "/role-aliases/{roleAlias}"
      },
      "input": {
        "type": "structure",
        "required": [
          "roleAlias",
          "roleArn"
        ],
        "members": {
          "roleAlias": {
            "location": "uri",
            "locationName": "roleAlias"
          },
          "roleArn": {},
          "credentialDurationSeconds": {
            "type": "integer"
          },
          "tags": {
            "shape": "S29"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "roleAlias": {},
          "roleAliasArn": {}
        }
      }
    },
    "CreateScheduledAudit": {
      "http": {
        "requestUri": "/audit/scheduledaudits/{scheduledAuditName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "frequency",
          "targetCheckNames",
          "scheduledAuditName"
        ],
        "members": {
          "frequency": {},
          "dayOfMonth": {},
          "dayOfWeek": {},
          "targetCheckNames": {
            "shape": "S8e"
          },
          "scheduledAuditName": {
            "location": "uri",
            "locationName": "scheduledAuditName"
          },
          "tags": {
            "shape": "S29"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "scheduledAuditArn": {}
        }
      }
    },
    "CreateSecurityProfile": {
      "http": {
        "requestUri": "/security-profiles/{securityProfileName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "securityProfileName"
        ],
        "members": {
          "securityProfileName": {
            "location": "uri",
            "locationName": "securityProfileName"
          },
          "securityProfileDescription": {},
          "behaviors": {
            "shape": "S8k"
          },
          "alertTargets": {
            "shape": "S9a"
          },
          "additionalMetricsToRetain": {
            "shape": "S9e",
            "deprecated": true,
            "deprecatedMessage": "Use additionalMetricsToRetainV2."
          },
          "additionalMetricsToRetainV2": {
            "shape": "S9f"
          },
          "tags": {
            "shape": "S29"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "securityProfileName": {},
          "securityProfileArn": {}
        }
      }
    },
    "CreateStream": {
      "http": {
        "requestUri": "/streams/{streamId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "streamId",
          "files",
          "roleArn"
        ],
        "members": {
          "streamId": {
            "location": "uri",
            "locationName": "streamId"
          },
          "description": {},
          "files": {
            "shape": "S9l"
          },
          "roleArn": {},
          "tags": {
            "shape": "S29"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "streamId": {},
          "streamArn": {},
          "description": {},
          "streamVersion": {
            "type": "integer"
          }
        }
      }
    },
    "CreateThing": {
      "http": {
        "requestUri": "/things/{thingName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "thingName"
        ],
        "members": {
          "thingName": {
            "location": "uri",
            "locationName": "thingName"
          },
          "thingTypeName": {},
          "attributePayload": {
            "shape": "S3h"
          },
          "billingGroupName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "thingName": {},
          "thingArn": {},
          "thingId": {}
        }
      }
    },
    "CreateThingGroup": {
      "http": {
        "requestUri": "/thing-groups/{thingGroupName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "thingGroupName"
        ],
        "members": {
          "thingGroupName": {
            "location": "uri",
            "locationName": "thingGroupName"
          },
          "parentGroupName": {},
          "thingGroupProperties": {
            "shape": "S3f"
          },
          "tags": {
            "shape": "S29"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "thingGroupName": {},
          "thingGroupArn": {},
          "thingGroupId": {}
        }
      }
    },
    "CreateThingType": {
      "http": {
        "requestUri": "/thing-types/{thingTypeName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "thingTypeName"
        ],
        "members": {
          "thingTypeName": {
            "location": "uri",
            "locationName": "thingTypeName"
          },
          "thingTypeProperties": {
            "shape": "S9x"
          },
          "tags": {
            "shape": "S29"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "thingTypeName": {},
          "thingTypeArn": {},
          "thingTypeId": {}
        }
      }
    },
    "CreateTopicRule": {
      "http": {
        "requestUri": "/rules/{ruleName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "ruleName",
          "topicRulePayload"
        ],
        "members": {
          "ruleName": {
            "location": "uri",
            "locationName": "ruleName"
          },
          "topicRulePayload": {
            "shape": "Sa5"
          },
          "tags": {
            "location": "header",
            "locationName": "x-amz-tagging"
          }
        },
        "payload": "topicRulePayload"
      }
    },
    "CreateTopicRuleDestination": {
      "http": {
        "requestUri": "/destinations"
      },
      "input": {
        "type": "structure",
        "required": [
          "destinationConfiguration"
        ],
        "members": {
          "destinationConfiguration": {
            "type": "structure",
            "members": {
              "httpUrlConfiguration": {
                "type": "structure",
                "required": [
                  "confirmationUrl"
                ],
                "members": {
                  "confirmationUrl": {}
                }
              },
              "vpcConfiguration": {
                "type": "structure",
                "required": [
                  "subnetIds",
                  "vpcId",
                  "roleArn"
                ],
                "members": {
                  "subnetIds": {
                    "shape": "Sd6"
                  },
                  "securityGroups": {
                    "shape": "Sd8"
                  },
                  "vpcId": {},
                  "roleArn": {}
                }
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "topicRuleDestination": {
            "shape": "Sdc"
          }
        }
      }
    },
    "DeleteAccountAuditConfiguration": {
      "http": {
        "method": "DELETE",
        "requestUri": "/audit/configuration"
      },
      "input": {
        "type": "structure",
        "members": {
          "deleteScheduledAudits": {
            "location": "querystring",
            "locationName": "deleteScheduledAudits",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteAuditSuppression": {
      "http": {
        "requestUri": "/audit/suppressions/delete"
      },
      "input": {
        "type": "structure",
        "required": [
          "checkName",
          "resourceIdentifier"
        ],
        "members": {
          "checkName": {},
          "resourceIdentifier": {
            "shape": "S1o"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteAuthorizer": {
      "http": {
        "method": "DELETE",
        "requestUri": "/authorizer/{authorizerName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "authorizerName"
        ],
        "members": {
          "authorizerName": {
            "location": "uri",
            "locationName": "authorizerName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteBillingGroup": {
      "http": {
        "method": "DELETE",
        "requestUri": "/billing-groups/{billingGroupName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "billingGroupName"
        ],
        "members": {
          "billingGroupName": {
            "location": "uri",
            "locationName": "billingGroupName"
          },
          "expectedVersion": {
            "location": "querystring",
            "locationName": "expectedVersion",
            "type": "long"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteCACertificate": {
      "http": {
        "method": "DELETE",
        "requestUri": "/cacertificate/{caCertificateId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "certificateId"
        ],
        "members": {
          "certificateId": {
            "location": "uri",
            "locationName": "caCertificateId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteCertificate": {
      "http": {
        "method": "DELETE",
        "requestUri": "/certificates/{certificateId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "certificateId"
        ],
        "members": {
          "certificateId": {
            "location": "uri",
            "locationName": "certificateId"
          },
          "forceDelete": {
            "location": "querystring",
            "locationName": "forceDelete",
            "type": "boolean"
          }
        }
      }
    },
    "DeleteCustomMetric": {
      "http": {
        "method": "DELETE",
        "requestUri": "/custom-metric/{metricName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "metricName"
        ],
        "members": {
          "metricName": {
            "location": "uri",
            "locationName": "metricName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteDimension": {
      "http": {
        "method": "DELETE",
        "requestUri": "/dimensions/{name}"
      },
      "input": {
        "type": "structure",
        "required": [
          "name"
        ],
        "members": {
          "name": {
            "location": "uri",
            "locationName": "name"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteDomainConfiguration": {
      "http": {
        "method": "DELETE",
        "requestUri": "/domainConfigurations/{domainConfigurationName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "domainConfigurationName"
        ],
        "members": {
          "domainConfigurationName": {
            "location": "uri",
            "locationName": "domainConfigurationName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteDynamicThingGroup": {
      "http": {
        "method": "DELETE",
        "requestUri": "/dynamic-thing-groups/{thingGroupName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "thingGroupName"
        ],
        "members": {
          "thingGroupName": {
            "location": "uri",
            "locationName": "thingGroupName"
          },
          "expectedVersion": {
            "location": "querystring",
            "locationName": "expectedVersion",
            "type": "long"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteFleetMetric": {
      "http": {
        "method": "DELETE",
        "requestUri": "/fleet-metric/{metricName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "metricName"
        ],
        "members": {
          "metricName": {
            "location": "uri",
            "locationName": "metricName"
          },
          "expectedVersion": {
            "location": "querystring",
            "locationName": "expectedVersion",
            "type": "long"
          }
        }
      }
    },
    "DeleteJob": {
      "http": {
        "method": "DELETE",
        "requestUri": "/jobs/{jobId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "jobId"
        ],
        "members": {
          "jobId": {
            "location": "uri",
            "locationName": "jobId"
          },
          "force": {
            "location": "querystring",
            "locationName": "force",
            "type": "boolean"
          },
          "namespaceId": {
            "location": "querystring",
            "locationName": "namespaceId"
          }
        }
      }
    },
    "DeleteJobExecution": {
      "http": {
        "method": "DELETE",
        "requestUri": "/things/{thingName}/jobs/{jobId}/executionNumber/{executionNumber}"
      },
      "input": {
        "type": "structure",
        "required": [
          "jobId",
          "thingName",
          "executionNumber"
        ],
        "members": {
          "jobId": {
            "location": "uri",
            "locationName": "jobId"
          },
          "thingName": {
            "location": "uri",
            "locationName": "thingName"
          },
          "executionNumber": {
            "location": "uri",
            "locationName": "executionNumber",
            "type": "long"
          },
          "force": {
            "location": "querystring",
            "locationName": "force",
            "type": "boolean"
          },
          "namespaceId": {
            "location": "querystring",
            "locationName": "namespaceId"
          }
        }
      }
    },
    "DeleteJobTemplate": {
      "http": {
        "method": "DELETE",
        "requestUri": "/job-templates/{jobTemplateId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "jobTemplateId"
        ],
        "members": {
          "jobTemplateId": {
            "location": "uri",
            "locationName": "jobTemplateId"
          }
        }
      }
    },
    "DeleteMitigationAction": {
      "http": {
        "method": "DELETE",
        "requestUri": "/mitigationactions/actions/{actionName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "actionName"
        ],
        "members": {
          "actionName": {
            "location": "uri",
            "locationName": "actionName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteOTAUpdate": {
      "http": {
        "method": "DELETE",
        "requestUri": "/otaUpdates/{otaUpdateId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "otaUpdateId"
        ],
        "members": {
          "otaUpdateId": {
            "location": "uri",
            "locationName": "otaUpdateId"
          },
          "deleteStream": {
            "location": "querystring",
            "locationName": "deleteStream",
            "type": "boolean"
          },
          "forceDeleteAWSJob": {
            "location": "querystring",
            "locationName": "forceDeleteAWSJob",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeletePolicy": {
      "http": {
        "method": "DELETE",
        "requestUri": "/policies/{policyName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "policyName"
        ],
        "members": {
          "policyName": {
            "location": "uri",
            "locationName": "policyName"
          }
        }
      }
    },
    "DeletePolicyVersion": {
      "http": {
        "method": "DELETE",
        "requestUri": "/policies/{policyName}/version/{policyVersionId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "policyName",
          "policyVersionId"
        ],
        "members": {
          "policyName": {
            "location": "uri",
            "locationName": "policyName"
          },
          "policyVersionId": {
            "location": "uri",
            "locationName": "policyVersionId"
          }
        }
      }
    },
    "DeleteProvisioningTemplate": {
      "http": {
        "method": "DELETE",
        "requestUri": "/provisioning-templates/{templateName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "templateName"
        ],
        "members": {
          "templateName": {
            "location": "uri",
            "locationName": "templateName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteProvisioningTemplateVersion": {
      "http": {
        "method": "DELETE",
        "requestUri": "/provisioning-templates/{templateName}/versions/{versionId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "templateName",
          "versionId"
        ],
        "members": {
          "templateName": {
            "location": "uri",
            "locationName": "templateName"
          },
          "versionId": {
            "location": "uri",
            "locationName": "versionId",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteRegistrationCode": {
      "http": {
        "method": "DELETE",
        "requestUri": "/registrationcode"
      },
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteRoleAlias": {
      "http": {
        "method": "DELETE",
        "requestUri": "/role-aliases/{roleAlias}"
      },
      "input": {
        "type": "structure",
        "required": [
          "roleAlias"
        ],
        "members": {
          "roleAlias": {
            "location": "uri",
            "locationName": "roleAlias"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteScheduledAudit": {
      "http": {
        "method": "DELETE",
        "requestUri": "/audit/scheduledaudits/{scheduledAuditName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "scheduledAuditName"
        ],
        "members": {
          "scheduledAuditName": {
            "location": "uri",
            "locationName": "scheduledAuditName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteSecurityProfile": {
      "http": {
        "method": "DELETE",
        "requestUri": "/security-profiles/{securityProfileName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "securityProfileName"
        ],
        "members": {
          "securityProfileName": {
            "location": "uri",
            "locationName": "securityProfileName"
          },
          "expectedVersion": {
            "location": "querystring",
            "locationName": "expectedVersion",
            "type": "long"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteStream": {
      "http": {
        "method": "DELETE",
        "requestUri": "/streams/{streamId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "streamId"
        ],
        "members": {
          "streamId": {
            "location": "uri",
            "locationName": "streamId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteThing": {
      "http": {
        "method": "DELETE",
        "requestUri": "/things/{thingName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "thingName"
        ],
        "members": {
          "thingName": {
            "location": "uri",
            "locationName": "thingName"
          },
          "expectedVersion": {
            "location": "querystring",
            "locationName": "expectedVersion",
            "type": "long"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteThingGroup": {
      "http": {
        "method": "DELETE",
        "requestUri": "/thing-groups/{thingGroupName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "thingGroupName"
        ],
        "members": {
          "thingGroupName": {
            "location": "uri",
            "locationName": "thingGroupName"
          },
          "expectedVersion": {
            "location": "querystring",
            "locationName": "expectedVersion",
            "type": "long"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteThingType": {
      "http": {
        "method": "DELETE",
        "requestUri": "/thing-types/{thingTypeName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "thingTypeName"
        ],
        "members": {
          "thingTypeName": {
            "location": "uri",
            "locationName": "thingTypeName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteTopicRule": {
      "http": {
        "method": "DELETE",
        "requestUri": "/rules/{ruleName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "ruleName"
        ],
        "members": {
          "ruleName": {
            "location": "uri",
            "locationName": "ruleName"
          }
        }
      }
    },
    "DeleteTopicRuleDestination": {
      "http": {
        "method": "DELETE",
        "requestUri": "/destinations/{arn+}"
      },
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {
            "location": "uri",
            "locationName": "arn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteV2LoggingLevel": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v2LoggingLevel"
      },
      "input": {
        "type": "structure",
        "required": [
          "targetType",
          "targetName"
        ],
        "members": {
          "targetType": {
            "location": "querystring",
            "locationName": "targetType"
          },
          "targetName": {
            "location": "querystring",
            "locationName": "targetName"
          }
        }
      }
    },
    "DeprecateThingType": {
      "http": {
        "requestUri": "/thing-types/{thingTypeName}/deprecate"
      },
      "input": {
        "type": "structure",
        "required": [
          "thingTypeName"
        ],
        "members": {
          "thingTypeName": {
            "location": "uri",
            "locationName": "thingTypeName"
          },
          "undoDeprecate": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DescribeAccountAuditConfiguration": {
      "http": {
        "method": "GET",
        "requestUri": "/audit/configuration"
      },
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "roleArn": {},
          "auditNotificationTargetConfigurations": {
            "shape": "Sfc"
          },
          "auditCheckConfigurations": {
            "shape": "Sff"
          }
        }
      }
    },
    "DescribeAuditFinding": {
      "http": {
        "method": "GET",
        "requestUri": "/audit/findings/{findingId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "findingId"
        ],
        "members": {
          "findingId": {
            "location": "uri",
            "locationName": "findingId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "finding": {
            "shape": "Sfk"
          }
        }
      }
    },
    "DescribeAuditMitigationActionsTask": {
      "http": {
        "method": "GET",
        "requestUri": "/audit/mitigationactions/tasks/{taskId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "taskId"
        ],
        "members": {
          "taskId": {
            "location": "uri",
            "locationName": "taskId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "taskStatus": {},
          "startTime": {
            "type": "timestamp"
          },
          "endTime": {
            "type": "timestamp"
          },
          "taskStatistics": {
            "type": "map",
            "key": {},
            "value": {
              "type": "structure",
              "members": {
                "totalFindingsCount": {
                  "type": "long"
                },
                "failedFindingsCount": {
                  "type": "long"
                },
                "succeededFindingsCount": {
                  "type": "long"
                },
                "skippedFindingsCount": {
                  "type": "long"
                },
                "canceledFindingsCount": {
                  "type": "long"
                }
              }
            }
          },
          "target": {
            "shape": "Sg4"
          },
          "auditCheckToActionsMapping": {
            "shape": "Sg8"
          },
          "actionsDefinition": {
            "shape": "Sga"
          }
        }
      }
    },
    "DescribeAuditSuppression": {
      "http": {
        "requestUri": "/audit/suppressions/describe"
      },
      "input": {
        "type": "structure",
        "required": [
          "checkName",
          "resourceIdentifier"
        ],
        "members": {
          "checkName": {},
          "resourceIdentifier": {
            "shape": "S1o"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "checkName": {},
          "resourceIdentifier": {
            "shape": "S1o"
          },
          "expirationDate": {
            "type": "timestamp"
          },
          "suppressIndefinitely": {
            "type": "boolean"
          },
          "description": {}
        }
      }
    },
    "DescribeAuditTask": {
      "http": {
        "method": "GET",
        "requestUri": "/audit/tasks/{taskId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "taskId"
        ],
        "members": {
          "taskId": {
            "location": "uri",
            "locationName": "taskId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "taskStatus": {},
          "taskType": {},
          "taskStartTime": {
            "type": "timestamp"
          },
          "taskStatistics": {
            "type": "structure",
            "members": {
              "totalChecks": {
                "type": "integer"
              },
              "inProgressChecks": {
                "type": "integer"
              },
              "waitingForDataCollectionChecks": {
                "type": "integer"
              },
              "compliantChecks": {
                "type": "integer"
              },
              "nonCompliantChecks": {
                "type": "integer"
              },
              "failedChecks": {
                "type": "integer"
              },
              "canceledChecks": {
                "type": "integer"
              }
            }
          },
          "scheduledAuditName": {},
          "auditDetails": {
            "type": "map",
            "key": {},
            "value": {
              "type": "structure",
              "members": {
                "checkRunStatus": {},
                "checkCompliant": {
                  "type": "boolean"
                },
                "totalResourcesCount": {
                  "type": "long"
                },
                "nonCompliantResourcesCount": {
                  "type": "long"
                },
                "suppressedNonCompliantResourcesCount": {
                  "type": "long"
                },
                "errorCode": {},
                "message": {}
              }
            }
          }
        }
      }
    },
    "DescribeAuthorizer": {
      "http": {
        "method": "GET",
        "requestUri": "/authorizer/{authorizerName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "authorizerName"
        ],
        "members": {
          "authorizerName": {
            "location": "uri",
            "locationName": "authorizerName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "authorizerDescription": {
            "shape": "Sh1"
          }
        }
      }
    },
    "DescribeBillingGroup": {
      "http": {
        "method": "GET",
        "requestUri": "/billing-groups/{billingGroupName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "billingGroupName"
        ],
        "members": {
          "billingGroupName": {
            "location": "uri",
            "locationName": "billingGroupName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "billingGroupName": {},
          "billingGroupId": {},
          "billingGroupArn": {},
          "version": {
            "type": "long"
          },
          "billingGroupProperties": {
            "shape": "S2i"
          },
          "billingGroupMetadata": {
            "type": "structure",
            "members": {
              "creationDate": {
                "type": "timestamp"
              }
            }
          }
        }
      }
    },
    "DescribeCACertificate": {
      "http": {
        "method": "GET",
        "requestUri": "/cacertificate/{caCertificateId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "certificateId"
        ],
        "members": {
          "certificateId": {
            "location": "uri",
            "locationName": "caCertificateId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "certificateDescription": {
            "type": "structure",
            "members": {
              "certificateArn": {},
              "certificateId": {},
              "status": {},
              "certificatePem": {},
              "ownedBy": {},
              "creationDate": {
                "type": "timestamp"
              },
              "autoRegistrationStatus": {},
              "lastModifiedDate": {
                "type": "timestamp"
              },
              "customerVersion": {
                "type": "integer"
              },
              "generationId": {},
              "validity": {
                "shape": "She"
              }
            }
          },
          "registrationConfig": {
            "shape": "Shf"
          }
        }
      }
    },
    "DescribeCertificate": {
      "http": {
        "method": "GET",
        "requestUri": "/certificates/{certificateId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "certificateId"
        ],
        "members": {
          "certificateId": {
            "location": "uri",
            "locationName": "certificateId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "certificateDescription": {
            "type": "structure",
            "members": {
              "certificateArn": {},
              "certificateId": {},
              "caCertificateId": {},
              "status": {},
              "certificatePem": {},
              "ownedBy": {},
              "previousOwnedBy": {},
              "creationDate": {
                "type": "timestamp"
              },
              "lastModifiedDate": {
                "type": "timestamp"
              },
              "customerVersion": {
                "type": "integer"
              },
              "transferData": {
                "type": "structure",
                "members": {
                  "transferMessage": {},
                  "rejectReason": {},
                  "transferDate": {
                    "type": "timestamp"
                  },
                  "acceptDate": {
                    "type": "timestamp"
                  },
                  "rejectDate": {
                    "type": "timestamp"
                  }
                }
              },
              "generationId": {},
              "validity": {
                "shape": "She"
              },
              "certificateMode": {}
            }
          }
        }
      }
    },
    "DescribeCustomMetric": {
      "http": {
        "method": "GET",
        "requestUri": "/custom-metric/{metricName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "metricName"
        ],
        "members": {
          "metricName": {
            "location": "uri",
            "locationName": "metricName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "metricName": {},
          "metricArn": {},
          "metricType": {},
          "displayName": {},
          "creationDate": {
            "type": "timestamp"
          },
          "lastModifiedDate": {
            "type": "timestamp"
          }
        }
      }
    },
    "DescribeDefaultAuthorizer": {
      "http": {
        "method": "GET",
        "requestUri": "/default-authorizer"
      },
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "authorizerDescription": {
            "shape": "Sh1"
          }
        }
      }
    },
    "DescribeDetectMitigationActionsTask": {
      "http": {
        "method": "GET",
        "requestUri": "/detect/mitigationactions/tasks/{taskId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "taskId"
        ],
        "members": {
          "taskId": {
            "location": "uri",
            "locationName": "taskId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "taskSummary": {
            "shape": "Sht"
          }
        }
      }
    },
    "DescribeDimension": {
      "http": {
        "method": "GET",
        "requestUri": "/dimensions/{name}"
      },
      "input": {
        "type": "structure",
        "required": [
          "name"
        ],
        "members": {
          "name": {
            "location": "uri",
            "locationName": "name"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "name": {},
          "arn": {},
          "type": {},
          "stringValues": {
            "shape": "S30"
          },
          "creationDate": {
            "type": "timestamp"
          },
          "lastModifiedDate": {
            "type": "timestamp"
          }
        }
      }
    },
    "DescribeDomainConfiguration": {
      "http": {
        "method": "GET",
        "requestUri": "/domainConfigurations/{domainConfigurationName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "domainConfigurationName"
        ],
        "members": {
          "domainConfigurationName": {
            "location": "uri",
            "locationName": "domainConfigurationName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "domainConfigurationName": {},
          "domainConfigurationArn": {},
          "domainName": {},
          "serverCertificates": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "serverCertificateArn": {},
                "serverCertificateStatus": {},
                "serverCertificateStatusDetail": {}
              }
            }
          },
          "authorizerConfig": {
            "shape": "S39"
          },
          "domainConfigurationStatus": {},
          "serviceType": {},
          "domainType": {},
          "lastStatusChangeDate": {
            "type": "timestamp"
          }
        }
      }
    },
    "DescribeEndpoint": {
      "http": {
        "method": "GET",
        "requestUri": "/endpoint"
      },
      "input": {
        "type": "structure",
        "members": {
          "endpointType": {
            "location": "querystring",
            "locationName": "endpointType"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "endpointAddress": {}
        }
      }
    },
    "DescribeEventConfigurations": {
      "http": {
        "method": "GET",
        "requestUri": "/event-configurations"
      },
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "eventConfigurations": {
            "shape": "Sij"
          },
          "creationDate": {
            "type": "timestamp"
          },
          "lastModifiedDate": {
            "type": "timestamp"
          }
        }
      }
    },
    "DescribeFleetMetric": {
      "http": {
        "method": "GET",
        "requestUri": "/fleet-metric/{metricName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "metricName"
        ],
        "members": {
          "metricName": {
            "location": "uri",
            "locationName": "metricName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "metricName": {},
          "queryString": {},
          "aggregationType": {
            "shape": "S3t"
          },
          "period": {
            "type": "integer"
          },
          "aggregationField": {},
          "description": {},
          "queryVersion": {},
          "indexName": {},
          "creationDate": {
            "type": "timestamp"
          },
          "lastModifiedDate": {
            "type": "timestamp"
          },
          "unit": {},
          "version": {
            "type": "long"
          },
          "metricArn": {}
        }
      }
    },
    "DescribeIndex": {
      "http": {
        "method": "GET",
        "requestUri": "/indices/{indexName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "indexName"
        ],
        "members": {
          "indexName": {
            "location": "uri",
            "locationName": "indexName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "indexName": {},
          "indexStatus": {},
          "schema": {}
        }
      }
    },
    "DescribeJob": {
      "http": {
        "method": "GET",
        "requestUri": "/jobs/{jobId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "jobId"
        ],
        "members": {
          "jobId": {
            "location": "uri",
            "locationName": "jobId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "documentSource": {},
          "job": {
            "type": "structure",
            "members": {
              "jobArn": {},
              "jobId": {},
              "targetSelection": {},
              "status": {},
              "forceCanceled": {
                "type": "boolean"
              },
              "reasonCode": {},
              "comment": {},
              "targets": {
                "shape": "Sg"
              },
              "description": {},
              "presignedUrlConfig": {
                "shape": "S46"
              },
              "jobExecutionsRolloutConfig": {
                "shape": "S49"
              },
              "abortConfig": {
                "shape": "S4g"
              },
              "createdAt": {
                "type": "timestamp"
              },
              "lastUpdatedAt": {
                "type": "timestamp"
              },
              "completedAt": {
                "type": "timestamp"
              },
              "jobProcessDetails": {
                "type": "structure",
                "members": {
                  "processingTargets": {
                    "type": "list",
                    "member": {}
                  },
                  "numberOfCanceledThings": {
                    "type": "integer"
                  },
                  "numberOfSucceededThings": {
                    "type": "integer"
                  },
                  "numberOfFailedThings": {
                    "type": "integer"
                  },
                  "numberOfRejectedThings": {
                    "type": "integer"
                  },
                  "numberOfQueuedThings": {
                    "type": "integer"
                  },
                  "numberOfInProgressThings": {
                    "type": "integer"
                  },
                  "numberOfRemovedThings": {
                    "type": "integer"
                  },
                  "numberOfTimedOutThings": {
                    "type": "integer"
                  }
                }
              },
              "timeoutConfig": {
                "shape": "S4n"
              },
              "namespaceId": {},
              "jobTemplateArn": {},
              "jobExecutionsRetryConfig": {
                "shape": "S4q"
              },
              "documentParameters": {
                "shape": "S4v"
              },
              "isConcurrent": {
                "type": "boolean"
              }
            }
          }
        }
      }
    },
    "DescribeJobExecution": {
      "http": {
        "method": "GET",
        "requestUri": "/things/{thingName}/jobs/{jobId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "jobId",
          "thingName"
        ],
        "members": {
          "jobId": {
            "location": "uri",
            "locationName": "jobId"
          },
          "thingName": {
            "location": "uri",
            "locationName": "thingName"
          },
          "executionNumber": {
            "location": "querystring",
            "locationName": "executionNumber",
            "type": "long"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "execution": {
            "type": "structure",
            "members": {
              "jobId": {},
              "status": {},
              "forceCanceled": {
                "type": "boolean"
              },
              "statusDetails": {
                "type": "structure",
                "members": {
                  "detailsMap": {
                    "shape": "S1e"
                  }
                }
              },
              "thingArn": {},
              "queuedAt": {
                "type": "timestamp"
              },
              "startedAt": {
                "type": "timestamp"
              },
              "lastUpdatedAt": {
                "type": "timestamp"
              },
              "executionNumber": {
                "type": "long"
              },
              "versionNumber": {
                "type": "long"
              },
              "approximateSecondsBeforeTimedOut": {
                "type": "long"
              }
            }
          }
        }
      }
    },
    "DescribeJobTemplate": {
      "http": {
        "method": "GET",
        "requestUri": "/job-templates/{jobTemplateId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "jobTemplateId"
        ],
        "members": {
          "jobTemplateId": {
            "location": "uri",
            "locationName": "jobTemplateId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "jobTemplateArn": {},
          "jobTemplateId": {},
          "description": {},
          "documentSource": {},
          "document": {},
          "createdAt": {
            "type": "timestamp"
          },
          "presignedUrlConfig": {
            "shape": "S46"
          },
          "jobExecutionsRolloutConfig": {
            "shape": "S49"
          },
          "abortConfig": {
            "shape": "S4g"
          },
          "timeoutConfig": {
            "shape": "S4n"
          },
          "jobExecutionsRetryConfig": {
            "shape": "S4q"
          }
        }
      }
    },
    "DescribeManagedJobTemplate": {
      "http": {
        "method": "GET",
        "requestUri": "/managed-job-templates/{templateName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "templateName"
        ],
        "members": {
          "templateName": {
            "location": "uri",
            "locationName": "templateName"
          },
          "templateVersion": {
            "location": "querystring",
            "locationName": "templateVersion"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "templateName": {},
          "templateArn": {},
          "description": {},
          "templateVersion": {},
          "environments": {
            "shape": "Sjn"
          },
          "documentParameters": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "key": {},
                "description": {},
                "regex": {},
                "example": {},
                "optional": {
                  "type": "boolean"
                }
              }
            }
          },
          "document": {}
        }
      }
    },
    "DescribeMitigationAction": {
      "http": {
        "method": "GET",
        "requestUri": "/mitigationactions/actions/{actionName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "actionName"
        ],
        "members": {
          "actionName": {
            "location": "uri",
            "locationName": "actionName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "actionName": {},
          "actionType": {},
          "actionArn": {},
          "actionId": {},
          "roleArn": {},
          "actionParams": {
            "shape": "S59"
          },
          "creationDate": {
            "type": "timestamp"
          },
          "lastModifiedDate": {
            "type": "timestamp"
          }
        }
      }
    },
    "DescribeProvisioningTemplate": {
      "http": {
        "method": "GET",
        "requestUri": "/provisioning-templates/{templateName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "templateName"
        ],
        "members": {
          "templateName": {
            "location": "uri",
            "locationName": "templateName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "templateArn": {},
          "templateName": {},
          "description": {},
          "creationDate": {
            "type": "timestamp"
          },
          "lastModifiedDate": {
            "type": "timestamp"
          },
          "defaultVersionId": {
            "type": "integer"
          },
          "templateBody": {},
          "enabled": {
            "type": "boolean"
          },
          "provisioningRoleArn": {},
          "preProvisioningHook": {
            "shape": "S7z"
          }
        }
      }
    },
    "DescribeProvisioningTemplateVersion": {
      "http": {
        "method": "GET",
        "requestUri": "/provisioning-templates/{templateName}/versions/{versionId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "templateName",
          "versionId"
        ],
        "members": {
          "templateName": {
            "location": "uri",
            "locationName": "templateName"
          },
          "versionId": {
            "location": "uri",
            "locationName": "versionId",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "versionId": {
            "type": "integer"
          },
          "creationDate": {
            "type": "timestamp"
          },
          "templateBody": {},
          "isDefaultVersion": {
            "type": "boolean"
          }
        }
      }
    },
    "DescribeRoleAlias": {
      "http": {
        "method": "GET",
        "requestUri": "/role-aliases/{roleAlias}"
      },
      "input": {
        "type": "structure",
        "required": [
          "roleAlias"
        ],
        "members": {
          "roleAlias": {
            "location": "uri",
            "locationName": "roleAlias"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "roleAliasDescription": {
            "type": "structure",
            "members": {
              "roleAlias": {},
              "roleAliasArn": {},
              "roleArn": {},
              "owner": {},
              "credentialDurationSeconds": {
                "type": "integer"
              },
              "creationDate": {
                "type": "timestamp"
              },
              "lastModifiedDate": {
                "type": "timestamp"
              }
            }
          }
        }
      }
    },
    "DescribeScheduledAudit": {
      "http": {
        "method": "GET",
        "requestUri": "/audit/scheduledaudits/{scheduledAuditName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "scheduledAuditName"
        ],
        "members": {
          "scheduledAuditName": {
            "location": "uri",
            "locationName": "scheduledAuditName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "frequency": {},
          "dayOfMonth": {},
          "dayOfWeek": {},
          "targetCheckNames": {
            "shape": "S8e"
          },
          "scheduledAuditName": {},
          "scheduledAuditArn": {}
        }
      }
    },
    "DescribeSecurityProfile": {
      "http": {
        "method": "GET",
        "requestUri": "/security-profiles/{securityProfileName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "securityProfileName"
        ],
        "members": {
          "securityProfileName": {
            "location": "uri",
            "locationName": "securityProfileName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "securityProfileName": {},
          "securityProfileArn": {},
          "securityProfileDescription": {},
          "behaviors": {
            "shape": "S8k"
          },
          "alertTargets": {
            "shape": "S9a"
          },
          "additionalMetricsToRetain": {
            "shape": "S9e",
            "deprecated": true,
            "deprecatedMessage": "Use additionalMetricsToRetainV2."
          },
          "additionalMetricsToRetainV2": {
            "shape": "S9f"
          },
          "version": {
            "type": "long"
          },
          "creationDate": {
            "type": "timestamp"
          },
          "lastModifiedDate": {
            "type": "timestamp"
          }
        }
      }
    },
    "DescribeStream": {
      "http": {
        "method": "GET",
        "requestUri": "/streams/{streamId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "streamId"
        ],
        "members": {
          "streamId": {
            "location": "uri",
            "locationName": "streamId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "streamInfo": {
            "type": "structure",
            "members": {
              "streamId": {},
              "streamArn": {},
              "streamVersion": {
                "type": "integer"
              },
              "description": {},
              "files": {
                "shape": "S9l"
              },
              "createdAt": {
                "type": "timestamp"
              },
              "lastUpdatedAt": {
                "type": "timestamp"
              },
              "roleArn": {}
            }
          }
        }
      }
    },
    "DescribeThing": {
      "http": {
        "method": "GET",
        "requestUri": "/things/{thingName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "thingName"
        ],
        "members": {
          "thingName": {
            "location": "uri",
            "locationName": "thingName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "defaultClientId": {},
          "thingName": {},
          "thingId": {},
          "thingArn": {},
          "thingTypeName": {},
          "attributes": {
            "shape": "S3i"
          },
          "version": {
            "type": "long"
          },
          "billingGroupName": {}
        }
      }
    },
    "DescribeThingGroup": {
      "http": {
        "method": "GET",
        "requestUri": "/thing-groups/{thingGroupName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "thingGroupName"
        ],
        "members": {
          "thingGroupName": {
            "location": "uri",
            "locationName": "thingGroupName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "thingGroupName": {},
          "thingGroupId": {},
          "thingGroupArn": {},
          "version": {
            "type": "long"
          },
          "thingGroupProperties": {
            "shape": "S3f"
          },
          "thingGroupMetadata": {
            "type": "structure",
            "members": {
              "parentGroupName": {},
              "rootToParentThingGroups": {
                "shape": "Skg"
              },
              "creationDate": {
                "type": "timestamp"
              }
            }
          },
          "indexName": {},
          "queryString": {},
          "queryVersion": {},
          "status": {}
        }
      }
    },
    "DescribeThingRegistrationTask": {
      "http": {
        "method": "GET",
        "requestUri": "/thing-registration-tasks/{taskId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "taskId"
        ],
        "members": {
          "taskId": {
            "location": "uri",
            "locationName": "taskId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "taskId": {},
          "creationDate": {
            "type": "timestamp"
          },
          "lastModifiedDate": {
            "type": "timestamp"
          },
          "templateBody": {},
          "inputFileBucket": {},
          "inputFileKey": {},
          "roleArn": {},
          "status": {},
          "message": {},
          "successCount": {
            "type": "integer"
          },
          "failureCount": {
            "type": "integer"
          },
          "percentageProgress": {
            "type": "integer"
          }
        }
      }
    },
    "DescribeThingType": {
      "http": {
        "method": "GET",
        "requestUri": "/thing-types/{thingTypeName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "thingTypeName"
        ],
        "members": {
          "thingTypeName": {
            "location": "uri",
            "locationName": "thingTypeName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "thingTypeName": {},
          "thingTypeId": {},
          "thingTypeArn": {},
          "thingTypeProperties": {
            "shape": "S9x"
          },
          "thingTypeMetadata": {
            "shape": "Skt"
          }
        }
      }
    },
    "DetachPolicy": {
      "http": {
        "requestUri": "/target-policies/{policyName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "policyName",
          "target"
        ],
        "members": {
          "policyName": {
            "location": "uri",
            "locationName": "policyName"
          },
          "target": {}
        }
      }
    },
    "DetachPrincipalPolicy": {
      "http": {
        "method": "DELETE",
        "requestUri": "/principal-policies/{policyName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "policyName",
          "principal"
        ],
        "members": {
          "policyName": {
            "location": "uri",
            "locationName": "policyName"
          },
          "principal": {
            "location": "header",
            "locationName": "x-amzn-iot-principal"
          }
        }
      },
      "deprecated": true
    },
    "DetachSecurityProfile": {
      "http": {
        "method": "DELETE",
        "requestUri": "/security-profiles/{securityProfileName}/targets"
      },
      "input": {
        "type": "structure",
        "required": [
          "securityProfileName",
          "securityProfileTargetArn"
        ],
        "members": {
          "securityProfileName": {
            "location": "uri",
            "locationName": "securityProfileName"
          },
          "securityProfileTargetArn": {
            "location": "querystring",
            "locationName": "securityProfileTargetArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DetachThingPrincipal": {
      "http": {
        "method": "DELETE",
        "requestUri": "/things/{thingName}/principals"
      },
      "input": {
        "type": "structure",
        "required": [
          "thingName",
          "principal"
        ],
        "members": {
          "thingName": {
            "location": "uri",
            "locationName": "thingName"
          },
          "principal": {
            "location": "header",
            "locationName": "x-amzn-principal"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DisableTopicRule": {
      "http": {
        "requestUri": "/rules/{ruleName}/disable"
      },
      "input": {
        "type": "structure",
        "required": [
          "ruleName"
        ],
        "members": {
          "ruleName": {
            "location": "uri",
            "locationName": "ruleName"
          }
        }
      }
    },
    "EnableTopicRule": {
      "http": {
        "requestUri": "/rules/{ruleName}/enable"
      },
      "input": {
        "type": "structure",
        "required": [
          "ruleName"
        ],
        "members": {
          "ruleName": {
            "location": "uri",
            "locationName": "ruleName"
          }
        }
      }
    },
    "GetBehaviorModelTrainingSummaries": {
      "http": {
        "method": "GET",
        "requestUri": "/behavior-model-training/summaries"
      },
      "input": {
        "type": "structure",
        "members": {
          "securityProfileName": {
            "location": "querystring",
            "locationName": "securityProfileName"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "summaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "securityProfileName": {},
                "behaviorName": {},
                "trainingDataCollectionStartDate": {
                  "type": "timestamp"
                },
                "modelStatus": {},
                "datapointsCollectionPercentage": {
                  "type": "double"
                },
                "lastModelRefreshDate": {
                  "type": "timestamp"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "GetBucketsAggregation": {
      "http": {
        "requestUri": "/indices/buckets"
      },
      "input": {
        "type": "structure",
        "required": [
          "queryString",
          "aggregationField",
          "bucketsAggregationType"
        ],
        "members": {
          "indexName": {},
          "queryString": {},
          "aggregationField": {},
          "queryVersion": {},
          "bucketsAggregationType": {
            "type": "structure",
            "members": {
              "termsAggregation": {
                "type": "structure",
                "members": {
                  "maxBuckets": {
                    "type": "integer"
                  }
                }
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "totalCount": {
            "type": "integer"
          },
          "buckets": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "keyValue": {},
                "count": {
                  "type": "integer"
                }
              }
            }
          }
        }
      }
    },
    "GetCardinality": {
      "http": {
        "requestUri": "/indices/cardinality"
      },
      "input": {
        "type": "structure",
        "required": [
          "queryString"
        ],
        "members": {
          "indexName": {},
          "queryString": {},
          "aggregationField": {},
          "queryVersion": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "cardinality": {
            "type": "integer"
          }
        }
      }
    },
    "GetEffectivePolicies": {
      "http": {
        "requestUri": "/effective-policies"
      },
      "input": {
        "type": "structure",
        "members": {
          "principal": {},
          "cognitoIdentityPoolId": {},
          "thingName": {
            "location": "querystring",
            "locationName": "thingName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "effectivePolicies": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "policyName": {},
                "policyArn": {},
                "policyDocument": {}
              }
            }
          }
        }
      }
    },
    "GetIndexingConfiguration": {
      "http": {
        "method": "GET",
        "requestUri": "/indexing/config"
      },
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "thingIndexingConfiguration": {
            "shape": "Sls"
          },
          "thingGroupIndexingConfiguration": {
            "shape": "Sm1"
          }
        }
      }
    },
    "GetJobDocument": {
      "http": {
        "method": "GET",
        "requestUri": "/jobs/{jobId}/job-document"
      },
      "input": {
        "type": "structure",
        "required": [
          "jobId"
        ],
        "members": {
          "jobId": {
            "location": "uri",
            "locationName": "jobId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "document": {}
        }
      }
    },
    "GetLoggingOptions": {
      "http": {
        "method": "GET",
        "requestUri": "/loggingOptions"
      },
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "roleArn": {},
          "logLevel": {}
        }
      }
    },
    "GetOTAUpdate": {
      "http": {
        "method": "GET",
        "requestUri": "/otaUpdates/{otaUpdateId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "otaUpdateId"
        ],
        "members": {
          "otaUpdateId": {
            "location": "uri",
            "locationName": "otaUpdateId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "otaUpdateInfo": {
            "type": "structure",
            "members": {
              "otaUpdateId": {},
              "otaUpdateArn": {},
              "creationDate": {
                "type": "timestamp"
              },
              "lastModifiedDate": {
                "type": "timestamp"
              },
              "description": {},
              "targets": {
                "shape": "S5t"
              },
              "protocols": {
                "shape": "S5v"
              },
              "awsJobExecutionsRolloutConfig": {
                "shape": "S5x"
              },
              "awsJobPresignedUrlConfig": {
                "shape": "S64"
              },
              "targetSelection": {},
              "otaUpdateFiles": {
                "shape": "S6f"
              },
              "otaUpdateStatus": {},
              "awsIotJobId": {},
              "awsIotJobArn": {},
              "errorInfo": {
                "type": "structure",
                "members": {
                  "code": {},
                  "message": {}
                }
              },
              "additionalParameters": {
                "shape": "S7d"
              }
            }
          }
        }
      }
    },
    "GetPercentiles": {
      "http": {
        "requestUri": "/indices/percentiles"
      },
      "input": {
        "type": "structure",
        "required": [
          "queryString"
        ],
        "members": {
          "indexName": {},
          "queryString": {},
          "aggregationField": {},
          "queryVersion": {},
          "percents": {
            "type": "list",
            "member": {
              "type": "double"
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "percentiles": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "percent": {
                  "type": "double"
                },
                "value": {
                  "type": "double"
                }
              }
            }
          }
        }
      }
    },
    "GetPolicy": {
      "http": {
        "method": "GET",
        "requestUri": "/policies/{policyName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "policyName"
        ],
        "members": {
          "policyName": {
            "location": "uri",
            "locationName": "policyName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "policyName": {},
          "policyArn": {},
          "policyDocument": {},
          "defaultVersionId": {},
          "creationDate": {
            "type": "timestamp"
          },
          "lastModifiedDate": {
            "type": "timestamp"
          },
          "generationId": {}
        }
      }
    },
    "GetPolicyVersion": {
      "http": {
        "method": "GET",
        "requestUri": "/policies/{policyName}/version/{policyVersionId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "policyName",
          "policyVersionId"
        ],
        "members": {
          "policyName": {
            "location": "uri",
            "locationName": "policyName"
          },
          "policyVersionId": {
            "location": "uri",
            "locationName": "policyVersionId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "policyArn": {},
          "policyName": {},
          "policyDocument": {},
          "policyVersionId": {},
          "isDefaultVersion": {
            "type": "boolean"
          },
          "creationDate": {
            "type": "timestamp"
          },
          "lastModifiedDate": {
            "type": "timestamp"
          },
          "generationId": {}
        }
      }
    },
    "GetRegistrationCode": {
      "http": {
        "method": "GET",
        "requestUri": "/registrationcode"
      },
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "registrationCode": {}
        }
      }
    },
    "GetStatistics": {
      "http": {
        "requestUri": "/indices/statistics"
      },
      "input": {
        "type": "structure",
        "required": [
          "queryString"
        ],
        "members": {
          "indexName": {},
          "queryString": {},
          "aggregationField": {},
          "queryVersion": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "statistics": {
            "type": "structure",
            "members": {
              "count": {
                "type": "integer"
              },
              "average": {
                "type": "double"
              },
              "sum": {
                "type": "double"
              },
              "minimum": {
                "type": "double"
              },
              "maximum": {
                "type": "double"
              },
              "sumOfSquares": {
                "type": "double"
              },
              "variance": {
                "type": "double"
              },
              "stdDeviation": {
                "type": "double"
              }
            }
          }
        }
      }
    },
    "GetTopicRule": {
      "http": {
        "method": "GET",
        "requestUri": "/rules/{ruleName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "ruleName"
        ],
        "members": {
          "ruleName": {
            "location": "uri",
            "locationName": "ruleName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ruleArn": {},
          "rule": {
            "type": "structure",
            "members": {
              "ruleName": {},
              "sql": {},
              "description": {},
              "createdAt": {
                "type": "timestamp"
              },
              "actions": {
                "shape": "Sa8"
              },
              "ruleDisabled": {
                "type": "boolean"
              },
              "awsIotSqlVersion": {},
              "errorAction": {
                "shape": "Sa9"
              }
            }
          }
        }
      }
    },
    "GetTopicRuleDestination": {
      "http": {
        "method": "GET",
        "requestUri": "/destinations/{arn+}"
      },
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {
            "location": "uri",
            "locationName": "arn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "topicRuleDestination": {
            "shape": "Sdc"
          }
        }
      }
    },
    "GetV2LoggingOptions": {
      "http": {
        "method": "GET",
        "requestUri": "/v2LoggingOptions"
      },
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "roleArn": {},
          "defaultLogLevel": {},
          "disableAllLogs": {
            "type": "boolean"
          }
        }
      }
    },
    "ListActiveViolations": {
      "http": {
        "method": "GET",
        "requestUri": "/active-violations"
      },
      "input": {
        "type": "structure",
        "members": {
          "thingName": {
            "location": "querystring",
            "locationName": "thingName"
          },
          "securityProfileName": {
            "location": "querystring",
            "locationName": "securityProfileName"
          },
          "behaviorCriteriaType": {
            "location": "querystring",
            "locationName": "behaviorCriteriaType"
          },
          "listSuppressedAlerts": {
            "location": "querystring",
            "locationName": "listSuppressedAlerts",
            "type": "boolean"
          },
          "verificationState": {
            "location": "querystring",
            "locationName": "verificationState"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "activeViolations": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "violationId": {},
                "thingName": {},
                "securityProfileName": {},
                "behavior": {
                  "shape": "S8l"
                },
                "lastViolationValue": {
                  "shape": "S8s"
                },
                "violationEventAdditionalInfo": {
                  "shape": "Snj"
                },
                "verificationState": {},
                "verificationStateDescription": {},
                "lastViolationTime": {
                  "type": "timestamp"
                },
                "violationStartTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListAttachedPolicies": {
      "http": {
        "requestUri": "/attached-policies/{target}"
      },
      "input": {
        "type": "structure",
        "required": [
          "target"
        ],
        "members": {
          "target": {
            "location": "uri",
            "locationName": "target"
          },
          "recursive": {
            "location": "querystring",
            "locationName": "recursive",
            "type": "boolean"
          },
          "marker": {
            "location": "querystring",
            "locationName": "marker"
          },
          "pageSize": {
            "location": "querystring",
            "locationName": "pageSize",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "policies": {
            "shape": "Snq"
          },
          "nextMarker": {}
        }
      }
    },
    "ListAuditFindings": {
      "http": {
        "requestUri": "/audit/findings"
      },
      "input": {
        "type": "structure",
        "members": {
          "taskId": {},
          "checkName": {},
          "resourceIdentifier": {
            "shape": "S1o"
          },
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {},
          "startTime": {
            "type": "timestamp"
          },
          "endTime": {
            "type": "timestamp"
          },
          "listSuppressedFindings": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "findings": {
            "type": "list",
            "member": {
              "shape": "Sfk"
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListAuditMitigationActionsExecutions": {
      "http": {
        "method": "GET",
        "requestUri": "/audit/mitigationactions/executions"
      },
      "input": {
        "type": "structure",
        "required": [
          "taskId",
          "findingId"
        ],
        "members": {
          "taskId": {
            "location": "querystring",
            "locationName": "taskId"
          },
          "actionStatus": {
            "location": "querystring",
            "locationName": "actionStatus"
          },
          "findingId": {
            "location": "querystring",
            "locationName": "findingId"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "actionsExecutions": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "taskId": {},
                "findingId": {},
                "actionName": {},
                "actionId": {},
                "status": {},
                "startTime": {
                  "type": "timestamp"
                },
                "endTime": {
                  "type": "timestamp"
                },
                "errorCode": {},
                "message": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListAuditMitigationActionsTasks": {
      "http": {
        "method": "GET",
        "requestUri": "/audit/mitigationactions/tasks"
      },
      "input": {
        "type": "structure",
        "required": [
          "startTime",
          "endTime"
        ],
        "members": {
          "auditTaskId": {
            "location": "querystring",
            "locationName": "auditTaskId"
          },
          "findingId": {
            "location": "querystring",
            "locationName": "findingId"
          },
          "taskStatus": {
            "location": "querystring",
            "locationName": "taskStatus"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "startTime": {
            "location": "querystring",
            "locationName": "startTime",
            "type": "timestamp"
          },
          "endTime": {
            "location": "querystring",
            "locationName": "endTime",
            "type": "timestamp"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "tasks": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "taskId": {},
                "startTime": {
                  "type": "timestamp"
                },
                "taskStatus": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListAuditSuppressions": {
      "http": {
        "requestUri": "/audit/suppressions/list"
      },
      "input": {
        "type": "structure",
        "members": {
          "checkName": {},
          "resourceIdentifier": {
            "shape": "S1o"
          },
          "ascendingOrder": {
            "type": "boolean"
          },
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "suppressions": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "checkName",
                "resourceIdentifier"
              ],
              "members": {
                "checkName": {},
                "resourceIdentifier": {
                  "shape": "S1o"
                },
                "expirationDate": {
                  "type": "timestamp"
                },
                "suppressIndefinitely": {
                  "type": "boolean"
                },
                "description": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListAuditTasks": {
      "http": {
        "method": "GET",
        "requestUri": "/audit/tasks"
      },
      "input": {
        "type": "structure",
        "required": [
          "startTime",
          "endTime"
        ],
        "members": {
          "startTime": {
            "location": "querystring",
            "locationName": "startTime",
            "type": "timestamp"
          },
          "endTime": {
            "location": "querystring",
            "locationName": "endTime",
            "type": "timestamp"
          },
          "taskType": {
            "location": "querystring",
            "locationName": "taskType"
          },
          "taskStatus": {
            "location": "querystring",
            "locationName": "taskStatus"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "tasks": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "taskId": {},
                "taskStatus": {},
                "taskType": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListAuthorizers": {
      "http": {
        "method": "GET",
        "requestUri": "/authorizers/"
      },
      "input": {
        "type": "structure",
        "members": {
          "pageSize": {
            "location": "querystring",
            "locationName": "pageSize",
            "type": "integer"
          },
          "marker": {
            "location": "querystring",
            "locationName": "marker"
          },
          "ascendingOrder": {
            "location": "querystring",
            "locationName": "isAscendingOrder",
            "type": "boolean"
          },
          "status": {
            "location": "querystring",
            "locationName": "status"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "authorizers": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "authorizerName": {},
                "authorizerArn": {}
              }
            }
          },
          "nextMarker": {}
        }
      }
    },
    "ListBillingGroups": {
      "http": {
        "method": "GET",
        "requestUri": "/billing-groups"
      },
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "namePrefixFilter": {
            "location": "querystring",
            "locationName": "namePrefixFilter"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "billingGroups": {
            "type": "list",
            "member": {
              "shape": "Skh"
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListCACertificates": {
      "http": {
        "method": "GET",
        "requestUri": "/cacertificates"
      },
      "input": {
        "type": "structure",
        "members": {
          "pageSize": {
            "location": "querystring",
            "locationName": "pageSize",
            "type": "integer"
          },
          "marker": {
            "location": "querystring",
            "locationName": "marker"
          },
          "ascendingOrder": {
            "location": "querystring",
            "locationName": "isAscendingOrder",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "certificates": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "certificateArn": {},
                "certificateId": {},
                "status": {},
                "creationDate": {
                  "type": "timestamp"
                }
              }
            }
          },
          "nextMarker": {}
        }
      }
    },
    "ListCertificates": {
      "http": {
        "method": "GET",
        "requestUri": "/certificates"
      },
      "input": {
        "type": "structure",
        "members": {
          "pageSize": {
            "location": "querystring",
            "locationName": "pageSize",
            "type": "integer"
          },
          "marker": {
            "location": "querystring",
            "locationName": "marker"
          },
          "ascendingOrder": {
            "location": "querystring",
            "locationName": "isAscendingOrder",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "certificates": {
            "shape": "Sos"
          },
          "nextMarker": {}
        }
      }
    },
    "ListCertificatesByCA": {
      "http": {
        "method": "GET",
        "requestUri": "/certificates-by-ca/{caCertificateId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "caCertificateId"
        ],
        "members": {
          "caCertificateId": {
            "location": "uri",
            "locationName": "caCertificateId"
          },
          "pageSize": {
            "location": "querystring",
            "locationName": "pageSize",
            "type": "integer"
          },
          "marker": {
            "location": "querystring",
            "locationName": "marker"
          },
          "ascendingOrder": {
            "location": "querystring",
            "locationName": "isAscendingOrder",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "certificates": {
            "shape": "Sos"
          },
          "nextMarker": {}
        }
      }
    },
    "ListCustomMetrics": {
      "http": {
        "method": "GET",
        "requestUri": "/custom-metrics"
      },
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "metricNames": {
            "type": "list",
            "member": {}
          },
          "nextToken": {}
        }
      }
    },
    "ListDetectMitigationActionsExecutions": {
      "http": {
        "method": "GET",
        "requestUri": "/detect/mitigationactions/executions"
      },
      "input": {
        "type": "structure",
        "members": {
          "taskId": {
            "location": "querystring",
            "locationName": "taskId"
          },
          "violationId": {
            "location": "querystring",
            "locationName": "violationId"
          },
          "thingName": {
            "location": "querystring",
            "locationName": "thingName"
          },
          "startTime": {
            "location": "querystring",
            "locationName": "startTime",
            "type": "timestamp"
          },
          "endTime": {
            "location": "querystring",
            "locationName": "endTime",
            "type": "timestamp"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "actionsExecutions": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "taskId": {},
                "violationId": {},
                "actionName": {},
                "thingName": {},
                "executionStartDate": {
                  "type": "timestamp"
                },
                "executionEndDate": {
                  "type": "timestamp"
                },
                "status": {},
                "errorCode": {},
                "message": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListDetectMitigationActionsTasks": {
      "http": {
        "method": "GET",
        "requestUri": "/detect/mitigationactions/tasks"
      },
      "input": {
        "type": "structure",
        "required": [
          "startTime",
          "endTime"
        ],
        "members": {
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "startTime": {
            "location": "querystring",
            "locationName": "startTime",
            "type": "timestamp"
          },
          "endTime": {
            "location": "querystring",
            "locationName": "endTime",
            "type": "timestamp"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "tasks": {
            "type": "list",
            "member": {
              "shape": "Sht"
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListDimensions": {
      "http": {
        "method": "GET",
        "requestUri": "/dimensions"
      },
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "dimensionNames": {
            "type": "list",
            "member": {}
          },
          "nextToken": {}
        }
      }
    },
    "ListDomainConfigurations": {
      "http": {
        "method": "GET",
        "requestUri": "/domainConfigurations"
      },
      "input": {
        "type": "structure",
        "members": {
          "marker": {
            "location": "querystring",
            "locationName": "marker"
          },
          "pageSize": {
            "location": "querystring",
            "locationName": "pageSize",
            "type": "integer"
          },
          "serviceType": {
            "location": "querystring",
            "locationName": "serviceType"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "domainConfigurations": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "domainConfigurationName": {},
                "domainConfigurationArn": {},
                "serviceType": {}
              }
            }
          },
          "nextMarker": {}
        }
      }
    },
    "ListFleetMetrics": {
      "http": {
        "method": "GET",
        "requestUri": "/fleet-metrics"
      },
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "fleetMetrics": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "metricName": {},
                "metricArn": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListIndices": {
      "http": {
        "method": "GET",
        "requestUri": "/indices"
      },
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "indexNames": {
            "type": "list",
            "member": {}
          },
          "nextToken": {}
        }
      }
    },
    "ListJobExecutionsForJob": {
      "http": {
        "method": "GET",
        "requestUri": "/jobs/{jobId}/things"
      },
      "input": {
        "type": "structure",
        "required": [
          "jobId"
        ],
        "members": {
          "jobId": {
            "location": "uri",
            "locationName": "jobId"
          },
          "status": {
            "location": "querystring",
            "locationName": "status"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "executionSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "thingArn": {},
                "jobExecutionSummary": {
                  "shape": "Sps"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListJobExecutionsForThing": {
      "http": {
        "method": "GET",
        "requestUri": "/things/{thingName}/jobs"
      },
      "input": {
        "type": "structure",
        "required": [
          "thingName"
        ],
        "members": {
          "thingName": {
            "location": "uri",
            "locationName": "thingName"
          },
          "status": {
            "location": "querystring",
            "locationName": "status"
          },
          "namespaceId": {
            "location": "querystring",
            "locationName": "namespaceId"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "jobId": {
            "location": "querystring",
            "locationName": "jobId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "executionSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "jobId": {},
                "jobExecutionSummary": {
                  "shape": "Sps"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListJobTemplates": {
      "http": {
        "method": "GET",
        "requestUri": "/job-templates"
      },
      "input": {
        "type": "structure",
        "members": {
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "jobTemplates": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "jobTemplateArn": {},
                "jobTemplateId": {},
                "description": {},
                "createdAt": {
                  "type": "timestamp"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListJobs": {
      "http": {
        "method": "GET",
        "requestUri": "/jobs"
      },
      "input": {
        "type": "structure",
        "members": {
          "status": {
            "location": "querystring",
            "locationName": "status"
          },
          "targetSelection": {
            "location": "querystring",
            "locationName": "targetSelection"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "thingGroupName": {
            "location": "querystring",
            "locationName": "thingGroupName"
          },
          "thingGroupId": {
            "location": "querystring",
            "locationName": "thingGroupId"
          },
          "namespaceId": {
            "location": "querystring",
            "locationName": "namespaceId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "jobs": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "jobArn": {},
                "jobId": {},
                "thingGroupId": {},
                "targetSelection": {},
                "status": {},
                "createdAt": {
                  "type": "timestamp"
                },
                "lastUpdatedAt": {
                  "type": "timestamp"
                },
                "completedAt": {
                  "type": "timestamp"
                },
                "isConcurrent": {
                  "type": "boolean"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListManagedJobTemplates": {
      "http": {
        "method": "GET",
        "requestUri": "/managed-job-templates"
      },
      "input": {
        "type": "structure",
        "members": {
          "templateName": {
            "location": "querystring",
            "locationName": "templateName"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "managedJobTemplates": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "templateArn": {},
                "templateName": {},
                "description": {},
                "environments": {
                  "shape": "Sjn"
                },
                "templateVersion": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListMetricValues": {
      "http": {
        "method": "GET",
        "requestUri": "/metric-values"
      },
      "input": {
        "type": "structure",
        "required": [
          "thingName",
          "metricName",
          "startTime",
          "endTime"
        ],
        "members": {
          "thingName": {
            "location": "querystring",
            "locationName": "thingName"
          },
          "metricName": {
            "location": "querystring",
            "locationName": "metricName"
          },
          "dimensionName": {
            "location": "querystring",
            "locationName": "dimensionName"
          },
          "dimensionValueOperator": {
            "location": "querystring",
            "locationName": "dimensionValueOperator"
          },
          "startTime": {
            "location": "querystring",
            "locationName": "startTime",
            "type": "timestamp"
          },
          "endTime": {
            "location": "querystring",
            "locationName": "endTime",
            "type": "timestamp"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "metricDatumList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "timestamp": {
                  "type": "timestamp"
                },
                "value": {
                  "shape": "S8s"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListMitigationActions": {
      "http": {
        "method": "GET",
        "requestUri": "/mitigationactions/actions"
      },
      "input": {
        "type": "structure",
        "members": {
          "actionType": {
            "location": "querystring",
            "locationName": "actionType"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "actionIdentifiers": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "actionName": {},
                "actionArn": {},
                "creationDate": {
                  "type": "timestamp"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListOTAUpdates": {
      "http": {
        "method": "GET",
        "requestUri": "/otaUpdates"
      },
      "input": {
        "type": "structure",
        "members": {
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "otaUpdateStatus": {
            "location": "querystring",
            "locationName": "otaUpdateStatus"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "otaUpdates": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "otaUpdateId": {},
                "otaUpdateArn": {},
                "creationDate": {
                  "type": "timestamp"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListOutgoingCertificates": {
      "http": {
        "method": "GET",
        "requestUri": "/certificates-out-going"
      },
      "input": {
        "type": "structure",
        "members": {
          "pageSize": {
            "location": "querystring",
            "locationName": "pageSize",
            "type": "integer"
          },
          "marker": {
            "location": "querystring",
            "locationName": "marker"
          },
          "ascendingOrder": {
            "location": "querystring",
            "locationName": "isAscendingOrder",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "outgoingCertificates": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "certificateArn": {},
                "certificateId": {},
                "transferredTo": {},
                "transferDate": {
                  "type": "timestamp"
                },
                "transferMessage": {},
                "creationDate": {
                  "type": "timestamp"
                }
              }
            }
          },
          "nextMarker": {}
        }
      }
    },
    "ListPolicies": {
      "http": {
        "method": "GET",
        "requestUri": "/policies"
      },
      "input": {
        "type": "structure",
        "members": {
          "marker": {
            "location": "querystring",
            "locationName": "marker"
          },
          "pageSize": {
            "location": "querystring",
            "locationName": "pageSize",
            "type": "integer"
          },
          "ascendingOrder": {
            "location": "querystring",
            "locationName": "isAscendingOrder",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "policies": {
            "shape": "Snq"
          },
          "nextMarker": {}
        }
      }
    },
    "ListPolicyPrincipals": {
      "http": {
        "method": "GET",
        "requestUri": "/policy-principals"
      },
      "input": {
        "type": "structure",
        "required": [
          "policyName"
        ],
        "members": {
          "policyName": {
            "location": "header",
            "locationName": "x-amzn-iot-policy"
          },
          "marker": {
            "location": "querystring",
            "locationName": "marker"
          },
          "pageSize": {
            "location": "querystring",
            "locationName": "pageSize",
            "type": "integer"
          },
          "ascendingOrder": {
            "location": "querystring",
            "locationName": "isAscendingOrder",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "principals": {
            "shape": "Squ"
          },
          "nextMarker": {}
        }
      },
      "deprecated": true
    },
    "ListPolicyVersions": {
      "http": {
        "method": "GET",
        "requestUri": "/policies/{policyName}/version"
      },
      "input": {
        "type": "structure",
        "required": [
          "policyName"
        ],
        "members": {
          "policyName": {
            "location": "uri",
            "locationName": "policyName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "policyVersions": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "versionId": {},
                "isDefaultVersion": {
                  "type": "boolean"
                },
                "createDate": {
                  "type": "timestamp"
                }
              }
            }
          }
        }
      }
    },
    "ListPrincipalPolicies": {
      "http": {
        "method": "GET",
        "requestUri": "/principal-policies"
      },
      "input": {
        "type": "structure",
        "required": [
          "principal"
        ],
        "members": {
          "principal": {
            "location": "header",
            "locationName": "x-amzn-iot-principal"
          },
          "marker": {
            "location": "querystring",
            "locationName": "marker"
          },
          "pageSize": {
            "location": "querystring",
            "locationName": "pageSize",
            "type": "integer"
          },
          "ascendingOrder": {
            "location": "querystring",
            "locationName": "isAscendingOrder",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "policies": {
            "shape": "Snq"
          },
          "nextMarker": {}
        }
      },
      "deprecated": true
    },
    "ListPrincipalThings": {
      "http": {
        "method": "GET",
        "requestUri": "/principals/things"
      },
      "input": {
        "type": "structure",
        "required": [
          "principal"
        ],
        "members": {
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "principal": {
            "location": "header",
            "locationName": "x-amzn-principal"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "things": {
            "shape": "Sr4"
          },
          "nextToken": {}
        }
      }
    },
    "ListProvisioningTemplateVersions": {
      "http": {
        "method": "GET",
        "requestUri": "/provisioning-templates/{templateName}/versions"
      },
      "input": {
        "type": "structure",
        "required": [
          "templateName"
        ],
        "members": {
          "templateName": {
            "location": "uri",
            "locationName": "templateName"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "versions": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "versionId": {
                  "type": "integer"
                },
                "creationDate": {
                  "type": "timestamp"
                },
                "isDefaultVersion": {
                  "type": "boolean"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListProvisioningTemplates": {
      "http": {
        "method": "GET",
        "requestUri": "/provisioning-templates"
      },
      "input": {
        "type": "structure",
        "members": {
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "templates": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "templateArn": {},
                "templateName": {},
                "description": {},
                "creationDate": {
                  "type": "timestamp"
                },
                "lastModifiedDate": {
                  "type": "timestamp"
                },
                "enabled": {
                  "type": "boolean"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListRoleAliases": {
      "http": {
        "method": "GET",
        "requestUri": "/role-aliases"
      },
      "input": {
        "type": "structure",
        "members": {
          "pageSize": {
            "location": "querystring",
            "locationName": "pageSize",
            "type": "integer"
          },
          "marker": {
            "location": "querystring",
            "locationName": "marker"
          },
          "ascendingOrder": {
            "location": "querystring",
            "locationName": "isAscendingOrder",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "roleAliases": {
            "type": "list",
            "member": {}
          },
          "nextMarker": {}
        }
      }
    },
    "ListScheduledAudits": {
      "http": {
        "method": "GET",
        "requestUri": "/audit/scheduledaudits"
      },
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "scheduledAudits": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "scheduledAuditName": {},
                "scheduledAuditArn": {},
                "frequency": {},
                "dayOfMonth": {},
                "dayOfWeek": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListSecurityProfiles": {
      "http": {
        "method": "GET",
        "requestUri": "/security-profiles"
      },
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "dimensionName": {
            "location": "querystring",
            "locationName": "dimensionName"
          },
          "metricName": {
            "location": "querystring",
            "locationName": "metricName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "securityProfileIdentifiers": {
            "type": "list",
            "member": {
              "shape": "Srn"
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListSecurityProfilesForTarget": {
      "http": {
        "method": "GET",
        "requestUri": "/security-profiles-for-target"
      },
      "input": {
        "type": "structure",
        "required": [
          "securityProfileTargetArn"
        ],
        "members": {
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "recursive": {
            "location": "querystring",
            "locationName": "recursive",
            "type": "boolean"
          },
          "securityProfileTargetArn": {
            "location": "querystring",
            "locationName": "securityProfileTargetArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "securityProfileTargetMappings": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "securityProfileIdentifier": {
                  "shape": "Srn"
                },
                "target": {
                  "shape": "Srs"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListStreams": {
      "http": {
        "method": "GET",
        "requestUri": "/streams"
      },
      "input": {
        "type": "structure",
        "members": {
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "ascendingOrder": {
            "location": "querystring",
            "locationName": "isAscendingOrder",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "streams": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "streamId": {},
                "streamArn": {},
                "streamVersion": {
                  "type": "integer"
                },
                "description": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/tags"
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn"
        ],
        "members": {
          "resourceArn": {
            "location": "querystring",
            "locationName": "resourceArn"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "tags": {
            "shape": "S29"
          },
          "nextToken": {}
        }
      }
    },
    "ListTargetsForPolicy": {
      "http": {
        "requestUri": "/policy-targets/{policyName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "policyName"
        ],
        "members": {
          "policyName": {
            "location": "uri",
            "locationName": "policyName"
          },
          "marker": {
            "location": "querystring",
            "locationName": "marker"
          },
          "pageSize": {
            "location": "querystring",
            "locationName": "pageSize",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "targets": {
            "type": "list",
            "member": {}
          },
          "nextMarker": {}
        }
      }
    },
    "ListTargetsForSecurityProfile": {
      "http": {
        "method": "GET",
        "requestUri": "/security-profiles/{securityProfileName}/targets"
      },
      "input": {
        "type": "structure",
        "required": [
          "securityProfileName"
        ],
        "members": {
          "securityProfileName": {
            "location": "uri",
            "locationName": "securityProfileName"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "securityProfileTargets": {
            "type": "list",
            "member": {
              "shape": "Srs"
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListThingGroups": {
      "http": {
        "method": "GET",
        "requestUri": "/thing-groups"
      },
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "parentGroup": {
            "location": "querystring",
            "locationName": "parentGroup"
          },
          "namePrefixFilter": {
            "location": "querystring",
            "locationName": "namePrefixFilter"
          },
          "recursive": {
            "location": "querystring",
            "locationName": "recursive",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "thingGroups": {
            "shape": "Skg"
          },
          "nextToken": {}
        }
      }
    },
    "ListThingGroupsForThing": {
      "http": {
        "method": "GET",
        "requestUri": "/things/{thingName}/thing-groups"
      },
      "input": {
        "type": "structure",
        "required": [
          "thingName"
        ],
        "members": {
          "thingName": {
            "location": "uri",
            "locationName": "thingName"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "thingGroups": {
            "shape": "Skg"
          },
          "nextToken": {}
        }
      }
    },
    "ListThingPrincipals": {
      "http": {
        "method": "GET",
        "requestUri": "/things/{thingName}/principals"
      },
      "input": {
        "type": "structure",
        "required": [
          "thingName"
        ],
        "members": {
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "thingName": {
            "location": "uri",
            "locationName": "thingName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "principals": {
            "shape": "Squ"
          },
          "nextToken": {}
        }
      }
    },
    "ListThingRegistrationTaskReports": {
      "http": {
        "method": "GET",
        "requestUri": "/thing-registration-tasks/{taskId}/reports"
      },
      "input": {
        "type": "structure",
        "required": [
          "taskId",
          "reportType"
        ],
        "members": {
          "taskId": {
            "location": "uri",
            "locationName": "taskId"
          },
          "reportType": {
            "location": "querystring",
            "locationName": "reportType"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "resourceLinks": {
            "type": "list",
            "member": {}
          },
          "reportType": {},
          "nextToken": {}
        }
      }
    },
    "ListThingRegistrationTasks": {
      "http": {
        "method": "GET",
        "requestUri": "/thing-registration-tasks"
      },
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "status": {
            "location": "querystring",
            "locationName": "status"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "taskIds": {
            "type": "list",
            "member": {}
          },
          "nextToken": {}
        }
      }
    },
    "ListThingTypes": {
      "http": {
        "method": "GET",
        "requestUri": "/thing-types"
      },
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "thingTypeName": {
            "location": "querystring",
            "locationName": "thingTypeName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "thingTypes": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "thingTypeName": {},
                "thingTypeArn": {},
                "thingTypeProperties": {
                  "shape": "S9x"
                },
                "thingTypeMetadata": {
                  "shape": "Skt"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListThings": {
      "http": {
        "method": "GET",
        "requestUri": "/things"
      },
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "attributeName": {
            "location": "querystring",
            "locationName": "attributeName"
          },
          "attributeValue": {
            "location": "querystring",
            "locationName": "attributeValue"
          },
          "thingTypeName": {
            "location": "querystring",
            "locationName": "thingTypeName"
          },
          "usePrefixAttributeValue": {
            "location": "querystring",
            "locationName": "usePrefixAttributeValue",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "things": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "thingName": {},
                "thingTypeName": {},
                "thingArn": {},
                "attributes": {
                  "shape": "S3i"
                },
                "version": {
                  "type": "long"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListThingsInBillingGroup": {
      "http": {
        "method": "GET",
        "requestUri": "/billing-groups/{billingGroupName}/things"
      },
      "input": {
        "type": "structure",
        "required": [
          "billingGroupName"
        ],
        "members": {
          "billingGroupName": {
            "location": "uri",
            "locationName": "billingGroupName"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "things": {
            "shape": "Sr4"
          },
          "nextToken": {}
        }
      }
    },
    "ListThingsInThingGroup": {
      "http": {
        "method": "GET",
        "requestUri": "/thing-groups/{thingGroupName}/things"
      },
      "input": {
        "type": "structure",
        "required": [
          "thingGroupName"
        ],
        "members": {
          "thingGroupName": {
            "location": "uri",
            "locationName": "thingGroupName"
          },
          "recursive": {
            "location": "querystring",
            "locationName": "recursive",
            "type": "boolean"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "things": {
            "shape": "Sr4"
          },
          "nextToken": {}
        }
      }
    },
    "ListTopicRuleDestinations": {
      "http": {
        "method": "GET",
        "requestUri": "/destinations"
      },
      "input": {
        "type": "structure",
        "members": {
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "destinationSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "arn": {},
                "status": {},
                "createdAt": {
                  "type": "timestamp"
                },
                "lastUpdatedAt": {
                  "type": "timestamp"
                },
                "statusReason": {},
                "httpUrlSummary": {
                  "type": "structure",
                  "members": {
                    "confirmationUrl": {}
                  }
                },
                "vpcDestinationSummary": {
                  "type": "structure",
                  "members": {
                    "subnetIds": {
                      "shape": "Sd6"
                    },
                    "securityGroups": {
                      "shape": "Sd8"
                    },
                    "vpcId": {},
                    "roleArn": {}
                  }
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListTopicRules": {
      "http": {
        "method": "GET",
        "requestUri": "/rules"
      },
      "input": {
        "type": "structure",
        "members": {
          "topic": {
            "location": "querystring",
            "locationName": "topic"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "ruleDisabled": {
            "location": "querystring",
            "locationName": "ruleDisabled",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "rules": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ruleArn": {},
                "ruleName": {},
                "topicPattern": {},
                "createdAt": {
                  "type": "timestamp"
                },
                "ruleDisabled": {
                  "type": "boolean"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListV2LoggingLevels": {
      "http": {
        "method": "GET",
        "requestUri": "/v2LoggingLevel"
      },
      "input": {
        "type": "structure",
        "members": {
          "targetType": {
            "location": "querystring",
            "locationName": "targetType"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "logTargetConfigurations": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "logTarget": {
                  "shape": "Stg"
                },
                "logLevel": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListViolationEvents": {
      "http": {
        "method": "GET",
        "requestUri": "/violation-events"
      },
      "input": {
        "type": "structure",
        "required": [
          "startTime",
          "endTime"
        ],
        "members": {
          "startTime": {
            "location": "querystring",
            "locationName": "startTime",
            "type": "timestamp"
          },
          "endTime": {
            "location": "querystring",
            "locationName": "endTime",
            "type": "timestamp"
          },
          "thingName": {
            "location": "querystring",
            "locationName": "thingName"
          },
          "securityProfileName": {
            "location": "querystring",
            "locationName": "securityProfileName"
          },
          "behaviorCriteriaType": {
            "location": "querystring",
            "locationName": "behaviorCriteriaType"
          },
          "listSuppressedAlerts": {
            "location": "querystring",
            "locationName": "listSuppressedAlerts",
            "type": "boolean"
          },
          "verificationState": {
            "location": "querystring",
            "locationName": "verificationState"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "violationEvents": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "violationId": {},
                "thingName": {},
                "securityProfileName": {},
                "behavior": {
                  "shape": "S8l"
                },
                "metricValue": {
                  "shape": "S8s"
                },
                "violationEventAdditionalInfo": {
                  "shape": "Snj"
                },
                "violationEventType": {},
                "verificationState": {},
                "verificationStateDescription": {},
                "violationEventTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "PutVerificationStateOnViolation": {
      "http": {
        "requestUri": "/violations/verification-state/{violationId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "violationId",
          "verificationState"
        ],
        "members": {
          "violationId": {
            "location": "uri",
            "locationName": "violationId"
          },
          "verificationState": {},
          "verificationStateDescription": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "RegisterCACertificate": {
      "http": {
        "requestUri": "/cacertificate"
      },
      "input": {
        "type": "structure",
        "required": [
          "caCertificate",
          "verificationCertificate"
        ],
        "members": {
          "caCertificate": {},
          "verificationCertificate": {},
          "setAsActive": {
            "location": "querystring",
            "locationName": "setAsActive",
            "type": "boolean"
          },
          "allowAutoRegistration": {
            "location": "querystring",
            "locationName": "allowAutoRegistration",
            "type": "boolean"
          },
          "registrationConfig": {
            "shape": "Shf"
          },
          "tags": {
            "shape": "S29"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "certificateArn": {},
          "certificateId": {}
        }
      }
    },
    "RegisterCertificate": {
      "http": {
        "requestUri": "/certificate/register"
      },
      "input": {
        "type": "structure",
        "required": [
          "certificatePem"
        ],
        "members": {
          "certificatePem": {},
          "caCertificatePem": {},
          "setAsActive": {
            "deprecated": true,
            "location": "querystring",
            "locationName": "setAsActive",
            "type": "boolean"
          },
          "status": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "certificateArn": {},
          "certificateId": {}
        }
      }
    },
    "RegisterCertificateWithoutCA": {
      "http": {
        "requestUri": "/certificate/register-no-ca"
      },
      "input": {
        "type": "structure",
        "required": [
          "certificatePem"
        ],
        "members": {
          "certificatePem": {},
          "status": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "certificateArn": {},
          "certificateId": {}
        }
      }
    },
    "RegisterThing": {
      "http": {
        "requestUri": "/things"
      },
      "input": {
        "type": "structure",
        "required": [
          "templateBody"
        ],
        "members": {
          "templateBody": {},
          "parameters": {
            "type": "map",
            "key": {},
            "value": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "certificatePem": {},
          "resourceArns": {
            "type": "map",
            "key": {},
            "value": {}
          }
        }
      }
    },
    "RejectCertificateTransfer": {
      "http": {
        "method": "PATCH",
        "requestUri": "/reject-certificate-transfer/{certificateId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "certificateId"
        ],
        "members": {
          "certificateId": {
            "location": "uri",
            "locationName": "certificateId"
          },
          "rejectReason": {}
        }
      }
    },
    "RemoveThingFromBillingGroup": {
      "http": {
        "method": "PUT",
        "requestUri": "/billing-groups/removeThingFromBillingGroup"
      },
      "input": {
        "type": "structure",
        "members": {
          "billingGroupName": {},
          "billingGroupArn": {},
          "thingName": {},
          "thingArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "RemoveThingFromThingGroup": {
      "http": {
        "method": "PUT",
        "requestUri": "/thing-groups/removeThingFromThingGroup"
      },
      "input": {
        "type": "structure",
        "members": {
          "thingGroupName": {},
          "thingGroupArn": {},
          "thingName": {},
          "thingArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "ReplaceTopicRule": {
      "http": {
        "method": "PATCH",
        "requestUri": "/rules/{ruleName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "ruleName",
          "topicRulePayload"
        ],
        "members": {
          "ruleName": {
            "location": "uri",
            "locationName": "ruleName"
          },
          "topicRulePayload": {
            "shape": "Sa5"
          }
        },
        "payload": "topicRulePayload"
      }
    },
    "SearchIndex": {
      "http": {
        "requestUri": "/indices/search"
      },
      "input": {
        "type": "structure",
        "required": [
          "queryString"
        ],
        "members": {
          "indexName": {},
          "queryString": {},
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          },
          "queryVersion": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "nextToken": {},
          "things": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "thingName": {},
                "thingId": {},
                "thingTypeName": {},
                "thingGroupNames": {
                  "shape": "Suc"
                },
                "attributes": {
                  "shape": "S3i"
                },
                "shadow": {},
                "deviceDefender": {},
                "connectivity": {
                  "type": "structure",
                  "members": {
                    "connected": {
                      "type": "boolean"
                    },
                    "timestamp": {
                      "type": "long"
                    },
                    "disconnectReason": {}
                  }
                }
              }
            }
          },
          "thingGroups": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "thingGroupName": {},
                "thingGroupId": {},
                "thingGroupDescription": {},
                "attributes": {
                  "shape": "S3i"
                },
                "parentGroupNames": {
                  "shape": "Suc"
                }
              }
            }
          }
        }
      }
    },
    "SetDefaultAuthorizer": {
      "http": {
        "requestUri": "/default-authorizer"
      },
      "input": {
        "type": "structure",
        "required": [
          "authorizerName"
        ],
        "members": {
          "authorizerName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "authorizerName": {},
          "authorizerArn": {}
        }
      }
    },
    "SetDefaultPolicyVersion": {
      "http": {
        "method": "PATCH",
        "requestUri": "/policies/{policyName}/version/{policyVersionId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "policyName",
          "policyVersionId"
        ],
        "members": {
          "policyName": {
            "location": "uri",
            "locationName": "policyName"
          },
          "policyVersionId": {
            "location": "uri",
            "locationName": "policyVersionId"
          }
        }
      }
    },
    "SetLoggingOptions": {
      "http": {
        "requestUri": "/loggingOptions"
      },
      "input": {
        "type": "structure",
        "required": [
          "loggingOptionsPayload"
        ],
        "members": {
          "loggingOptionsPayload": {
            "type": "structure",
            "required": [
              "roleArn"
            ],
            "members": {
              "roleArn": {},
              "logLevel": {}
            }
          }
        },
        "payload": "loggingOptionsPayload"
      }
    },
    "SetV2LoggingLevel": {
      "http": {
        "requestUri": "/v2LoggingLevel"
      },
      "input": {
        "type": "structure",
        "required": [
          "logTarget",
          "logLevel"
        ],
        "members": {
          "logTarget": {
            "shape": "Stg"
          },
          "logLevel": {}
        }
      }
    },
    "SetV2LoggingOptions": {
      "http": {
        "requestUri": "/v2LoggingOptions"
      },
      "input": {
        "type": "structure",
        "members": {
          "roleArn": {},
          "defaultLogLevel": {},
          "disableAllLogs": {
            "type": "boolean"
          }
        }
      }
    },
    "StartAuditMitigationActionsTask": {
      "http": {
        "requestUri": "/audit/mitigationactions/tasks/{taskId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "taskId",
          "target",
          "auditCheckToActionsMapping",
          "clientRequestToken"
        ],
        "members": {
          "taskId": {
            "location": "uri",
            "locationName": "taskId"
          },
          "target": {
            "shape": "Sg4"
          },
          "auditCheckToActionsMapping": {
            "shape": "Sg8"
          },
          "clientRequestToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "taskId": {}
        }
      }
    },
    "StartDetectMitigationActionsTask": {
      "http": {
        "method": "PUT",
        "requestUri": "/detect/mitigationactions/tasks/{taskId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "taskId",
          "target",
          "actions",
          "clientRequestToken"
        ],
        "members": {
          "taskId": {
            "location": "uri",
            "locationName": "taskId"
          },
          "target": {
            "shape": "Shv"
          },
          "actions": {
            "type": "list",
            "member": {}
          },
          "violationEventOccurrenceRange": {
            "shape": "Shy"
          },
          "includeOnlyActiveViolations": {
            "type": "boolean"
          },
          "includeSuppressedAlerts": {
            "type": "boolean"
          },
          "clientRequestToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "taskId": {}
        }
      }
    },
    "StartOnDemandAuditTask": {
      "http": {
        "requestUri": "/audit/tasks"
      },
      "input": {
        "type": "structure",
        "required": [
          "targetCheckNames"
        ],
        "members": {
          "targetCheckNames": {
            "shape": "S8e"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "taskId": {}
        }
      }
    },
    "StartThingRegistrationTask": {
      "http": {
        "requestUri": "/thing-registration-tasks"
      },
      "input": {
        "type": "structure",
        "required": [
          "templateBody",
          "inputFileBucket",
          "inputFileKey",
          "roleArn"
        ],
        "members": {
          "templateBody": {},
          "inputFileBucket": {},
          "inputFileKey": {},
          "roleArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "taskId": {}
        }
      }
    },
    "StopThingRegistrationTask": {
      "http": {
        "method": "PUT",
        "requestUri": "/thing-registration-tasks/{taskId}/cancel"
      },
      "input": {
        "type": "structure",
        "required": [
          "taskId"
        ],
        "members": {
          "taskId": {
            "location": "uri",
            "locationName": "taskId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/tags"
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tags"
        ],
        "members": {
          "resourceArn": {},
          "tags": {
            "shape": "S29"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "TestAuthorization": {
      "http": {
        "requestUri": "/test-authorization"
      },
      "input": {
        "type": "structure",
        "required": [
          "authInfos"
        ],
        "members": {
          "principal": {},
          "cognitoIdentityPoolId": {},
          "authInfos": {
            "type": "list",
            "member": {
              "shape": "Sv5"
            }
          },
          "clientId": {
            "location": "querystring",
            "locationName": "clientId"
          },
          "policyNamesToAdd": {
            "shape": "Sv9"
          },
          "policyNamesToSkip": {
            "shape": "Sv9"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "authResults": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "authInfo": {
                  "shape": "Sv5"
                },
                "allowed": {
                  "type": "structure",
                  "members": {
                    "policies": {
                      "shape": "Snq"
                    }
                  }
                },
                "denied": {
                  "type": "structure",
                  "members": {
                    "implicitDeny": {
                      "type": "structure",
                      "members": {
                        "policies": {
                          "shape": "Snq"
                        }
                      }
                    },
                    "explicitDeny": {
                      "type": "structure",
                      "members": {
                        "policies": {
                          "shape": "Snq"
                        }
                      }
                    }
                  }
                },
                "authDecision": {},
                "missingContextValues": {
                  "type": "list",
                  "member": {}
                }
              }
            }
          }
        }
      }
    },
    "TestInvokeAuthorizer": {
      "http": {
        "requestUri": "/authorizer/{authorizerName}/test"
      },
      "input": {
        "type": "structure",
        "required": [
          "authorizerName"
        ],
        "members": {
          "authorizerName": {
            "location": "uri",
            "locationName": "authorizerName"
          },
          "token": {},
          "tokenSignature": {},
          "httpContext": {
            "type": "structure",
            "members": {
              "headers": {
                "type": "map",
                "key": {},
                "value": {}
              },
              "queryString": {}
            }
          },
          "mqttContext": {
            "type": "structure",
            "members": {
              "username": {},
              "password": {
                "type": "blob"
              },
              "clientId": {}
            }
          },
          "tlsContext": {
            "type": "structure",
            "members": {
              "serverName": {}
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "isAuthenticated": {
            "type": "boolean"
          },
          "principalId": {},
          "policyDocuments": {
            "type": "list",
            "member": {}
          },
          "refreshAfterInSeconds": {
            "type": "integer"
          },
          "disconnectAfterInSeconds": {
            "type": "integer"
          }
        }
      }
    },
    "TransferCertificate": {
      "http": {
        "method": "PATCH",
        "requestUri": "/transfer-certificate/{certificateId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "certificateId",
          "targetAwsAccount"
        ],
        "members": {
          "certificateId": {
            "location": "uri",
            "locationName": "certificateId"
          },
          "targetAwsAccount": {
            "location": "querystring",
            "locationName": "targetAwsAccount"
          },
          "transferMessage": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "transferredCertificateArn": {}
        }
      }
    },
    "UntagResource": {
      "http": {
        "requestUri": "/untag"
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tagKeys"
        ],
        "members": {
          "resourceArn": {},
          "tagKeys": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateAccountAuditConfiguration": {
      "http": {
        "method": "PATCH",
        "requestUri": "/audit/configuration"
      },
      "input": {
        "type": "structure",
        "members": {
          "roleArn": {},
          "auditNotificationTargetConfigurations": {
            "shape": "Sfc"
          },
          "auditCheckConfigurations": {
            "shape": "Sff"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateAuditSuppression": {
      "http": {
        "method": "PATCH",
        "requestUri": "/audit/suppressions/update"
      },
      "input": {
        "type": "structure",
        "required": [
          "checkName",
          "resourceIdentifier"
        ],
        "members": {
          "checkName": {},
          "resourceIdentifier": {
            "shape": "S1o"
          },
          "expirationDate": {
            "type": "timestamp"
          },
          "suppressIndefinitely": {
            "type": "boolean"
          },
          "description": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateAuthorizer": {
      "http": {
        "method": "PUT",
        "requestUri": "/authorizer/{authorizerName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "authorizerName"
        ],
        "members": {
          "authorizerName": {
            "location": "uri",
            "locationName": "authorizerName"
          },
          "authorizerFunctionArn": {},
          "tokenKeyName": {},
          "tokenSigningPublicKeys": {
            "shape": "S25"
          },
          "status": {},
          "enableCachingForHttp": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "authorizerName": {},
          "authorizerArn": {}
        }
      }
    },
    "UpdateBillingGroup": {
      "http": {
        "method": "PATCH",
        "requestUri": "/billing-groups/{billingGroupName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "billingGroupName",
          "billingGroupProperties"
        ],
        "members": {
          "billingGroupName": {
            "location": "uri",
            "locationName": "billingGroupName"
          },
          "billingGroupProperties": {
            "shape": "S2i"
          },
          "expectedVersion": {
            "type": "long"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "version": {
            "type": "long"
          }
        }
      }
    },
    "UpdateCACertificate": {
      "http": {
        "method": "PUT",
        "requestUri": "/cacertificate/{caCertificateId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "certificateId"
        ],
        "members": {
          "certificateId": {
            "location": "uri",
            "locationName": "caCertificateId"
          },
          "newStatus": {
            "location": "querystring",
            "locationName": "newStatus"
          },
          "newAutoRegistrationStatus": {
            "location": "querystring",
            "locationName": "newAutoRegistrationStatus"
          },
          "registrationConfig": {
            "shape": "Shf"
          },
          "removeAutoRegistration": {
            "type": "boolean"
          }
        }
      }
    },
    "UpdateCertificate": {
      "http": {
        "method": "PUT",
        "requestUri": "/certificates/{certificateId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "certificateId",
          "newStatus"
        ],
        "members": {
          "certificateId": {
            "location": "uri",
            "locationName": "certificateId"
          },
          "newStatus": {
            "location": "querystring",
            "locationName": "newStatus"
          }
        }
      }
    },
    "UpdateCustomMetric": {
      "http": {
        "method": "PATCH",
        "requestUri": "/custom-metric/{metricName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "metricName",
          "displayName"
        ],
        "members": {
          "metricName": {
            "location": "uri",
            "locationName": "metricName"
          },
          "displayName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "metricName": {},
          "metricArn": {},
          "metricType": {},
          "displayName": {},
          "creationDate": {
            "type": "timestamp"
          },
          "lastModifiedDate": {
            "type": "timestamp"
          }
        }
      }
    },
    "UpdateDimension": {
      "http": {
        "method": "PATCH",
        "requestUri": "/dimensions/{name}"
      },
      "input": {
        "type": "structure",
        "required": [
          "name",
          "stringValues"
        ],
        "members": {
          "name": {
            "location": "uri",
            "locationName": "name"
          },
          "stringValues": {
            "shape": "S30"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "name": {},
          "arn": {},
          "type": {},
          "stringValues": {
            "shape": "S30"
          },
          "creationDate": {
            "type": "timestamp"
          },
          "lastModifiedDate": {
            "type": "timestamp"
          }
        }
      }
    },
    "UpdateDomainConfiguration": {
      "http": {
        "method": "PUT",
        "requestUri": "/domainConfigurations/{domainConfigurationName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "domainConfigurationName"
        ],
        "members": {
          "domainConfigurationName": {
            "location": "uri",
            "locationName": "domainConfigurationName"
          },
          "authorizerConfig": {
            "shape": "S39"
          },
          "domainConfigurationStatus": {},
          "removeAuthorizerConfig": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "domainConfigurationName": {},
          "domainConfigurationArn": {}
        }
      }
    },
    "UpdateDynamicThingGroup": {
      "http": {
        "method": "PATCH",
        "requestUri": "/dynamic-thing-groups/{thingGroupName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "thingGroupName",
          "thingGroupProperties"
        ],
        "members": {
          "thingGroupName": {
            "location": "uri",
            "locationName": "thingGroupName"
          },
          "thingGroupProperties": {
            "shape": "S3f"
          },
          "expectedVersion": {
            "type": "long"
          },
          "indexName": {},
          "queryString": {},
          "queryVersion": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "version": {
            "type": "long"
          }
        }
      }
    },
    "UpdateEventConfigurations": {
      "http": {
        "method": "PATCH",
        "requestUri": "/event-configurations"
      },
      "input": {
        "type": "structure",
        "members": {
          "eventConfigurations": {
            "shape": "Sij"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateFleetMetric": {
      "http": {
        "method": "PATCH",
        "requestUri": "/fleet-metric/{metricName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "metricName",
          "indexName"
        ],
        "members": {
          "metricName": {
            "location": "uri",
            "locationName": "metricName"
          },
          "queryString": {},
          "aggregationType": {
            "shape": "S3t"
          },
          "period": {
            "type": "integer"
          },
          "aggregationField": {},
          "description": {},
          "queryVersion": {},
          "indexName": {},
          "unit": {},
          "expectedVersion": {
            "type": "long"
          }
        }
      }
    },
    "UpdateIndexingConfiguration": {
      "http": {
        "requestUri": "/indexing/config"
      },
      "input": {
        "type": "structure",
        "members": {
          "thingIndexingConfiguration": {
            "shape": "Sls"
          },
          "thingGroupIndexingConfiguration": {
            "shape": "Sm1"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateJob": {
      "http": {
        "method": "PATCH",
        "requestUri": "/jobs/{jobId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "jobId"
        ],
        "members": {
          "jobId": {
            "location": "uri",
            "locationName": "jobId"
          },
          "description": {},
          "presignedUrlConfig": {
            "shape": "S46"
          },
          "jobExecutionsRolloutConfig": {
            "shape": "S49"
          },
          "abortConfig": {
            "shape": "S4g"
          },
          "timeoutConfig": {
            "shape": "S4n"
          },
          "namespaceId": {
            "location": "querystring",
            "locationName": "namespaceId"
          },
          "jobExecutionsRetryConfig": {
            "shape": "S4q"
          }
        }
      }
    },
    "UpdateMitigationAction": {
      "http": {
        "method": "PATCH",
        "requestUri": "/mitigationactions/actions/{actionName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "actionName"
        ],
        "members": {
          "actionName": {
            "location": "uri",
            "locationName": "actionName"
          },
          "roleArn": {},
          "actionParams": {
            "shape": "S59"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "actionArn": {},
          "actionId": {}
        }
      }
    },
    "UpdateProvisioningTemplate": {
      "http": {
        "method": "PATCH",
        "requestUri": "/provisioning-templates/{templateName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "templateName"
        ],
        "members": {
          "templateName": {
            "location": "uri",
            "locationName": "templateName"
          },
          "description": {},
          "enabled": {
            "type": "boolean"
          },
          "defaultVersionId": {
            "type": "integer"
          },
          "provisioningRoleArn": {},
          "preProvisioningHook": {
            "shape": "S7z"
          },
          "removePreProvisioningHook": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateRoleAlias": {
      "http": {
        "method": "PUT",
        "requestUri": "/role-aliases/{roleAlias}"
      },
      "input": {
        "type": "structure",
        "required": [
          "roleAlias"
        ],
        "members": {
          "roleAlias": {
            "location": "uri",
            "locationName": "roleAlias"
          },
          "roleArn": {},
          "credentialDurationSeconds": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "roleAlias": {},
          "roleAliasArn": {}
        }
      }
    },
    "UpdateScheduledAudit": {
      "http": {
        "method": "PATCH",
        "requestUri": "/audit/scheduledaudits/{scheduledAuditName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "scheduledAuditName"
        ],
        "members": {
          "frequency": {},
          "dayOfMonth": {},
          "dayOfWeek": {},
          "targetCheckNames": {
            "shape": "S8e"
          },
          "scheduledAuditName": {
            "location": "uri",
            "locationName": "scheduledAuditName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "scheduledAuditArn": {}
        }
      }
    },
    "UpdateSecurityProfile": {
      "http": {
        "method": "PATCH",
        "requestUri": "/security-profiles/{securityProfileName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "securityProfileName"
        ],
        "members": {
          "securityProfileName": {
            "location": "uri",
            "locationName": "securityProfileName"
          },
          "securityProfileDescription": {},
          "behaviors": {
            "shape": "S8k"
          },
          "alertTargets": {
            "shape": "S9a"
          },
          "additionalMetricsToRetain": {
            "shape": "S9e",
            "deprecated": true,
            "deprecatedMessage": "Use additionalMetricsToRetainV2."
          },
          "additionalMetricsToRetainV2": {
            "shape": "S9f"
          },
          "deleteBehaviors": {
            "type": "boolean"
          },
          "deleteAlertTargets": {
            "type": "boolean"
          },
          "deleteAdditionalMetricsToRetain": {
            "type": "boolean"
          },
          "expectedVersion": {
            "location": "querystring",
            "locationName": "expectedVersion",
            "type": "long"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "securityProfileName": {},
          "securityProfileArn": {},
          "securityProfileDescription": {},
          "behaviors": {
            "shape": "S8k"
          },
          "alertTargets": {
            "shape": "S9a"
          },
          "additionalMetricsToRetain": {
            "shape": "S9e",
            "deprecated": true,
            "deprecatedMessage": "Use additionalMetricsToRetainV2."
          },
          "additionalMetricsToRetainV2": {
            "shape": "S9f"
          },
          "version": {
            "type": "long"
          },
          "creationDate": {
            "type": "timestamp"
          },
          "lastModifiedDate": {
            "type": "timestamp"
          }
        }
      }
    },
    "UpdateStream": {
      "http": {
        "method": "PUT",
        "requestUri": "/streams/{streamId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "streamId"
        ],
        "members": {
          "streamId": {
            "location": "uri",
            "locationName": "streamId"
          },
          "description": {},
          "files": {
            "shape": "S9l"
          },
          "roleArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "streamId": {},
          "streamArn": {},
          "description": {},
          "streamVersion": {
            "type": "integer"
          }
        }
      }
    },
    "UpdateThing": {
      "http": {
        "method": "PATCH",
        "requestUri": "/things/{thingName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "thingName"
        ],
        "members": {
          "thingName": {
            "location": "uri",
            "locationName": "thingName"
          },
          "thingTypeName": {},
          "attributePayload": {
            "shape": "S3h"
          },
          "expectedVersion": {
            "type": "long"
          },
          "removeThingType": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateThingGroup": {
      "http": {
        "method": "PATCH",
        "requestUri": "/thing-groups/{thingGroupName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "thingGroupName",
          "thingGroupProperties"
        ],
        "members": {
          "thingGroupName": {
            "location": "uri",
            "locationName": "thingGroupName"
          },
          "thingGroupProperties": {
            "shape": "S3f"
          },
          "expectedVersion": {
            "type": "long"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "version": {
            "type": "long"
          }
        }
      }
    },
    "UpdateThingGroupsForThing": {
      "http": {
        "method": "PUT",
        "requestUri": "/thing-groups/updateThingGroupsForThing"
      },
      "input": {
        "type": "structure",
        "members": {
          "thingName": {},
          "thingGroupsToAdd": {
            "shape": "Sxk"
          },
          "thingGroupsToRemove": {
            "shape": "Sxk"
          },
          "overrideDynamicGroups": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateTopicRuleDestination": {
      "http": {
        "method": "PATCH",
        "requestUri": "/destinations"
      },
      "input": {
        "type": "structure",
        "required": [
          "arn",
          "status"
        ],
        "members": {
          "arn": {},
          "status": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "ValidateSecurityProfileBehaviors": {
      "http": {
        "requestUri": "/security-profile-behaviors/validate"
      },
      "input": {
        "type": "structure",
        "required": [
          "behaviors"
        ],
        "members": {
          "behaviors": {
            "shape": "S8k"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "valid": {
            "type": "boolean"
          },
          "validationErrors": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "errorMessage": {}
              }
            }
          }
        }
      }
    }
  },
  "shapes": {
    "Sg": {
      "type": "list",
      "member": {}
    },
    "S1e": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S1o": {
      "type": "structure",
      "members": {
        "deviceCertificateId": {},
        "caCertificateId": {},
        "cognitoIdentityPoolId": {},
        "clientId": {},
        "policyVersionIdentifier": {
          "type": "structure",
          "members": {
            "policyName": {},
            "policyVersionId": {}
          }
        },
        "account": {},
        "iamRoleArn": {},
        "roleAliasArn": {}
      }
    },
    "S25": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S29": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key"
        ],
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "S2i": {
      "type": "structure",
      "members": {
        "billingGroupDescription": {}
      }
    },
    "S30": {
      "type": "list",
      "member": {}
    },
    "S39": {
      "type": "structure",
      "members": {
        "defaultAuthorizerName": {},
        "allowAuthorizerOverride": {
          "type": "boolean"
        }
      }
    },
    "S3f": {
      "type": "structure",
      "members": {
        "thingGroupDescription": {},
        "attributePayload": {
          "shape": "S3h"
        }
      }
    },
    "S3h": {
      "type": "structure",
      "members": {
        "attributes": {
          "shape": "S3i"
        },
        "merge": {
          "type": "boolean"
        }
      }
    },
    "S3i": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S3t": {
      "type": "structure",
      "required": [
        "name"
      ],
      "members": {
        "name": {},
        "values": {
          "type": "list",
          "member": {}
        }
      }
    },
    "S46": {
      "type": "structure",
      "members": {
        "roleArn": {},
        "expiresInSec": {
          "type": "long"
        }
      }
    },
    "S49": {
      "type": "structure",
      "members": {
        "maximumPerMinute": {
          "type": "integer"
        },
        "exponentialRate": {
          "type": "structure",
          "required": [
            "baseRatePerMinute",
            "incrementFactor",
            "rateIncreaseCriteria"
          ],
          "members": {
            "baseRatePerMinute": {
              "type": "integer"
            },
            "incrementFactor": {
              "type": "double"
            },
            "rateIncreaseCriteria": {
              "type": "structure",
              "members": {
                "numberOfNotifiedThings": {
                  "type": "integer"
                },
                "numberOfSucceededThings": {
                  "type": "integer"
                }
              }
            }
          }
        }
      }
    },
    "S4g": {
      "type": "structure",
      "required": [
        "criteriaList"
      ],
      "members": {
        "criteriaList": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "failureType",
              "action",
              "thresholdPercentage",
              "minNumberOfExecutedThings"
            ],
            "members": {
              "failureType": {},
              "action": {},
              "thresholdPercentage": {
                "type": "double"
              },
              "minNumberOfExecutedThings": {
                "type": "integer"
              }
            }
          }
        }
      }
    },
    "S4n": {
      "type": "structure",
      "members": {
        "inProgressTimeoutInMinutes": {
          "type": "long"
        }
      }
    },
    "S4q": {
      "type": "structure",
      "required": [
        "criteriaList"
      ],
      "members": {
        "criteriaList": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "failureType",
              "numberOfRetries"
            ],
            "members": {
              "failureType": {},
              "numberOfRetries": {
                "type": "integer"
              }
            }
          }
        }
      }
    },
    "S4v": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S54": {
      "type": "structure",
      "members": {
        "PublicKey": {},
        "PrivateKey": {
          "type": "string",
          "sensitive": true
        }
      }
    },
    "S59": {
      "type": "structure",
      "members": {
        "updateDeviceCertificateParams": {
          "type": "structure",
          "required": [
            "action"
          ],
          "members": {
            "action": {}
          }
        },
        "updateCACertificateParams": {
          "type": "structure",
          "required": [
            "action"
          ],
          "members": {
            "action": {}
          }
        },
        "addThingsToThingGroupParams": {
          "type": "structure",
          "required": [
            "thingGroupNames"
          ],
          "members": {
            "thingGroupNames": {
              "type": "list",
              "member": {}
            },
            "overrideDynamicGroups": {
              "type": "boolean"
            }
          }
        },
        "replaceDefaultPolicyVersionParams": {
          "type": "structure",
          "required": [
            "templateName"
          ],
          "members": {
            "templateName": {}
          }
        },
        "enableIoTLoggingParams": {
          "type": "structure",
          "required": [
            "roleArnForLogging",
            "logLevel"
          ],
          "members": {
            "roleArnForLogging": {},
            "logLevel": {}
          }
        },
        "publishFindingToSnsParams": {
          "type": "structure",
          "required": [
            "topicArn"
          ],
          "members": {
            "topicArn": {}
          }
        }
      }
    },
    "S5t": {
      "type": "list",
      "member": {}
    },
    "S5v": {
      "type": "list",
      "member": {}
    },
    "S5x": {
      "type": "structure",
      "members": {
        "maximumPerMinute": {
          "type": "integer"
        },
        "exponentialRate": {
          "type": "structure",
          "required": [
            "baseRatePerMinute",
            "incrementFactor",
            "rateIncreaseCriteria"
          ],
          "members": {
            "baseRatePerMinute": {
              "type": "integer"
            },
            "incrementFactor": {
              "type": "double"
            },
            "rateIncreaseCriteria": {
              "type": "structure",
              "members": {
                "numberOfNotifiedThings": {
                  "type": "integer"
                },
                "numberOfSucceededThings": {
                  "type": "integer"
                }
              }
            }
          }
        }
      }
    },
    "S64": {
      "type": "structure",
      "members": {
        "expiresInSec": {
          "type": "long"
        }
      }
    },
    "S6f": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "fileName": {},
          "fileType": {
            "type": "integer"
          },
          "fileVersion": {},
          "fileLocation": {
            "type": "structure",
            "members": {
              "stream": {
                "type": "structure",
                "members": {
                  "streamId": {},
                  "fileId": {
                    "type": "integer"
                  }
                }
              },
              "s3Location": {
                "shape": "S6o"
              }
            }
          },
          "codeSigning": {
            "type": "structure",
            "members": {
              "awsSignerJobId": {},
              "startSigningJobParameter": {
                "type": "structure",
                "members": {
                  "signingProfileParameter": {
                    "type": "structure",
                    "members": {
                      "certificateArn": {},
                      "platform": {},
                      "certificatePathOnDevice": {}
                    }
                  },
                  "signingProfileName": {},
                  "destination": {
                    "type": "structure",
                    "members": {
                      "s3Destination": {
                        "type": "structure",
                        "members": {
                          "bucket": {},
                          "prefix": {}
                        }
                      }
                    }
                  }
                }
              },
              "customCodeSigning": {
                "type": "structure",
                "members": {
                  "signature": {
                    "type": "structure",
                    "members": {
                      "inlineDocument": {
                        "type": "blob"
                      }
                    }
                  },
                  "certificateChain": {
                    "type": "structure",
                    "members": {
                      "certificateName": {},
                      "inlineDocument": {}
                    }
                  },
                  "hashAlgorithm": {},
                  "signatureAlgorithm": {}
                }
              }
            }
          },
          "attributes": {
            "type": "map",
            "key": {},
            "value": {}
          }
        }
      }
    },
    "S6o": {
      "type": "structure",
      "members": {
        "bucket": {},
        "key": {},
        "version": {}
      }
    },
    "S7d": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S7z": {
      "type": "structure",
      "required": [
        "targetArn"
      ],
      "members": {
        "payloadVersion": {},
        "targetArn": {}
      }
    },
    "S8e": {
      "type": "list",
      "member": {}
    },
    "S8k": {
      "type": "list",
      "member": {
        "shape": "S8l"
      }
    },
    "S8l": {
      "type": "structure",
      "required": [
        "name"
      ],
      "members": {
        "name": {},
        "metric": {},
        "metricDimension": {
          "shape": "S8o"
        },
        "criteria": {
          "type": "structure",
          "members": {
            "comparisonOperator": {},
            "value": {
              "shape": "S8s"
            },
            "durationSeconds": {
              "type": "integer"
            },
            "consecutiveDatapointsToAlarm": {
              "type": "integer"
            },
            "consecutiveDatapointsToClear": {
              "type": "integer"
            },
            "statisticalThreshold": {
              "type": "structure",
              "members": {
                "statistic": {}
              }
            },
            "mlDetectionConfig": {
              "type": "structure",
              "required": [
                "confidenceLevel"
              ],
              "members": {
                "confidenceLevel": {}
              }
            }
          }
        },
        "suppressAlerts": {
          "type": "boolean"
        }
      }
    },
    "S8o": {
      "type": "structure",
      "required": [
        "dimensionName"
      ],
      "members": {
        "dimensionName": {},
        "operator": {}
      }
    },
    "S8s": {
      "type": "structure",
      "members": {
        "count": {
          "type": "long"
        },
        "cidrs": {
          "type": "list",
          "member": {}
        },
        "ports": {
          "type": "list",
          "member": {
            "type": "integer"
          }
        },
        "number": {
          "type": "double"
        },
        "numbers": {
          "type": "list",
          "member": {
            "type": "double"
          }
        },
        "strings": {
          "type": "list",
          "member": {}
        }
      }
    },
    "S9a": {
      "type": "map",
      "key": {},
      "value": {
        "type": "structure",
        "required": [
          "alertTargetArn",
          "roleArn"
        ],
        "members": {
          "alertTargetArn": {},
          "roleArn": {}
        }
      }
    },
    "S9e": {
      "type": "list",
      "member": {}
    },
    "S9f": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "metric"
        ],
        "members": {
          "metric": {},
          "metricDimension": {
            "shape": "S8o"
          }
        }
      }
    },
    "S9l": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "fileId": {
            "type": "integer"
          },
          "s3Location": {
            "shape": "S6o"
          }
        }
      }
    },
    "S9x": {
      "type": "structure",
      "members": {
        "thingTypeDescription": {},
        "searchableAttributes": {
          "type": "list",
          "member": {}
        }
      }
    },
    "Sa5": {
      "type": "structure",
      "required": [
        "sql",
        "actions"
      ],
      "members": {
        "sql": {},
        "description": {},
        "actions": {
          "shape": "Sa8"
        },
        "ruleDisabled": {
          "type": "boolean"
        },
        "awsIotSqlVersion": {},
        "errorAction": {
          "shape": "Sa9"
        }
      }
    },
    "Sa8": {
      "type": "list",
      "member": {
        "shape": "Sa9"
      }
    },
    "Sa9": {
      "type": "structure",
      "members": {
        "dynamoDB": {
          "type": "structure",
          "required": [
            "tableName",
            "roleArn",
            "hashKeyField",
            "hashKeyValue"
          ],
          "members": {
            "tableName": {},
            "roleArn": {},
            "operation": {},
            "hashKeyField": {},
            "hashKeyValue": {},
            "hashKeyType": {},
            "rangeKeyField": {},
            "rangeKeyValue": {},
            "rangeKeyType": {},
            "payloadField": {}
          }
        },
        "dynamoDBv2": {
          "type": "structure",
          "required": [
            "roleArn",
            "putItem"
          ],
          "members": {
            "roleArn": {},
            "putItem": {
              "type": "structure",
              "required": [
                "tableName"
              ],
              "members": {
                "tableName": {}
              }
            }
          }
        },
        "lambda": {
          "type": "structure",
          "required": [
            "functionArn"
          ],
          "members": {
            "functionArn": {}
          }
        },
        "sns": {
          "type": "structure",
          "required": [
            "targetArn",
            "roleArn"
          ],
          "members": {
            "targetArn": {},
            "roleArn": {},
            "messageFormat": {}
          }
        },
        "sqs": {
          "type": "structure",
          "required": [
            "roleArn",
            "queueUrl"
          ],
          "members": {
            "roleArn": {},
            "queueUrl": {},
            "useBase64": {
              "type": "boolean"
            }
          }
        },
        "kinesis": {
          "type": "structure",
          "required": [
            "roleArn",
            "streamName"
          ],
          "members": {
            "roleArn": {},
            "streamName": {},
            "partitionKey": {}
          }
        },
        "republish": {
          "type": "structure",
          "required": [
            "roleArn",
            "topic"
          ],
          "members": {
            "roleArn": {},
            "topic": {},
            "qos": {
              "type": "integer"
            }
          }
        },
        "s3": {
          "type": "structure",
          "required": [
            "roleArn",
            "bucketName",
            "key"
          ],
          "members": {
            "roleArn": {},
            "bucketName": {},
            "key": {},
            "cannedAcl": {}
          }
        },
        "firehose": {
          "type": "structure",
          "required": [
            "roleArn",
            "deliveryStreamName"
          ],
          "members": {
            "roleArn": {},
            "deliveryStreamName": {},
            "separator": {},
            "batchMode": {
              "type": "boolean"
            }
          }
        },
        "cloudwatchMetric": {
          "type": "structure",
          "required": [
            "roleArn",
            "metricNamespace",
            "metricName",
            "metricValue",
            "metricUnit"
          ],
          "members": {
            "roleArn": {},
            "metricNamespace": {},
            "metricName": {},
            "metricValue": {},
            "metricUnit": {},
            "metricTimestamp": {}
          }
        },
        "cloudwatchAlarm": {
          "type": "structure",
          "required": [
            "roleArn",
            "alarmName",
            "stateReason",
            "stateValue"
          ],
          "members": {
            "roleArn": {},
            "alarmName": {},
            "stateReason": {},
            "stateValue": {}
          }
        },
        "cloudwatchLogs": {
          "type": "structure",
          "required": [
            "roleArn",
            "logGroupName"
          ],
          "members": {
            "roleArn": {},
            "logGroupName": {}
          }
        },
        "elasticsearch": {
          "type": "structure",
          "required": [
            "roleArn",
            "endpoint",
            "index",
            "type",
            "id"
          ],
          "members": {
            "roleArn": {},
            "endpoint": {},
            "index": {},
            "type": {},
            "id": {}
          }
        },
        "salesforce": {
          "type": "structure",
          "required": [
            "token",
            "url"
          ],
          "members": {
            "token": {},
            "url": {}
          }
        },
        "iotAnalytics": {
          "type": "structure",
          "members": {
            "channelArn": {},
            "channelName": {},
            "batchMode": {
              "type": "boolean"
            },
            "roleArn": {}
          }
        },
        "iotEvents": {
          "type": "structure",
          "required": [
            "inputName",
            "roleArn"
          ],
          "members": {
            "inputName": {},
            "messageId": {},
            "batchMode": {
              "type": "boolean"
            },
            "roleArn": {}
          }
        },
        "iotSiteWise": {
          "type": "structure",
          "required": [
            "putAssetPropertyValueEntries",
            "roleArn"
          ],
          "members": {
            "putAssetPropertyValueEntries": {
              "type": "list",
              "member": {
                "type": "structure",
                "required": [
                  "propertyValues"
                ],
                "members": {
                  "entryId": {},
                  "assetId": {},
                  "propertyId": {},
                  "propertyAlias": {},
                  "propertyValues": {
                    "type": "list",
                    "member": {
                      "type": "structure",
                      "required": [
                        "value",
                        "timestamp"
                      ],
                      "members": {
                        "value": {
                          "type": "structure",
                          "members": {
                            "stringValue": {},
                            "integerValue": {},
                            "doubleValue": {},
                            "booleanValue": {}
                          }
                        },
                        "timestamp": {
                          "type": "structure",
                          "required": [
                            "timeInSeconds"
                          ],
                          "members": {
                            "timeInSeconds": {},
                            "offsetInNanos": {}
                          }
                        },
                        "quality": {}
                      }
                    }
                  }
                }
              }
            },
            "roleArn": {}
          }
        },
        "stepFunctions": {
          "type": "structure",
          "required": [
            "stateMachineName",
            "roleArn"
          ],
          "members": {
            "executionNamePrefix": {},
            "stateMachineName": {},
            "roleArn": {}
          }
        },
        "timestream": {
          "type": "structure",
          "required": [
            "roleArn",
            "databaseName",
            "tableName",
            "dimensions"
          ],
          "members": {
            "roleArn": {},
            "databaseName": {},
            "tableName": {},
            "dimensions": {
              "type": "list",
              "member": {
                "type": "structure",
                "required": [
                  "name",
                  "value"
                ],
                "members": {
                  "name": {},
                  "value": {}
                }
              }
            },
            "timestamp": {
              "type": "structure",
              "required": [
                "value",
                "unit"
              ],
              "members": {
                "value": {},
                "unit": {}
              }
            }
          }
        },
        "http": {
          "type": "structure",
          "required": [
            "url"
          ],
          "members": {
            "url": {},
            "confirmationUrl": {},
            "headers": {
              "type": "list",
              "member": {
                "type": "structure",
                "required": [
                  "key",
                  "value"
                ],
                "members": {
                  "key": {},
                  "value": {}
                }
              }
            },
            "auth": {
              "type": "structure",
              "members": {
                "sigv4": {
                  "type": "structure",
                  "required": [
                    "signingRegion",
                    "serviceName",
                    "roleArn"
                  ],
                  "members": {
                    "signingRegion": {},
                    "serviceName": {},
                    "roleArn": {}
                  }
                }
              }
            }
          }
        },
        "kafka": {
          "type": "structure",
          "required": [
            "destinationArn",
            "topic",
            "clientProperties"
          ],
          "members": {
            "destinationArn": {},
            "topic": {},
            "key": {},
            "partition": {},
            "clientProperties": {
              "type": "map",
              "key": {},
              "value": {}
            }
          }
        },
        "openSearch": {
          "type": "structure",
          "required": [
            "roleArn",
            "endpoint",
            "index",
            "type",
            "id"
          ],
          "members": {
            "roleArn": {},
            "endpoint": {},
            "index": {},
            "type": {},
            "id": {}
          }
        }
      }
    },
    "Sd6": {
      "type": "list",
      "member": {}
    },
    "Sd8": {
      "type": "list",
      "member": {}
    },
    "Sdc": {
      "type": "structure",
      "members": {
        "arn": {},
        "status": {},
        "createdAt": {
          "type": "timestamp"
        },
        "lastUpdatedAt": {
          "type": "timestamp"
        },
        "statusReason": {},
        "httpUrlProperties": {
          "type": "structure",
          "members": {
            "confirmationUrl": {}
          }
        },
        "vpcProperties": {
          "type": "structure",
          "members": {
            "subnetIds": {
              "shape": "Sd6"
            },
            "securityGroups": {
              "shape": "Sd8"
            },
            "vpcId": {},
            "roleArn": {}
          }
        }
      }
    },
    "Sfc": {
      "type": "map",
      "key": {},
      "value": {
        "type": "structure",
        "members": {
          "targetArn": {},
          "roleArn": {},
          "enabled": {
            "type": "boolean"
          }
        }
      }
    },
    "Sff": {
      "type": "map",
      "key": {},
      "value": {
        "type": "structure",
        "members": {
          "enabled": {
            "type": "boolean"
          }
        }
      }
    },
    "Sfk": {
      "type": "structure",
      "members": {
        "findingId": {},
        "taskId": {},
        "checkName": {},
        "taskStartTime": {
          "type": "timestamp"
        },
        "findingTime": {
          "type": "timestamp"
        },
        "severity": {},
        "nonCompliantResource": {
          "type": "structure",
          "members": {
            "resourceType": {},
            "resourceIdentifier": {
              "shape": "S1o"
            },
            "additionalInfo": {
              "shape": "Sfo"
            }
          }
        },
        "relatedResources": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "resourceType": {},
              "resourceIdentifier": {
                "shape": "S1o"
              },
              "additionalInfo": {
                "shape": "Sfo"
              }
            }
          }
        },
        "reasonForNonCompliance": {},
        "reasonForNonComplianceCode": {},
        "isSuppressed": {
          "type": "boolean"
        }
      }
    },
    "Sfo": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "Sg4": {
      "type": "structure",
      "members": {
        "auditTaskId": {},
        "findingIds": {
          "type": "list",
          "member": {}
        },
        "auditCheckToReasonCodeFilter": {
          "type": "map",
          "key": {},
          "value": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "Sg8": {
      "type": "map",
      "key": {},
      "value": {
        "type": "list",
        "member": {}
      }
    },
    "Sga": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "name": {},
          "id": {},
          "roleArn": {},
          "actionParams": {
            "shape": "S59"
          }
        }
      }
    },
    "Sh1": {
      "type": "structure",
      "members": {
        "authorizerName": {},
        "authorizerArn": {},
        "authorizerFunctionArn": {},
        "tokenKeyName": {},
        "tokenSigningPublicKeys": {
          "shape": "S25"
        },
        "status": {},
        "creationDate": {
          "type": "timestamp"
        },
        "lastModifiedDate": {
          "type": "timestamp"
        },
        "signingDisabled": {
          "type": "boolean"
        },
        "enableCachingForHttp": {
          "type": "boolean"
        }
      }
    },
    "She": {
      "type": "structure",
      "members": {
        "notBefore": {
          "type": "timestamp"
        },
        "notAfter": {
          "type": "timestamp"
        }
      }
    },
    "Shf": {
      "type": "structure",
      "members": {
        "templateBody": {},
        "roleArn": {}
      }
    },
    "Sht": {
      "type": "structure",
      "members": {
        "taskId": {},
        "taskStatus": {},
        "taskStartTime": {
          "type": "timestamp"
        },
        "taskEndTime": {
          "type": "timestamp"
        },
        "target": {
          "shape": "Shv"
        },
        "violationEventOccurrenceRange": {
          "shape": "Shy"
        },
        "onlyActiveViolationsIncluded": {
          "type": "boolean"
        },
        "suppressedAlertsIncluded": {
          "type": "boolean"
        },
        "actionsDefinition": {
          "shape": "Sga"
        },
        "taskStatistics": {
          "type": "structure",
          "members": {
            "actionsExecuted": {
              "type": "long"
            },
            "actionsSkipped": {
              "type": "long"
            },
            "actionsFailed": {
              "type": "long"
            }
          }
        }
      }
    },
    "Shv": {
      "type": "structure",
      "members": {
        "violationIds": {
          "type": "list",
          "member": {}
        },
        "securityProfileName": {},
        "behaviorName": {}
      }
    },
    "Shy": {
      "type": "structure",
      "required": [
        "startTime",
        "endTime"
      ],
      "members": {
        "startTime": {
          "type": "timestamp"
        },
        "endTime": {
          "type": "timestamp"
        }
      }
    },
    "Sij": {
      "type": "map",
      "key": {},
      "value": {
        "type": "structure",
        "members": {
          "Enabled": {
            "type": "boolean"
          }
        }
      }
    },
    "Sjn": {
      "type": "list",
      "member": {}
    },
    "Skg": {
      "type": "list",
      "member": {
        "shape": "Skh"
      }
    },
    "Skh": {
      "type": "structure",
      "members": {
        "groupName": {},
        "groupArn": {}
      }
    },
    "Skt": {
      "type": "structure",
      "members": {
        "deprecated": {
          "type": "boolean"
        },
        "deprecationDate": {
          "type": "timestamp"
        },
        "creationDate": {
          "type": "timestamp"
        }
      }
    },
    "Sls": {
      "type": "structure",
      "required": [
        "thingIndexingMode"
      ],
      "members": {
        "thingIndexingMode": {},
        "thingConnectivityIndexingMode": {},
        "deviceDefenderIndexingMode": {},
        "namedShadowIndexingMode": {},
        "managedFields": {
          "shape": "Slx"
        },
        "customFields": {
          "shape": "Slx"
        }
      }
    },
    "Slx": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "name": {},
          "type": {}
        }
      }
    },
    "Sm1": {
      "type": "structure",
      "required": [
        "thingGroupIndexingMode"
      ],
      "members": {
        "thingGroupIndexingMode": {},
        "managedFields": {
          "shape": "Slx"
        },
        "customFields": {
          "shape": "Slx"
        }
      }
    },
    "Snj": {
      "type": "structure",
      "members": {
        "confidenceLevel": {}
      }
    },
    "Snq": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "policyName": {},
          "policyArn": {}
        }
      }
    },
    "Sos": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "certificateArn": {},
          "certificateId": {},
          "status": {},
          "certificateMode": {},
          "creationDate": {
            "type": "timestamp"
          }
        }
      }
    },
    "Sps": {
      "type": "structure",
      "members": {
        "status": {},
        "queuedAt": {
          "type": "timestamp"
        },
        "startedAt": {
          "type": "timestamp"
        },
        "lastUpdatedAt": {
          "type": "timestamp"
        },
        "executionNumber": {
          "type": "long"
        },
        "retryAttempt": {
          "type": "integer"
        }
      }
    },
    "Squ": {
      "type": "list",
      "member": {}
    },
    "Sr4": {
      "type": "list",
      "member": {}
    },
    "Srn": {
      "type": "structure",
      "required": [
        "name",
        "arn"
      ],
      "members": {
        "name": {},
        "arn": {}
      }
    },
    "Srs": {
      "type": "structure",
      "required": [
        "arn"
      ],
      "members": {
        "arn": {}
      }
    },
    "Stg": {
      "type": "structure",
      "required": [
        "targetType"
      ],
      "members": {
        "targetType": {},
        "targetName": {}
      }
    },
    "Suc": {
      "type": "list",
      "member": {}
    },
    "Sv5": {
      "type": "structure",
      "required": [
        "resources"
      ],
      "members": {
        "actionType": {},
        "resources": {
          "type": "list",
          "member": {}
        }
      }
    },
    "Sv9": {
      "type": "list",
      "member": {}
    },
    "Sxk": {
      "type": "list",
      "member": {}
    }
  }
}