"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.NTACorporateInfoProcess = exports.NTACorporateInfoKind = exports.NTACorporateInfoCloseCause = exports.KENALL = void 0;
var core_1 = require("./core");
Object.defineProperty(exports, "KENALL", {
  enumerable: true,
  get: function () {
    return core_1.KENALLV1;
  }
});
var interfaces_1 = require("./interfaces");
Object.defineProperty(exports, "NTACorporateInfoCloseCause", {
  enumerable: true,
  get: function () {
    return interfaces_1.NTACorporateInfoCloseCause;
  }
});
Object.defineProperty(exports, "NTACorporateInfoKind", {
  enumerable: true,
  get: function () {
    return interfaces_1.NTACorporateInfoKind;
  }
});
Object.defineProperty(exports, "NTACorporateInfoProcess", {
  enumerable: true,
  get: function () {
    return interfaces_1.NTACorporateInfoProcess;
  }
});
