{
  "pagination": {
    "ListParallelData": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken"
    },
    "ListTerminologies": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken"
    },
    "ListTextTranslationJobs": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken"
    }
  }
}