<!-- このコンポーネントのスタイルは _date-picker.scss で管理 -->
<form [formGroup]="formAgree">
  <mat-form-field floatLabel="always" class="form-field">
    <mat-label>{{label}}</mat-label>
    <input matInput
      [matDatepicker]="picker"
      placeholder="年/月/日"
      maxlength="10"
      class="browser-default"
      [(ngModel)]="dateOfBirth"
      (dateChange)="onDateChange($event)"
      formControlName="date_of_birth"
      [min]="dateOfBirthMin"
      [max]="dateOfBirthMax"
    >
    <button *ngIf="dateOfBirth" class="date-picker-clear" mat-icon-button aria-label="Clear" (click)="dateOfBirth=''">
      <mat-icon>close</mat-icon>
    </button>
    <mat-datepicker-toggle matSuffix [for]="picker">
      <mat-icon class="mat-calendar-icon" color="primary" matDatepickerToggleIcon>date_range</mat-icon>
    </mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
</form>
